import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, TextField, Button } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from 'styled-components';

import useStyles from "../styles.js";
import { BankAuthContext, transfer } from '../../../../../../actions/bankScenario.js';
import { SolutionsContext } from '../../../../../../contexts/SolutionsContext.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const Transaction = (props) => {
  const [accountNumber, setAccountNumber] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  
  const dispatch = useDispatch();
  
  const { page, setPage, session } = useContext(ChallengeDataContext);
  const {solutions, setSolutions} = useContext(SolutionsContext);

  useEffect(()=>{
    const unionAnswer = solutions.transfer;
    if(unionAnswer){
      accountNumber(unionAnswer ? (unionAnswer.accountNumber || "") : "");
      amount(unionAnswer ? (unionAnswer.amount || "") : "");
      description(unionAnswer ? (unionAnswer.description || "") : "");
    }

  }, [solutions])

  const handleTransaction = () => {
    // Handle the transaction logic here
    // You can send the data to your backend or simulate the transaction process

    if(session.user)
    {
      dispatch(transfer({
        accountNumber,
        fromAccountNumber: session.user.accountNumber,
        sortCode,
        amount,
        description,
      })).then(result => {
        // console.log(result)
        if(!result.error){
          setSuccess("Transfer made successfully");
        }
        else{
          setError(result.error);
        }
      });
      
      setAccountNumber('');
      setSortCode('');
      setAmount('');
      setDescription('');
    }
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Container maxWidth="md">
      <div  className={classes.text}>

      <Typography variant="h4" align="center" style={{ margin: '20px' }}>
        Transfer Money
      </Typography>
      <div>

          <TextField
            fullWidth
            label="Recipient Account Number"
            value={accountNumber}
            onChange={(e) => setAccountNumber(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
          {/* <TextField
            fullWidth
            label="Recipient Sort Code"
            value={sortCode}
            onChange={(e) => setSortCode(e.target.value)}
            style={{ marginBottom: '20px' }}
          /> */}
          <TextField
            fullWidth
            label="Amount (GBP)"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ marginBottom: '20px' }}
          />
      </div>
      <div>
        {error ?? <Typography>{error}</Typography>}
        {success ?? <Typography>{success}</Typography>}

      </div>
          <Button variant="contained" color="primary" onClick={handleTransaction}>
            Transfer
          </Button>
        <Button variant="contained"  color="primary" className="m-3" onClick={() => setPage("menu")}>
            Back
        </Button>
      </div>
      {/* Additional relevant information or components can be added here */}
    </Container>
  );
};

export default Transaction;