import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Container, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  text: {
    marginBottom: theme.spacing(2),
  },
}));

const NotFoundPage = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Grid container direction="column" alignItems="center">
        <Typography variant="h1" className={classes.text}>
          404
        </Typography>
        <Typography variant="h5" className={classes.text}>
          Oops! Page not found.
        </Typography>
        <Typography variant="body1" className={classes.text}>
          The page you are looking for might be under construction or doesn't exist.
        </Typography>
        <Button variant="contained" color="primary" component={Link} to="/">
          Go to Home
        </Button>
      </Grid>
    </Container>
  );
};

export default NotFoundPage;
