import { GET_STOLEN_COOKIES, GET_STOLEN_COOKIES_ERROR, UPDATE_CHALLENGE_STATUS } from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { createContext, useEffect, useReducer } from "react";

export const fetchStolenCookies = () => async (dispatch) => {
  try{
    const {data} = await api.getCookies();

    // ' OR balance > 1000000 --
    dispatch({ type: GET_STOLEN_COOKIES, payload: data });

    if(data.step)
    {
      console.log(data.step)
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_STOLEN_COOKIES_ERROR, payload: e.message });
  }
};

export const cookiesUpdatedStep = () => async (dispatch) => {
  try{
    const {data} = await api.cookiesUpdatedCheck();

    // ' OR balance > 1000000 --
    if(data.step)
    {
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_STOLEN_COOKIES_ERROR, payload: e.message });
  }
};