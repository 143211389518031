import React from 'react';
import { Container, Typography, Grid, Card, CardContent, CardMedia, Link} from '@material-ui/core';

import georgeImage from '../../assets/team/george_karabassis.jpeg';  
import donImage from '../../assets/team/Donald-Sannella.jpg';  
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { FormattedMessage } from 'react-intl';

const About = () => {

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Container className={`${classes.text} my-3`}>
      <Typography variant="h4" align="center" gutterBottom>
        <FormattedMessage id="about" defaultMessage="About" ></FormattedMessage>
      </Typography>
      <Typography variant="body1" align="center" paragraph>
        <FormattedMessage id="about-intro" defaultMessage="Find relevant information regarding HackMeDown." ></FormattedMessage>
      </Typography>

      <Grid container spacing={3} className="mb-3">
        <Grid item xs={12} sm={6}>
          <Card className={`${classes.bgSoft} d-flex justify-content-center flex-column align-items-center p-3`}>
            <CardMedia
              component="img"
              alt="George Karabassis"
              height="200"
              image={georgeImage}
              className={classes.circularImage}
            />
            <CardContent className="p-0 mt-3">
            <Link href="https://georgekarabassis.com" target="_blank">
                <Typography variant="h6" className={classes.text}>George Karabassis</Typography>
              </Link>
              <Typography variant="body2" color="textSecondary" className={classes.textSoft}>
              <FormattedMessage id="student" defaultMessage="Student" ></FormattedMessage>
              </Typography>
              <Typography variant="body2" className={`${classes.text} m-0`} paragraph>
              <FormattedMessage id="student-intro" defaultMessage="George is currently a 4th year Computer Science and Artificial Intelligence student at the University of Edinburgh. George is creating HackMeDown under his dissertation project."></FormattedMessage>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        
        <Grid item xs={12} sm={6}>
          <Card className={`${classes.bgSoft} d-flex justify-content-center flex-column align-items-center p-3`}>
              <CardMedia
                component="img"
                alt="Don Sanella"
                image={donImage}
                className={classes.circularImage}
              />
            <CardContent className="p-0 mt-3">
            <Link href="https://homepages.inf.ed.ac.uk/dts/" target="_blank">
              <Typography variant="h6" className={classes.text}>Don Sannella</Typography>
            </Link>
              <Typography variant="body2" color="textSecondary" className={classes.textSoft}>
              <FormattedMessage id="supervisor" defaultMessage="Supervisor" ></FormattedMessage>
              </Typography>
              <Typography variant="body2" className={`${classes.text} m-0`} paragraph>
              <FormattedMessage id="supervisor-intro" defaultMessage="Don is currently supervising George on his dissertation project. As a supervisor, he provides guidance
                and mentorship, ensuring the successful execution of the project."></FormattedMessage>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    <Container className="p-0 text-center">

      <Typography variant="h5" align="center" gutterBottom>
      <FormattedMessage id="project-about-title" defaultMessage="What's this Project About?"></FormattedMessage>
      </Typography>
      <Typography variant="body1" paragraph>
      <FormattedMessage id="project-about-1" defaultMessage="Our project is aimed towards individuals who are looking to sharpen their cybersecurity skills in a smart way. HackMeDown learns from your behavour and will generate challenges and scenarios for you to challenge your skills and take them to the next level." ></FormattedMessage>
      </Typography>

      <Typography variant="body1" paragraph>
      <FormattedMessage id="project-about-2" defaultMessage="We understand that such a tool is sparsly available and while I'm working on HackMeDown as my dissertation project for my 4th year of studies, supervised by Don Sannella, we are certain that this project will help many enthusiasts to make a start in the field of cybersecurity." ></FormattedMessage>
      </Typography>
    </Container>
    </Container>
  );
};

export default About;