// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.link {
  display: flex;
  align-items: cover;
}

.link .wrap{
    display: flex;
    padding: 1rem;
    align-items: center;
      cursor: pointer;
      border-radius: 30px;
}

/* .link .wrap:hover {
  background-color: #fff5f6;
  color: #f51657;
  transition: color 100ms ease-out;
} */

.link h2 {
  font-weight: 700;
  font-size: 20px;
  margin: 0px;
}

.post-btn{
  padding: 1rem!important;
  margin-top:0.5rem!important;
  border-radius: 2rem!important;
  color: white;
  font-weight: 800!important;
  text-transform: inherit;
}`, "",{"version":3,"sources":["webpack://./src/Components/Menu/sidebarLink.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;MACjB,eAAe;MACf,mBAAmB;AACzB;;AAEA;;;;GAIG;;AAEH;EACE,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,2BAA2B;EAC3B,6BAA6B;EAC7B,YAAY;EACZ,0BAA0B;EAC1B,uBAAuB;AACzB","sourcesContent":[".link {\n  display: flex;\n  align-items: cover;\n}\n\n.link .wrap{\n    display: flex;\n    padding: 1rem;\n    align-items: center;\n      cursor: pointer;\n      border-radius: 30px;\n}\n\n/* .link .wrap:hover {\n  background-color: #fff5f6;\n  color: #f51657;\n  transition: color 100ms ease-out;\n} */\n\n.link h2 {\n  font-weight: 700;\n  font-size: 20px;\n  margin: 0px;\n}\n\n.post-btn{\n  padding: 1rem!important;\n  margin-top:0.5rem!important;\n  border-radius: 2rem!important;\n  color: white;\n  font-weight: 800!important;\n  text-transform: inherit;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
