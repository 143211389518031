import { Button } from "@material-ui/core";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import useStyles from "../Scenarios/BankScenario/Dashboard/Button/styles.js";
import { BankAuthContext, endSession } from "../../../actions/challenge.js";
import { ChallengeDataContext } from "contexts/ChallengeData.js";
import bank from "reducers/bank.js";
import { Cookies } from "react-cookie";

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs,
    getRestart
  } from 'selectors/browser';
import { FormattedMessage } from "react-intl";

const cookies = new Cookies();


// export const logoutHandler = (dispatch,resetStates, activeTab) => {

//     console.log(activeTab)
//     dispatch(endSession(activeTab)).then(() => {
//         // removeCookie(activeTab);
//         cookies.remove(activeTab);
//         console.log(cookies)
//         resetStates(activeTab);
//     });
// };

export const logoutHandler = (dispatch, activeTab, selectedBrowser) => {
    
    dispatch(endSession(activeTab, selectedBrowser)).then(() => {
        // Set cookie expiration to a past date
        document.cookie = `${selectedBrowser}_${activeTab}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        document.cookie = `${activeTab}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            if (cookie.startsWith("session_id=")) {
                const cookieParts = cookie.split("=");
                const cookieName = cookieParts[0];
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        }

        
        dispatch({type: "UPDATE_PAGE", payload: "login"});
        dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com/login`});
    });
};
export const BankLogOut = () => {

    const theme = useTheme();
    const classes = useStyles(theme);
    // const {user} = useContext(BankAuthContext)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const restart = useSelector(getRestart);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    const logout = () => {
        // if(session)
        // {
        console.log(activeTab, selectedBrowser, "activeTab, selectedBrowser")
            logoutHandler(dispatch, activeTab, selectedBrowser);
        // }
    };

    return (
        <Button
            variant="contained"
            className="top-0 end-0"
            onClick={() => logout()}
            color="secondary"
        >
            {/* {" "} */}
            <FormattedMessage id="logout" defaultMessage="Logout" ></FormattedMessage>
        </Button>
    );
}