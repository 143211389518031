import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
  text: (theme) => ({
    color: theme.textColor,
    padding: "0.5rem"
  }),
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover', // Make the video cover the entire container
    zIndex: 0, // Position the video behind other content
  },
  overlay: (theme) => ({
    position:"absolute",
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex:1,
    backgroundColor: theme.overlay,
  }),
  content:{
    zIndex: 2,
  }
  }));
