import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
      },
      // card: {
      //   display: 'flex',
      //   marginBottom: theme.spacing(2),
      // },
      // content: {
      //   flex: '1 0 auto',
      // },
      media: {
        objectFit: 'cover',
      },
      card: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%', // Set card height to occupy the full container height
      },
      content: {
        flex: '1 0 auto',
        padding: theme.spacing(2),
        textAlign: 'center',
        overflow: 'hidden', // Hide overflowed content
        textOverflow: 'ellipsis', // Show ellipsis for overflowed text
      },
}));
