// src/App.js
import React, { useContext, useEffect, useState } from 'react';
import { Container, CssBaseline } from '@mui/material';
import { Grid, Paper, Typography } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import { fetchStolenPackets } from 'actions/packetSnifferScenario.js';
import { useDispatch } from 'react-redux';
import { packetSnifferCookieStolen } from 'actions/challenge.js';
;

const PacketSnifferScenario = () => {

    const {activeTab, session} = useContext(ChallengeDataContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const [error, setError] = useState(null);
    const [stolenPackets, setStolenPackets] = useState([]);
    const [iframeSrc, setIframeSrc] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchStolenPackets()).then((data) => {
            if(data)
            {
                if(data.message){
                    setError(data.message)
                }
                else{
                    setStolenPackets(data.data);
                }
            }
            else{
                setError("Error fetching stolen cookies");
            }
        });

        const match = /auth=([^;]+)/.exec(document.cookie);
        const authValue = match ? match[1] : null;
        setIframeSrc(`${process.env.REACT_APP_PACKETSNIFFER_URL || 'http://localhost:5003'}?auth=${encodeURIComponent(authValue)}`)

        const handleMessage = (event) => {
            if (event.origin === (process.env.REACT_APP_PACKETSNIFFER_URL || 'http://localhost:5003')) {

                // Ensure the message is from the expected origin (localhost:3001)
                const receivedValue = event.data;
                console.log(receivedValue);

                // Store the value as a cookie or perform other actions
                document.cookie = `session_id=${receivedValue}; expires=Thu, 21 Feb 2025 12:00:00 UTC; path=/; SameSite=None; Secure`;

                // update step

                dispatch(packetSnifferCookieStolen({cookie: receivedValue})).then((data) => {
                    console.log("cookie stolen step added");
                })
            }
        };

        // Attach the event listener when the component mounts
        console.log('adding event listener')
        window.addEventListener('message', handleMessage);

        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('message', handleMessage);
        };

    },[]);

    //' OR balance > 1000000

    return (
        <Container component="main" maxWidth="md">
            <CssBaseline />
            <Paper className={classes.paper}>
                {/* <h2>All Cookies</h2>
                <Typography>Cookies I stole so far...</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="h6">Origin</Typography>
                        {stolenCookies.map((cookie, index) => (
                            <Typography key={index}>{cookie.origin}</Typography>
                        ))}
                    </Grid>
                    <Grid item xs={6}>
                        <Typography variant="h6">Cookie</Typography>
                        {stolenCookies.map((cookie, index) => (
                            <Typography key={index}>{cookie.cookie}</Typography>
                        ))}
                    </Grid>
                </Grid>
                {error && <Typography>{error}</Typography>} */}
                {iframeSrc && 
                (<iframe
                    title="PacketSniffer"
                    src={iframeSrc}
                    style={{ width: '100%', height: '500px', border: 'none' }}
                />)}
            </Paper>
            {/* <OpenModal modal={"finish"} title="Finish"></OpenModal> */}
        </Container>
    );
};

export default PacketSnifferScenario;