import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, CircularProgress, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckIcon from '@mui/icons-material/Check.js';
import CloseIcon from '@mui/icons-material/Close.js';
import { useDispatch } from 'react-redux';
import { IconButton, Paper } from '@material-ui/core';
import copy from 'clipboard-copy';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getSolution } from 'actions/challenge.js';

const Solution = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);

  const {content} = props;
 
  const theme = useTheme();
  const classes = useStyles(theme);

  const [solutionContent, setSolutionContent] = useState(null);

  const dispatch = useDispatch();

  const codeSnippetRef = useRef({});

  const [copied, setCopied] = useState({});

  const copyToClipboard = (key) => {

    if(codeSnippetRef.current[key]){
      const currentRef = codeSnippetRef.current[key];
      copy(currentRef.innerText);
      setCopied({ ...copied, [key]: true });
      setTimeout(() => setCopied({ ...copied, [key]: false }), 2000); // Reset the copied state after 2 seconds
    }
  };

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  useEffect(() => {
    if(!isOpen) return;

    if(isOpen === "solution") {
      console.log('fetch solution...');
      // fetching solution for this step
      dispatch(getSolution()).then((response) => {
        console.log('response', response);
        Object.keys(response.solution).map((key) => copied[key] = false);
        console.log(copied)
        setSolutionContent(response.solution);
      });
    }

  }, [isOpen]);

  return (
    <div>
        <Modal
            open={isOpen === "solution"}
            onClose={handleCloseModal} 
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}>Step Solution</h2>
                <Box className={`${classes.textContent} text-start flex-column w-100`} style={{ display: 'flex' }}>
                  {solutionContent ? 
                    (
                      <>
                        {typeof solutionContent == "string" ? 
                          <Typography>
                            {solutionContent}
                          </Typography>
                         : Object.keys(solutionContent).map((key, index) => (
                            <>
                              <Typography variant="h6" id="modal-title" className={classes.textSnippets}>
                                {key} {/* This is your "keyA", "keyB", etc. */}
                              </Typography>

                                  {key === "message"
                                    ? 
                                    <Typography variant="body2"  style={{padding: "0px"}}>
                                      {solutionContent[key]}
                                    </Typography>
                                    :
                                    <Paper className={classes.textContentSnippets}>
                                      <Typography key={index} variant="body2" component="pre"  style={{padding: "0px"}} className={classes.textSnippets}>

                                        <pre style={{padding: "1rem 0px"}}>
                                          <code ref={(ref) => (codeSnippetRef.current[key] = ref)}>{solutionContent[key]}</code>
                                        </pre>
                                      </Typography>
                                      <IconButton
                                          color="primary"
                                          onClick={() => copyToClipboard(key)}
                                          disabled={copied[key]}
                                        >
                                          {copied[key] ? <CheckCircleIcon /> : <FileCopyIcon />}
                                        </IconButton>
                                          {copied[key] && ' Copied!'}
                                    </Paper>

                                  }
                            </>
                          ))}
                      </>
                    ) : (
                      <Box className='w-100 d-flex justify-content-center'>
                        <CircularProgress />
                      </Box>
                    )
                  }
                </Box>
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    Got it
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default Solution;