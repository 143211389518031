import React, { useContext, useEffect, useState } from 'react';
import { Container, Button,Typography, CssBaseline, Paper, Grid, CircularProgress} from "@material-ui/core";

// import { useTheme } from "@material-ui/core";
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { useNavigate, useParams } from 'react-router-dom';
import { SolutionsContext, SolutionsContextProvider } from '../../contexts/SolutionsContext.js';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChallenge, getChallengeAnswers, getChallengeStatus, scenarioAuth, setChallenge } from '../../actions/challenge.js';
import ScenarioInstructionsModal from '../Modal/ScenarioInstructionsModal.js';
import ScenarioProgress from '../Modal/ScenarioProgress.js';
import ScenarioGuidance from '../Modal/ScenarioGuidance.js';
import { ModalContext } from '../../contexts/ModalContext.js';
import ChallengeCompletedModal from '../Modal/challengeCompleted.js';
import SocialProfileScenario from './Scenarios/SocialProfileScenario/SocialProfileScenario.js';
import BankScenario from './Scenarios/BankScenario/BankScenario.js';
import AttackerScenario from './Scenarios/AttackerScenario/AttackerScenario.js';
import { BankAuthContextProvider } from '../../actions/challenge.js';
import { ChallengeDataContext, ChallengeDataProvider } from '../../contexts/ChallengeData.js';
import BrowserTab from './BrowserTab/BrowserTab.js';
import ResetLevelButton from '../Button/ResetLevelButton/ResetLevelButton.js';
import OpenModal from '../Button/ResetLevelButton/OpenModal.js';
import OpenArticle from 'Components/Modal/OpenArticle.js';
import UnfinishedChallenge from 'Components/Modal/UnfinishedChallenge.js';
import ChallengeSnippets from 'Components/Modal/challengeSnippets.js';
import PacketSnifferScenario from './Scenarios/PacketSnifferScenario/PacketSnifferScenario.js';
import BrowserSwitch from './Browser/Browser.js';
import Solution from 'Components/Modal/Solution.js';
import Hint from 'Components/Modal/Hint.js';
import Skip from 'Components/Modal/Skip.js';
import EmailsScenario from './Scenarios/EmailsScenario /EmailsScenario.js';
import PhishingScenario from './Scenarios/PhishingScenario/PhishingScenario.js';
import ECommerceScenario from './Scenarios/ECommerceScenario/ECommerceScenario.js';

const Challenge = () => { 

  const {isOpen, setIsOpen, setText, text} = useContext(ModalContext);
  const {solutions, setSolutions} = useContext(SolutionsContext);
  const { setTabs, restart, selectedBrowser, session, setBrowsers, setBrowsersAndTabs, setSelectedBrowser, setRestart, setSession, activeTab } = useContext(ChallengeDataContext);

  const [challengeInfo, setChallengeInfo] = useState({});
  const [challengeStatus, setChallengeStatus] = useState({});
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [challengeGuidance, setChallengeGuidance] = useState([]);
  const [stepNumber, setStepNumber] = useState(0);
  const { challenge } = useParams();

  const theme = useTheme();
  const classes = useStyles(theme);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
 
  let challengeSelector = useSelector(state => state.challengeProgress);

  useEffect(() => {

    if (!activeTab) return;


    const fetchSession = async () => {
      try {
        // const response = await axios.get('http://localhost:5000/api/session', {
        //   withCredentials: true, // Send cookies along with the request
        // });

        // console.log(selectedBrowser, activeTab, session, selectedBrowser)

        if(session.sessionId)
        {
          const expirationDate = new Date();
          expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
          if(!document.cookie.includes('session_id='))
          {
            // document.cookie = `session_id=${session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
          }
        }

        console.log("session", session, selectedBrowser, activeTab)

        dispatch(scenarioAuth({scenario: activeTab, browser: selectedBrowser})).then(data => {
          if(data)
          {
            if(data.isAuthenticated === false){
              document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            }
            else{
              const expirationDate = new Date();
              expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now


              if(!document.cookie.includes('session_id='))
              {
                // document.cookie = `session_id=${data.session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
              }
            }
            setSession(data.session);
          }
        });

        // Assuming the response has a property isAuthenticated
        // dispatch(setAuthenticated(response.data.isAuthenticated));
      } catch (error) {
        console.error('Error fetching session:', error);
      }
    };

    fetchSession();
  }, [dispatch, activeTab, selectedBrowser]);


  // when the user moves on to the next challenge, this useEffect is triggered
  useEffect(() => {
    
    console.log(challengeSelector, )
    if (!challengeSelector.data) return;

    if(!challengeSelector.error){
      setChallengeStatus(challengeSelector.data);
      console.log(challengeSelector.data);  
      if(Object.keys(challengeSelector.data).length > 0)
      {
        let lastIncompleteStep = challengeSelector.data.findIndex(step => step.isComplete === 0);
        setIsLastStep(lastIncompleteStep === -1 ? true : false)
        setCurrentStepNumber(lastIncompleteStep === -1 ? challengeSelector.data.length : lastIncompleteStep);
        
    
        if(Object.keys(challengeSelector.data).length > 0 && Object.keys(challengeSelector.data).every(step => challengeSelector.data[step].isComplete === 1)){
          console.log("asdfjhdsaljfahlas", challengeSelector.data)
          if(challengeGuidance.length > 0){
            setIsOpen("guidance")
          }
          else{
            setIsOpen("finish")
          }
        }
      }
    }
  },[challengeSelector, challengeSelector.data]);

  useEffect(() => {

    // const fetchChallengeStatus = async (other) => {
    //   const data = await dispatch(getChallengeStatus());

    //   if (data) {
    //     // returns only the stats of the current challenge
    //     setChallengeInfo(prev => ({ ...other, ...data }));

    //     if (Object.keys(data.challenge).every(step => data.challenge[step].isComplete === 1)) {
    //       navigate("/scenario-description");
    //     }
    //   } else {
    //     setChallengeInfo(prev => ({ ...other }));
    //     navigate("/scenario-description");
    //   }
    // }

    // restarting the cahllenge making the state to rerender

    if(restart){
      setRestart(false);
    }

    const fetchData = async () => {
      // try {
        dispatch(getChallengeAnswers()).then(result => {
          setSolutions(result)
        });


        const data = await dispatch(fetchChallenge(challenge));

        if(data.completed){
          navigate(`/completed`);
          return;
        }

        if(!data) return;

        console.log(data)

        setChallengeInfo(data);
        setChallengeStatus(data.steps)
        setBrowsersAndTabs(data.scenarios);
        setChallengeGuidance(data.guidance);

        
        // check if cookie already exists s(user probably refreshes the browser)
        // if not, create a new cookie
        let browserList = Object.keys(data.scenarios);
        setBrowsers(browserList);
          if(document.cookie.includes('browser=') && !browserList.includes(selectedBrowser)){
            const browser = document.cookie.split(';').find(cookie => cookie.includes('browser=')).split('=')[1];
            setSelectedBrowser(browser);
            navigate(`?browser=${browser}`);
          }
          else{
            // document.cookie = `browser=${Object.keys(data.scenarios)[0]}; path=/;`;
            setSelectedBrowser(Object.keys(data.scenarios)[0]);
            navigate(`?browser=${Object.keys(data.scenarios)[0]}`);
          }
        setTabs(data.scenarios[Object.keys(data.scenarios)[0]]);

        handleBrowserChange(Object.keys(data.scenarios)[0]);

        if(data.userHasActiveChallenge){
          setIsOpen("activeProgress");
        }

        // const status = await dispatch(getChallengeStatus({challengeSeed: challenge}));

        // console.log(status)

        
        if(data.steps && Object.keys(data.steps) > 0)
        {
          if (Object.keys(data.steps).every(step => data.steps[step].isComplete === 1)) 
          {
            navigate(`/scenario-description/${data.seed}`);
          }
          // setChallengeStatus(data.steps);
        }

  
        // if (challengeSelector) {
        //   setTabs(challengeSelector.scenarios);
        //   fetchChallengeStatus(challengeSelector);
        // } else {
          // const setChallengeResult = await dispatch(setChallenge());
          // fetchChallengeStatus(setChallengeResult);
        // }
      // } catch (error) {
      //   console.error("Error fetching data:", error);
      // }
    };
  
    fetchData();
  }, [restart]);

  useEffect(() => {

    if(!challengeGuidance) return;
    if(!challengeStatus) return;

    if(challengeGuidance.length === 0) return;

    console.log(challengeGuidance, challengeStatus)
        setStepNumber(currentStepNumber);
        setIsOpen("guidance")

  }, [challengeGuidance, challengeStatus, currentStepNumber]);

  const handleBrowserChange = (browser) => {
    // setSelectedBrowser(browser);

    // console.log(browser)
    
    // const expirationDate = new Date();
    // expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
    // document.cookie = `browser=${browser}; expires=${expirationDate.toUTCString()}; path=/;`;
    
    // navigate(`?browser=${browser}`);
  };
  

    return (

      <>
        <Container  className="text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center">
          <ScenarioInstructionsModal content={challengeInfo  && challengeInfo.description} title={challengeInfo.title} isOpen={isOpen}></ScenarioInstructionsModal>
          <ChallengeCompletedModal isOpen={isOpen} challenge={challengeInfo.activeChallenge}></ChallengeCompletedModal> 
          <ScenarioProgress  content={challengeStatus} isOpen={isOpen} setStepNumber={setStepNumber}></ScenarioProgress>
          <ScenarioGuidance  guidance={challengeGuidance} isOpen={isOpen} stepNumber={stepNumber} isLastStep={isLastStep} category={challengeInfo.article && challengeInfo.article.category} slug={challengeInfo.article && challengeInfo.article.slug}></ScenarioGuidance>
          <Solution isOpen={isOpen}></Solution>
          <Hint isOpen={isOpen}></Hint>
          <Skip isOpen={isOpen}></Skip>
          <UnfinishedChallenge activeChallenge={challengeInfo.activeChallenge} currentChallenge={challenge} isOpen={isOpen}></UnfinishedChallenge>
          <ChallengeSnippets content={challengeInfo.snippets} isOpen={isOpen}></ChallengeSnippets>
          <OpenArticle  category={challengeInfo.article && challengeInfo.article.category} slug={challengeInfo.article && challengeInfo.article.slug} isOpen={isOpen}></OpenArticle>
            {/* <Typography variant="h5" className={`${classes.text} m-3`}>{challengeInfo.title}</Typography> */}

            <Grid container direction="column" style={{ height: '100%' }}>

            <Grid item>
              <ResetLevelButton></ResetLevelButton>
              <OpenModal modal={"instructions"} title="Description"></OpenModal>
              <OpenModal modal={"progress"} title="Instructions"></OpenModal>
              <OpenModal modal={"guidance"} title="Guidance"></OpenModal>
              <OpenModal modal={"article"} title="Article"></OpenModal>
              <OpenModal modal={"hint"} title="Hint"></OpenModal>
              <OpenModal modal={"solution"} title="Solution"></OpenModal>
              <OpenModal modal={"skip"} title="Skip Challenge"></OpenModal>
              {challengeInfo.snippets && (<OpenModal modal={"snippets"} title="Snippets"></OpenModal>)}
            </Grid>

            <Grid item>
              <BrowserSwitch handleBrowserChange={handleBrowserChange}></BrowserSwitch>
            </Grid>
            <Grid item style={{flexGrow: 1}}>

              <Paper className={`${classes.loginContainer} ${"overflow-auto"}`} elevation={3} style={{ padding: '20px' }}>
                <BrowserTab></BrowserTab>
                <CssBaseline />
                
                <BankAuthContextProvider>
                  {/* container which will include the page. The container will be scrollable */}
                  <Container maxWidth="100" className="position-relative p-0 w-100">
                    {activeTab === "attacker" ? <AttackerScenario></AttackerScenario> : <></>}
                    {activeTab === "packetsniffer" ? <PacketSnifferScenario></PacketSnifferScenario> : <></>}
                    {activeTab === "phishing" ? <PhishingScenario></PhishingScenario> : <></>}
                    {activeTab === "stolencreditcards" ? <PhishingScenario></PhishingScenario> : <></>}
                    {activeTab === "bank" ? <BankScenario></BankScenario> : <></>}
                    {activeTab === "flopify" ? <ECommerceScenario></ECommerceScenario> : <></>}
                    {activeTab === "bazoommail" ? <EmailsScenario></EmailsScenario> : <></>}
                    {activeTab === "bestsocial" ? <SocialProfileScenario></SocialProfileScenario> : <></>}
                    {!activeTab && 
                      <Container maxWidth="lg" className="position-relative p-0 d-flex justify-content-center align-items-center">
                        <CircularProgress />
                      </Container>
                    }
                  </Container>
                </BankAuthContextProvider>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };
  

export default Challenge;
