import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Paper, CardMedia, Grid } from '@mui/material';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { useDispatch, useSelector } from 'react-redux';
import { getProduct } from 'actions/ecommerce';
import { Button } from '@material-ui/core';

const Product = () => {

    const [product, setProduct] = useState({});
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    const { page, setPage, user, setUser, url, setUrl, domain, session, setDomain, tabs, setTabs, params } = useContext(ChallengeDataContext);

    const {data} = useSelector((state) => state.ecommerce);

    useEffect(() => {

        if (!params.slug) return;

        if(params.deliver_to){
            const deliver_to = document.getElementById('deliver_to');
            // deliver_to.innerHTML = `${params.deliver_to}`;
        }
        
        if (data.products.length === 0 && error === null)
        {
          dispatch(getProduct({slug: params.slug})).then((response) => {
              if (response && response.product) {
                  setProduct(response.product);
              }
              else{
                setError("Error fetching products");
              }
          })
          .catch((error) => {
              console.error(error);
          });
        }
        else{
          const productFound = data.products.find((product) => product.slug === params.slug);
          if(productFound){
            setProduct(productFound);
          }
          else{
            setProduct(null);
            setError("Product not found");
          }
        }
    }, [params, data]);

    const handleBuy = (slug) => {
      setPage("payment");
      // setUrl(domain+`/product?slug=${product.slug}`);
      setUrl(domain+`/payment?slug=${slug}&deliver_to=${encodeURIComponent(data.authUser.address)}`);
    }

  return (
    <Container>
      {error && <Typography variant="h6" color="error" style={{padding: "16px"}} gutterBottom>{error}</Typography>
      }
      {product && <>
        <Typography variant="h4" gutterBottom>
          {product.title}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <CardMedia component="img" height="auto" image={product.image} alt={product.title} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper style={{ padding: '16px' }}>
              <Typography variant="body1" paragraph>
                <strong>Description:</strong> {product.description}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Stock:</strong> {product.stock}
              </Typography>
              <Typography variant="body1" paragraph>
                <strong>Price:</strong> ${product.price}
              </Typography>
              <Typography variant="body1">
                <strong>Upload Date:</strong> {new Date(product.createdAt).toLocaleString()}
              </Typography>
              {/* <p id="deliver_to">

              </p> */}
              {/* <Typography variant="body2" color="textSecondary" id="deliver_to">
                
              </Typography> */}
              <Button variant="contained" color="primary" onClick={() => handleBuy(product.slug)}>
                Buy
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </>}
    </Container>
  );
};

export default Product;
