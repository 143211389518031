import { GET_QUESTIONS, GET_QUESTIONS_ERROR, SUBMIT_QUESTION_ANSWER, SUBMIT_QUESTION_ANSWER_ERROR, GET_QUESTION_ANSWERS, GET_QUESTION_ANSWERS_ERROR, GET_ANSWERED_QUESTIONS, GET_ANSWERED_QUESTIONS_ERROR } from "../constants/actionTypes.js";
import * as api from "../api/index.js";

export const fetchQuestions = (challenge) => async (dispatch) => {
  try{
    const {data} = await api.fetchQuestions(challenge);


    // ' OR balance > 1000000 --
    console.log(data)
      dispatch({ type: GET_QUESTIONS, payload: data });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_QUESTIONS_ERROR, payload: e.message });
  }
};

export const fetchAnsweredQuestions = (info) => async (dispatch) => {
  try{
    const {data} = await api.fetchAnsweredQuestions(info);

    // ' OR balance > 1000000 --
      dispatch({ type: GET_ANSWERED_QUESTIONS, payload: data });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_ANSWERED_QUESTIONS_ERROR, payload: e.message });
  }
};

export const submitAnswer = (info) => async (dispatch) => {
  try{
    const {data} = await api.submitAnswer(info);

    // ' OR balance > 1000000 --
      dispatch({ type: SUBMIT_QUESTION_ANSWER, payload: data });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: SUBMIT_QUESTION_ANSWER_ERROR, payload: e.message });
  }
};