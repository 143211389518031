import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, TextField, Button, Typography } from '@mui/material';
import { getProfileSettings, updateUser } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData';

const Settings = () => {
  const [name, setName] = useState(null);
  const [username, setUsername] = useState(null);
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState(null);
  const [currentPassword, setCurrentPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  
  const [error, setError] = useState(null);
  const [noUsersError, setNoUsersError] = useState(null);

  const isMounted = useRef(false); // skipping first useEffect render
  // const [user, setUser] = useState({});

  const {page, setPage, session, params, setUrl, uid, url, domain, activeTab} = useContext(ChallengeDataContext);

  const dispatch = useDispatch();

  const {data} = useSelector((state) => state.socialMedia);

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleCurrentPasswordChange = (event) => {
    setCurrentPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleSave = () => {
    // Handle the logic to save the updated profile settings
    // console.log('Name:', name);
    // console.log('Email:', email);

    dispatch(updateUser(
      {
        data: {
          name: name, 
          email: email, 
          username: username, 
          currentPassword: currentPassword, 
          newPassword: newPassword
        }, 
        id: userId
      }
    )).then((result) => {
      if(result.error){
        setError(result.error)
      }
      else{
        setError(result.message);
      }
    })
  };

  // useEffect(() => {
  //   if (!uid) return;


  //   // setUser(session.user);
  //   // setUrl(domain+"/profile/edit?uid="+session.user.username);
  // }, [uid]);

  useEffect(() => {

    // if (!data.profile) return;
    
    if (!session.status) return;
    if (!domain) return;
    console.log(params)
    
    if (isMounted.current) {
      
      if (Object.keys(params).length === 0 ) return;
      // console.log("uid", params, uid, url, params.uid);
      const username = params.uid;

        dispatch(getProfileSettings({username: username, scenario: activeTab, url: url})).then((result) => {
          // console.log(result);
          setName(null);
          setUserId(null);
          setUsername(null);
          setEmail(null);
          if(result){
            if(result.error){
              setNoUsersError(result.error);
            }
            else{
              setError(null);
              setNoUsersError(null)
              setName(result.name);
              setUserId(result.id);
              setUsername(result.username);
              setEmail(result.email);
            }
          }
          else{
            setNoUsersError("An unexpected error happened. Please try again later.");
          }
          // setUrl(domain+"/profile/edit?uid="+username);
        });
      }
      else{
        isMounted.current = true;
      }
  }, [session, params]);

  return (
    <Container maxWidth="sm" className='px-0 py-3'>
      <Typography variant="h4" gutterBottom>
        Profile Settings
      </Typography>
      {!noUsersError ? (
        <>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            value={name || ''}
            onChange={handleNameChange}
          />
          <TextField
            label="Username"
            variant="outlined"
            fullWidth
            margin="normal"
            value={username || ''}
            onChange={handleUsernameChange}
          />
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            value={email || ''}
            onChange={handleEmailChange}
          />
          <Divider style={{ margin: '16px 0' }} />
          <Typography variant="h5" gutterBottom>
            Change Password
          </Typography>
          <TextField
            label="Current Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={currentPassword || ''}
            onChange={handleCurrentPasswordChange}
          />
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            value={newPassword || ''}
            onChange={handleNewPasswordChange}
          />
            <Button variant="contained" color="primary" onClick={handleSave}>
              Save
            </Button>
            {error && (
              <Typography>{error}</Typography>
            )}
        </>
      ) : (
        <Typography>{noUsersError}</Typography>
      )}
    </Container>
  );
};

export default Settings;
