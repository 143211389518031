import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
    circularImage: {
        borderRadius: '50%',
        width: '200px',  // Adjust the size as needed
        height: '200px',  // Adjust the size as needed
        objectFit: 'cover',  // To ensure the image fills the circular container
      },
    text: (theme) => ({
        color: theme.textColor,
      }),
    textSoft: (theme) => ({
        color: theme.textColorSoft,
      }),
      bgSoft: (theme) => ({
        backgroundColor: theme.bgSoft,
      }),
  }));
