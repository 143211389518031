// UsersTab.jsx
import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import RenderRow from './RenderRow';

const users = [
  { id: 1, name: 'User 1', username: 'user1', email: 'user1@example.com', avatar: 'avatar1.jpg' },
  { id: 2, name: 'User 2', username: 'user2', email: 'user2@example.com', avatar: 'avatar2.jpg' },
  // Add more users as needed
];

const UsersTab = () => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Username</TableCell>
          <TableCell>Email</TableCell>
          <TableCell>Avatar</TableCell>
          <TableCell>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {users.map((user) => (
          <RenderRow {...user} />
        ))}
      </TableBody>
    </Table>
  );
};

export default UsersTab;
