import React, { useContext, useEffect, useState } from "react";
import "./sidebarLink.css";
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { Badge, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

export const SidebarLink  = ({ text, Icon, hasBadge,  type }) => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [badgeCount, setBadgeCount] = useState(0);



  // change the badge color to this: rgb(235, 0, 20)

  return (
    <div className="link">
      <div className="wrap">
        <Box style={{marginRight: "1rem"}}>
          {hasBadge && badgeCount > 0 
            ? <Badge badgeContent={badgeCount}  max={99} color="primary">
                <Icon className={classes.color} />
              </Badge>
            : <Icon className={classes.color} />
          }
        </Box>
        <h2 className={classes.color}>{text}</h2>
      </div>
    </div>
  );
}
export default SidebarLink;
