import React, { useState, useEffect, useContext } from "react";
import {
  Container,
  AppBar,
  Typography,
  Toolbar,
  Button,
  Avatar,
  IconButton,
} from "@material-ui/core";
import useStyles from "./styles.js";
import logo from "../../images/logo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AvatarIcon from "../Avatar/AvatarIcon/AvatarIcon.js";
import LogoutButton from "../Button/LogoutButton/LogoutButton.js";
import { AuthContext } from "../../actions/auth.js";
import { useSelector } from "react-redux";
import { DarkMode } from "../../contexts/DarkMode.js";
import * as Regular from "@fortawesome/free-regular-svg-icons";
import * as Solid from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome/index.js";
import { useTheme } from "styled-components";
import { SideMenuContext } from "../../contexts/SideMenuContext.js";
import LanguageSelector from "Components/LanguageSelector/LanguageSelector.js";
import { FormattedMessage } from "react-intl";

export const Navbar = (props) => {

  const {isOpen, setIsOpen, setLocale} = props;
  const { isDarkMode, setIsDarkMode } = useContext(DarkMode);
  const { menuOpen, setMenuOpen } = useContext(SideMenuContext);

  const theme = useTheme();
  const classes = useStyles(theme);
  // const { user } = useContext(AuthContext);
  const user = useSelector((state) => state.auth);

  const location = useLocation();

  useEffect(() => {
    const token = user?.token;

    // manual sign up

    // setUser(JSON.parse(localStorage.getItem("profile")));
  }, [location]);

  const handleLanguageChange = (selectedLocale) => {
    setLocale(selectedLocale);
  };

  return (
    <AppBar className={classes.appBar} position="static" color="inherit">
      <Container maxidth="lg" className={classes.navbarContainer}>
        <div className={classes.brandContainer}>
          <Typography
            className={classes.heading}
            component={Link}
            to="/"
            variant="h4"
            align="center"
          >
            HackMeDown
          </Typography>
          <IconButton className="p-0" onClick={() => setIsDarkMode(!isDarkMode)}>
            <FontAwesomeIcon className={classes.darkModeSwitch} icon={isDarkMode ? Regular.faSun : Regular.faMoon} />
          </IconButton>
          
          <Typography
            className={classes.navLink}
            component={Link}
            to={window.location.hostname.includes("blog") ? (process.env.REACT_APP_PUBLIC_URL || "https://george.tardis.ac") : (process.env.REACT_APP_BLOG_URL || "https://blog.george.tardis.ac")}
            variant="h6"
            align="center"
          >
            {window.location.hostname.includes("blog") ? 
            (<FormattedMessage id="home" defaultMessage="Home"></FormattedMessage>) : (<FormattedMessage id="blog" defaultMessage="Blog"></FormattedMessage>)}
          </Typography>
          <Typography
            className={classes.navLink}
            component={Link}
            to={process.env.REACT_APP_PUBLIC_URL+"/about"}
            variant="h6"
            align="center"
          >
            <FormattedMessage id="about" defaultMessage="About"></FormattedMessage>
          </Typography>
          <LanguageSelector onLanguageChange={handleLanguageChange} />
          {/* add the above in the future */}

          {/* <img className={classes.image} src={logo} alt="icon" height="60" /> */}
        </div>
        <Toolbar className={classes.toolbar}>
          {user && !window.location.hostname.includes("blog") ? (
            <div className={classes.profile}>
              {/* <Avatar
                className={classes.purple}
                alt={user?.result.name}
                src={user?.result.imageUrl}
              >
                {user?.result.name.charAt(0)}
              </Avatar> */}
              <Button style={{ textTransform: 'none', borderRadius: "1.5rem" }} className="p-0" onClick={() => setMenuOpen(true)}>
                  <AvatarIcon username={user?.result.username} avatar={user?.result.avatar}></AvatarIcon>
                {/* className={classes.userName} */}
                <Typography className={[classes.textColor, "px-3"]}  variant="h6">
                  {user?.result.name.split(" ")[0]}
                </Typography>
              </Button>
              {/* <LogoutButton></LogoutButton> */}
            </div>
          ) : (
            <Button
              component={Link}
              to="login"
              variant="contained"
              color="primary"
            >
              <FormattedMessage id="login" defaultMessage="Login"></FormattedMessage>
            </Button>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

// export default Navbar;
