import { GET_STOLEN_COOKIES, GET_STOLEN_COOKIES_ERROR } from "../constants/actionTypes.js";

const initialState = {
  data: [],
  loading: false,
  error: null,
}

export default (bankBalance = initialState, action) => {
  switch (action.type) {
    case GET_STOLEN_COOKIES:
        return {data: action?.payload, loading: false, error: null};
    case GET_STOLEN_COOKIES_ERROR:
        return {data: [], loading: false, error: action?.payload};
    default:
      return bankBalance;
  }
};
