// src/App.js
import React, { useContext, useEffect } from 'react';
import { Container, CssBaseline } from '@mui/material';
import LoginPage from '../../Common/LoginPage/LoginPage.js';
import Dashboard from './Dashboard/Dashboard.js';
import { Paper } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { BankAuthContext, endSession } from '../../../../actions/bankScenario.js';
import { useDispatch, useSelector } from 'react-redux';
import ScenarioInstructionsModal from '../../../Modal/ScenarioInstructionsModal.js';
import { ModalContext } from '../../../../contexts/ModalContext.js';
import { getChallengeAnswers } from '../../../../actions/challenge.js';import { ChallengeDataContext } from 'contexts/ChallengeData.js';
;

const BankScenario = () => {

    const {activeTab, session} = useContext(ChallengeDataContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    console.log(session, "dashboard")

    //' OR balance > 1000000

    return (
        <Container component="main" maxWidth="md">
                {!session.status ? <LoginPage title="Login to your Bank account." /> : <Dashboard />}
            {/* <OpenModal modal={"finish"} title="Finish"></OpenModal> */}
        </Container>
    );
};

export default BankScenario;