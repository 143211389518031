import React, { useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton/index.js';
import SearchIcon from '@mui/icons-material/Search.js';
import { TextField } from '@mui/material';

const SearchBar = ({ onSearch, query = "" }) => {
  const [searchTerm, setSearchTerm] = useState(query);

  useEffect(() => {

    if (!query) return;

    setSearchTerm(query);
  },[query])

  const handleSearch = () => {
    onSearch(searchTerm);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: "1rem" }}>
      <TextField
        label="Search Transaction"
        variant="outlined"
        size="small"
        fullWidth
        value={searchTerm}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
      />
      <IconButton onClick={handleSearch} size="small">
        <SearchIcon />
      </IconButton>
    </div>
  );
};

export default SearchBar;