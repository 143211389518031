import React, { useEffect, useState } from 'react';
import { fetchArticle } from 'actions/blog';
import { useDispatch } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import DOMPurify from 'dompurify'; // Import DOMPurify
import {
  Typography,
  Container,
  Breadcrumbs,
  Link as MuiLink,
  Grid,
  Button,
} from '@material-ui/core';

import { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import useStyles from "./styles.js";

const BlogPost = () => {

  const theme = useTheme();
  const classes = useStyles(theme);

  const [post, setPost] = useState([]);
  const [error, setError] = useState(null);
  
  const { category, slug } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  

  useEffect(() => {
    console.log(category, slug)
    dispatch(fetchArticle(category, slug)).then((result) => {
      console.log(result);
      if(!result){
        setError("An uknown error occurred while fetching this article.");
      }
      else{
        if (result.error) {
          setError(result.error);
        } else {
          setPost(result);
        }
      }
    });

  }, [])

  useEffect(() => {
    if (post && post.content) {
      // Get all elements with class 'spoil-answer'
        const spoilAnswers = document.querySelectorAll('.spoil-answer');

        // Iterate over each spoil-answer element
        spoilAnswers.forEach(spoilAnswer => {
            // Add click event listener
            spoilAnswer.addEventListener('click', function() {
                // Get the next sibling element with class 'solution'
                const solution = document.getElementById(this.dataset.solution);
                
                // Toggle the display of the solution
                if (window.getComputedStyle(solution).getPropertyValue("display") === 'none') {
                    solution.style.display = 'block';
                } else {
                    solution.style.display = 'none';
                }
            });
        });
    }
  
  }, [post]);

  return (
    <Container className={classes.root}>
      <Grid xs className='mb-3'  >

      <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
        <MuiLink component={Link} to="/" color="inherit">
          Home
        </MuiLink>
        <MuiLink component={Link} to={`/${category}`} color="inherit">
          {category}
        </MuiLink>
        <Typography color="textPrimary">{slug}</Typography>
      </Breadcrumbs>
      </Grid>

      {post ? (
        <div>
          <Typography variant="h3" gutterBottom className={classes.title}>
            {post.title}
          </Typography>
          <Typography variant="body1" paragraph className={classes.content}>
            {/* Render sanitized HTML content */}
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(post.content) }} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={() => window.location.href = `${process.env.REACT_APP_PUBLIC_URL || "https://george.tardis.ac"}/scenario-description/${post.challenge.challengeSeed}`}
          >
            Try this example in the simulated environment
          </Button>
        </div>
      ) : (
        <Typography variant="h5" className={classes.loading}>
          Loading...
        </Typography>
      )}
      {error && (
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.error} align="center">
            {error}
          </Typography>
        </Grid>
      )}
    </Container>
  );
};

export default BlogPost;