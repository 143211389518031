import React, { useEffect, useState } from 'react';
import { Menu, MenuItem, Button, Box, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Flag from 'react-world-flags'; // Optional if you use react-world-flags

import useStyles from "./styles.js";
import { useTheme } from "styled-components";

function LanguageSelector({ onLanguageChange }) {
  const [locale, setLocale] = useState('en');
  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();
  const classes = useStyles(theme);

  const languages = [
    { code: 'en', name: 'English', flag: 'US' }, // US flag for English
    { code: 'cn', name: '中文', flag: 'CN' } // China flag for Chinese
  ];

  useEffect(() => {
    // Check if locale cookie exists
    const cookieValue = document.cookie.split('; ').find(row => row.startsWith('locale='));
    if (cookieValue) {
      const localeCookie = cookieValue.split('=')[1];
      if (locale !== localeCookie) {
        setLocale(localeCookie);
        localStorage.setItem('locale', localeCookie);
      }
    }
  }, [locale]);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget); // Set the current button as the anchor element for the menu
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); // Close the menu
  }

  const handleLanguageSelect = (code) => {
    const selectedLocale = code;
    onLanguageChange(selectedLocale);
    setLocale(selectedLocale);
    localStorage.setItem('locale', selectedLocale);
    setAnchorEl(null);
    // store language as a cookie
    document.cookie = `locale=${selectedLocale}; path=/;`;
  };

  return (
    <Box sx={{ padding: 0 }}  >
      {/* Button to open the language menu */}
      <Button
        variant="contained"
        onClick={handleMenuOpen}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {/* Display the selected language flag and name */}
        <Flag code={languages.find(lang => lang.code === locale)?.flag} style={{ width: '20px', height: '15px', marginRight: '10px' }} />
        <Typography
          variant="body1"
          sx={{
            marginRight: '5px',
            lineHeight: 1, // Set line height to avoid vertical spacing
            letterSpacing: 'normal', // Prevent spacing between characters
            whiteSpace: 'nowrap', // Prevent wrapping
            wordBreak: 'keep-all', // Prevent breaking words
            textOrientation: 'upright', // Maintain upright orientation for vertical scripts
            writingMode: 'horizontal-tb', // Ensure horizontal text flow
            display: 'inline', // Inline display to avoid extra space around text
          }}
        >
          {languages.find(lang => lang.code === locale)?.name}
        </Typography>
        <ArrowDropDownIcon />
      </Button>

      {/* Menu for language options */}
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        
      >
        {languages.map((language) => (
          <MenuItem
            key={language.code}
            selected={language.code === locale}
            onClick={() => handleLanguageSelect(language.code)}
          >
            <Box display="flex" alignItems="center">
              <Flag code={language.flag} style={{ width: '20px', height: '15px', marginRight: '10px' }} />
              <Typography
                variant="body1"
                sx={{
                  lineHeight: 1,
                  letterSpacing: 'normal',
                  whiteSpace: 'nowrap',
                  wordBreak: 'keep-all',
                  textOrientation: 'upright',
                  writingMode: 'horizontal-tb',
                  display: 'inline',
                }}
              >
                {language.name}
              </Typography>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}

export default LanguageSelector;
