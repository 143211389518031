import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles(() => ({
  navbarContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  appBar: (theme) => ({
    // borderRadius: 15,
    // margin: "30px 0",
    backgroundColor: theme.bgSoft,
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.45)",
    zIndex: "9999",
  }),
  heading: (theme) => ({
    color: theme.logo,
    padding: "0rem 1rem",
    textDecoration: "none",
  }),
  textColor: (theme) => ({
    color: theme.textColor,
  }),
  navLink: (theme) => ({
    color: theme.navLink,
    textDecoration: "none",
    "&:hover": {
      color: theme.navLinkHover,
    },
    padding: "0rem 1rem",
  }),
  darkModeSwitch: (theme) => ({
    color: theme.darkModeSwitch,
  }),
  image: {
    marginLeft: "15px",
  },
  toolbar: {
    display: "flex",
    justifyContent: "flex-end",
    padding: "0px",
    // width: "400px",
  },
  profile: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  userName: {
    display: "flex",
    alignItems: "center",
  },
  brandContainer: {
    display: "flex",
    alignItems: "center",
  },
}));
