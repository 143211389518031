import React, { useEffect, useState } from 'react';
import { Typography, Button, TextField, Container, Paper } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchQuestions, submitAnswer } from 'actions/questionnaire';
import { useNavigate, useParams } from 'react-router-dom';

const QuestionnairePage = ({pre}) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState({});
  const [answers, setAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [challengeTitle, setChallengeTitle] = useState('');
  const { challenge } = useParams();
  const [error, setError] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleAnswerChange = (event) => {
    const newAnswers = [...answers];
    newAnswers[currentQuestionIndex] = event.target.value;
    setAnswers(newAnswers);

    setCurrentQuestion({ ...currentQuestion, preAnswer: undefined, postAnswer: undefined });


  };

  const handleNextQuestion = () => {
    // Submit the answer to the server
    // console.log(answers[currentQuestionIndex])
    if(answers[currentQuestionIndex]){
      if (currentQuestionIndex < questions.length - 1) {
        setCurrentQuestionIndex(currentQuestionIndex + 1);
      }
      dispatch(submitAnswer({questionID: currentQuestion.questionID, answer: answers[currentQuestionIndex], challenge: challenge, pre: pre}));
      setError(false);
    }
    else{
      setError(true);
    }
  };

  const handlePreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
    setError(false);
  };

  const handleStartChallenge = () => {
    // Navigate to the scenario description page
    // Implement your navigation logic here
    if(answers[currentQuestionIndex])
    {
      dispatch(submitAnswer({questionID: currentQuestion.questionID, answer: answers[currentQuestionIndex], challenge: challenge, pre: pre})).then(() => {
          if(pre){
              navigate(`/scenario-description/${challenge}`);
          }
          else{
              navigate(`/scenario-description`);
          }
      });
    }
    else{
      setError(true);
    }
  };

  useEffect(() => {
    // Fetch questions from the server
    // Implement your fetch logic here



    if(questions.length > 0){
      setAnswers(new Array(questions.length).fill(''));
    }
    else{
        dispatch(fetchQuestions({challenge})).then((data) => {
            setQuestions(data.questions);
            setChallengeTitle(data.challengeTitle);
            setAnswers(new Array(data.length).fill(''));

            setCurrentQuestionIndex(data.index);
        })
    }
    

  }, []);

  useEffect(() => {
    if (!questions.length > 0) return;
    setCurrentQuestion(questions[currentQuestionIndex]);

    if(!questions[currentQuestionIndex][pre ? "preAnswer" : "postAnswer"]) return;

    const newAnswers = new Array(questions.length).fill('');
    newAnswers[currentQuestionIndex] = questions[currentQuestionIndex][pre ? "preAnswer" : "postAnswer"];
    setAnswers(newAnswers);


  }, [currentQuestionIndex, questions]);

  return (
    <>
    <Container style={{textAlign: "center", padding: "0.5rem"}}>

      <Typography variant="h4" gutterBottom>
          {challengeTitle}
        </Typography>
    </Container>
    <Container maxWidth="sm">
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h4" gutterBottom>
          Question {currentQuestionIndex + 1}
        </Typography>
        {error ? <Typography variant="h6" color="error">Please answer the question before proceeding</Typography> : <></>}
        {Object.keys(currentQuestion).length > 0 ?
            <>
                <Typography variant="body1" gutterBottom>
                    {currentQuestion.text}
                </Typography>
                    {currentQuestion.type === 'text' ? (
                <TextField
                    id="answer"
                    label="Your Answer"
                    variant="outlined"
                    fullWidth
                    value={answers[currentQuestionIndex]}
                    onChange={(event) => handleAnswerChange(event)}
                />
                ) : (
                currentQuestion.choices.map((choice, index) => (
                  <div key={index}>
                    <input
                        type="radio"
                        id={`choice${index}`}
                        name="answer"
                        value={choice}
                        checked={answers[currentQuestionIndex] === choice || currentQuestion[pre ? "preAnswer" : "postAnswer"] === choice}
                        onChange={(event) => handleAnswerChange(event)}
                    />
                    <label htmlFor={`choice${index}`}>{choice}</label>
                  </div>
                ))
                )}
                {currentQuestionIndex !== 0 ? 
                <>
                    <Button variant="contained" color="primary" onClick={() => handlePreviousQuestion()}>
                        Previous Question
                    </Button>
                </>
                : <></>
                }
                {currentQuestionIndex === questions.length - 1 ? (
                    <Button variant="contained" color="primary" onClick={() => handleStartChallenge()}>
                        Start Challenge
                    </Button>
                    ) : (
                    <Button variant="contained" color="primary" onClick={() => handleNextQuestion()}>
                        Next Question
                    </Button>
                )}
            </> 
            : <Typography variant="h6">Loading...</Typography>}
      </Paper>
    </Container>
    </>
  );
};

export default QuestionnairePage;
