import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from "react-cookie";
import axios from "axios";
import { getCookies } from 'actions/challenge';
// import Cookies from 'universal-cookie';
 
// Create the ChallengeDataContext
export const ChallengeDataContext = createContext();

// Create the ChallengeDataProvider component
export const ChallengeDataProvider = ({ children }) => {
  // Define state variables for challenge data, user, params, and active tab
  const [challengeData, setChallengeData] = useState([]);
  const [page, setPage] = useState({});
  const [browsersAndTabs, setBrowsersAndTabs] = useState({});
  const [user, setUser] = useState({});
  const [params, setParams] = useState({});
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [uid, setUid] = useState({});
  const [selectedBrowser, setSelectedBrowser] = useState(null);
  const [browsers, setBrowsers] = useState([]);
  const [url, setUrl] = useState({});
  const [allUrls, setAllUrls] = useState([]);
  const [domain, setDomain] = useState({});
  const [restart, setRestart] = useState(false);
  const [session, setSession] = useState({status: false});
  const [undoHistory, setUndoHistory] = useState({});
  const [undoHistoryPointer, setUndoHistoryPointer] = useState({});
  const [scriptValid, setScriptValid] = useState(false);
  // const [cookies, removeCookie] = useCookies([]);

  // const sessionsStored = useSelector((state) => state.bank);

  const dispatch = useDispatch();

  // Define the getUser function
  const getUser = () => {
    // Your implementation to get the user data
    // ...
  };

  // const resetStates = (tab = null) => {
  //   setChallengeData([]);
  //   setPage({});
  //   setSession({});
  //   setUser({});
  //   setParams({});
  //   setTabs([]);
  //   setActiveTab(null);
  //   setUid({});
  //   setUrl(null);
  //   setDomain(null);
  //   console.log("states reset");
  // };

  const resetStates = (tab = null, browser = null) => {
    let browserCookie = document.cookie.split(';').filter((item) => item.includes('browser'))[0];
    let browserVal = browserCookie ? browserCookie.split('=')[1] : null;

    console.log(browserVal, tab, "printing browser cookie")
    setChallengeData([]);
    // setBrowsersAndTabs({});
    setSelectedBrowser((prev) => (browserCookie ? prev : null));
    setBrowsers((prev) => (tab ? prev : []));
    setPage((prev) => (tab ? { ...prev, [tab]: undefined } : {}));
    // setSession({});
    setSession((prev) => ({ ...prev, [browserVal]: (tab ? {[tab]: undefined} : {status: false})}));
    setUser((prev) => (tab ? { ...prev, [tab]: undefined } : {}));
    setParams((prev) => (tab ? { ...prev, [tab]: undefined } : {}));
    setTabs((prev) => (tab ? prev : []));
    setActiveTab((prev) => (tab ? prev : null));
    setUid((prev) => (tab ? { ...prev, [tab]: undefined } : {}));
    setUrl((prev) => (tab ? prev : null));
    setAllUrls((prev) => (tab ? prev : []));
    setUndoHistory((prev) => (tab ? { ...prev, [tab]: undefined } : {}));
    setUndoHistoryPointer((prev) => (tab ? { ...prev, [tab]: undefined } : 0));
    setDomain((prev) => (tab ? prev : {status: false}));
    setScriptValid(false);
    console.log("states reset");
  };

  // session = {
  //   'activeTab' : {
  //     status: false,
  //     user: {
  //       username: 'test',
  //       password: 'test'
  //     }
  //   },
  //   'activeTab2' : {
  //     status: false,
  //     user: {
  //       username: 'test',
  //       password: 'test'
  //     }
  //   },
  // }

  const updateChallengeContextValue = () => ({

    challengeData,
    getUser,
    params: params[activeTab],
    setParams: (value) => setParams({ ...params, [activeTab]: value }),
    uid: uid[activeTab],
    setUid: (value) => setUid({ ...uid, [activeTab]: value }),
    user: user[activeTab],
    setUser: (value) => setUser({ ...user, [activeTab]: value }),
    // session,
    // x,
    session: (session[selectedBrowser] ??= {})[activeTab] ??= { status: false, activeTab: activeTab },
    // session: session[activeTab] == undefined ? {status: false, activeTab: activeTab} : session[activeTab],
    setSession: (value) => {setSession({ ...session, [selectedBrowser]: {[activeTab]: value} })},
    url: url[activeTab],
    setUrl: (value) => {setUrl({ ...url, [activeTab]: value })},
    domain: domain[activeTab],
    setDomain: (value) => {setDomain({ ...domain, [activeTab]: value })},
    page: page[activeTab],
    setPage: (value) => setPage({ ...page, [activeTab]: value }),
    undoHistory: undoHistory[activeTab],
    setUndoHistory: (value) => setUndoHistory({...undoHistory, [activeTab]: value}),
    undoHistoryPointer: undoHistoryPointer[activeTab],
    setUndoHistoryPointer: (value) => setUndoHistoryPointer({...undoHistoryPointer, [activeTab]: value}),
    tabs,
    setTabs,
    scriptValid,
    setScriptValid,
    allUrls,
    setAllUrls,
    browsers,
    setBrowsers,
    browsersAndTabs,
    setBrowsersAndTabs,
    selectedBrowser,
    setSelectedBrowser,
    activeTab,
    setActiveTab,
    restart,
    setRestart,
    resetStates
  });

  const [challengeContextValue, setChallengeContextValue] = useState(updateChallengeContextValue);

  useEffect(() => {

    // const cookies = new Cookies();

    // console.log(session, activeTab)
    if (activeTab === null) return;
    if (selectedBrowser === null) return;
    if (session === undefined) return;

    
    console.log(session, selectedBrowser, session[selectedBrowser], "aslfjhljfdjlhaljfaj jhjkfdh fljahfljad hljdhf ljda hflja fhla")
    if(session[selectedBrowser])
    {
      console.log(selectedBrowser, activeTab, session[selectedBrowser][activeTab], "printing session")
      if (session[selectedBrowser][activeTab] == undefined || session[selectedBrowser][activeTab].status === false){ 
        console.log("hi")
        dispatch(getCookies({scenario: activeTab})).then(data => {
          // console.log(data.data, activeTab)
          console.log(data)
          setSession({...session, [selectedBrowser]: {[activeTab]: data.data}});
          setUser({...user, [activeTab]: data.data.user});
        });
      }
    }

    // setChallengeContextValue(updateChallengeContextValue());

  }, [activeTab, selectedBrowser]);

  useEffect(() => {

    // reset sessions when the browser is changed
    console.log("destory session changed browser")

    // reset url when the browser is changed
    setUrl(domain);

    setSession({});
  }, [selectedBrowser]);

  useEffect(() => {

    if (activeTab === null) return;

    setChallengeContextValue(updateChallengeContextValue());

  }, [session]);

  useEffect(() => {

    if (allUrls.length === 0) return;
    if (allUrls.length === 1) return;

    url[allUrls[0]] = allUrls[1];
  }, [allUrls]);

  useEffect(() => {

    // console.log(tabs, url,"printing tabs")
    if (tabs === undefined || tabs.length === 0) return;
    if(!selectedBrowser) setSelectedBrowser(tabs[0]);
    // console.log(selectedBrowser," adslfdsjflfdajfhdajfhadsljkfh")
    if(!activeTab) setActiveTab(tabs[0]);
    if (activeTab === null) return;

    if(domain[activeTab] === undefined) setDomain({...domain, [activeTab]: `https://www.${activeTab.toLowerCase()}.com`});
    if(url[activeTab] === undefined) setUrl({...url, [activeTab]: `https://www.${activeTab.toLowerCase()}.com`});



    if(uid[activeTab] === undefined) setUid({...uid, [activeTab]: null});
    if(params[activeTab] === undefined) setParams({...params, [activeTab]: {}});
    if(user[activeTab] === undefined) setUser({...user, [activeTab]: null});
    if(page[activeTab] === undefined) setPage({...page, [activeTab]: null});
    if(undoHistory[activeTab] === undefined) setUndoHistory({...page, [activeTab]: []});
    if(undoHistoryPointer[activeTab] === undefined) setUndoHistoryPointer({...page, [activeTab]: 0});


    if (domain === null || url === null) return;

    setChallengeContextValue(updateChallengeContextValue());

  }, [tabs, selectedBrowser, activeTab, domain, page, user, params, uid, url, scriptValid]);

  // Create an object with the challenge data, getUser function, params, setParams, uid, and setUid

  return (
    <ChallengeDataContext.Provider value={challengeContextValue}>
      {children}
    </ChallengeDataContext.Provider>
  );
};
