import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from '@material-ui/core';

const PostModal = ({ open, handleClose, row, handleEdit, handleDelete, btnClicked }) => {
  const [editedPost, setEditedPost] = useState({ ...row });

  console.log(open);

  const handleEditSave = () => {
    // Implement the logic to save the edited post
    handleEdit(editedPost);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{row.username}'s Post</DialogTitle>
      <DialogContent>
        {btnClicked === "delete" ? (
            <p>Are you sure you want to delete this post?</p>
        ) : (
            <>
                <Table>
                    <TableBody>
                        {Object.keys(row).map((key) => (
                            <TableRow key={key}>
                                <TableCell>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}</TableCell>
                                {btnClicked === "edit" ? 
                                (
                                    <>
                                        {key === 'content' ? (
                                            <TableCell>
                                                <textarea
                                                    rows={5}
                                                    cols={50}
                                                    value={editedPost[key]}
                                                    onChange={(e) => setEditedPost({ ...editedPost, [key]: e.target.value })}
                                                />
                                            </TableCell>
                                        ) : (
                                            <TableCell>
                                            <input
                                                type="text"
                                                value={editedPost[key]}
                                                onChange={(e) => setEditedPost({ ...editedPost, [key]: e.target.value })}
                                            />
                                            </TableCell>
                                        )}
                                    </>
                                ) : (
                                    <TableCell>{row[key]}</TableCell>
                                )}
                            </TableRow>
                            )
                        )}
                    </TableBody>
                </Table>
            </>
        )}
        </DialogContent>
        <DialogActions>
            {btnClicked === "edit" ? (
                <Button onClick={() => handleEdit(row)}>
                    Save
                </Button>
            ) : (<></>)}
            {btnClicked === "view" ? (
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            ) : (<></>)}
            {btnClicked === "delete" ? (
                <Button onClick={() => handleDelete(row)}>
                    Delete
                </Button>
            ) : (<></>)}
        </DialogActions>  
    </Dialog>
  );
};

export default PostModal;
