import React, { useState } from "react";
import { Container, Grow, Grid, Typography, Box } from "@material-ui/core";
import useStyles from "./styles.js";
import { useTheme } from "styled-components";

const Footer = () => {
  const theme = useTheme();
const classes = useStyles(theme);;
  const [currentDate, setCurentDate] = useState(new Date().getFullYear());

  return (
    <Box pb={2} pt={2} style={{"zIndex": 1}}>
      <Typography className={classes.text} align="center">© {currentDate} HackMeDown.</Typography>
    </Box>
  );
};

export default Footer;
