import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckIcon from '@mui/icons-material/Check.js';
import CloseIcon from '@mui/icons-material/Close.js';
import { useDispatch } from 'react-redux';
import { openArticle } from 'actions/challenge.js';

const OpenArticle = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);
  const [userClickedYes, setUserClickedYes] = useState(false);
  const {category, slug} = props;

  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsOpen(null);
  };



  useEffect(() => {

    if( !userClickedYes) return; 
    if( isOpen == null) return; 

    const fetchData = async () => {
      try {
        const data = await dispatch(openArticle({ category, slug }));
        console.log(data, category, slug);
        window.open(`${process.env.REACT_APP_BLOG_URL || "https://blog.george.tardis.ac"}/${category}/${slug}`, '_blank');
        handleCloseModal();
      } catch (error) {
        console.error('Error opening article:', error);
      }
    };

    console.log(userClickedYes, isOpen)

    if (userClickedYes && isOpen === 'article') {
      fetchData();
    }
  }, [isOpen, category, slug, dispatch, userClickedYes]);

  const handleOpenArticle = () => {
    setUserClickedYes(true);
    // handleCloseModal();
  };

  return (
    <div> 
        <Modal
            open={isOpen === "article"}
            onClose={handleCloseModal} 
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}>Open Article</h2>
                <Box className={classes.textContent}>
                    By opening the article, you may be exposed to partial solutions of this challenge. Are you sure you want to continue?
                </Box>
                <Button variant="contained" className="mt-3" onClick={() => handleOpenArticle()}>
                    Yes
                </Button>
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    No!
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default OpenArticle;