import { useEffect, useState } from "react";


function LanguageSelector({ onLanguageChange }) {

    const [locale, setLocale] = useState('en');

    useEffect(() => {
      // check if locale cookie exists
      const cookieValue = document.cookie.split('; ').find(row => row.startsWith('locale='));
  
      if (cookieValue) {
        const localeCookie = cookieValue.split('=')[1];
        if(locale !== localeCookie){
          setLocale(localeCookie);
        }
      }
  
    }, [locale]);

    const handleLanguageSelect = (e) => {
      const selectedLocale = e.target.value;
      onLanguageChange(selectedLocale);
      setLocale(selectedLocale);
      // store language as a cookie
    };
  
    return (
      <select onChange={handleLanguageSelect} value={locale}>
        <option value="en" >English</option>
        <option value="ch" >Chinese 中文</option>
        {/* Add more language options */}
      </select>
    );
  }

export default LanguageSelector;