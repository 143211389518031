import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetLevel } from 'actions/challenge.js';
import { AuthContext } from 'actions/auth.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const UnfinishedChallenge = (props) => {

  const {user} = useContext(AuthContext);
  const {isOpen, setIsOpen, setText} = useContext(ModalContext);
  const { resetStates, tabs, setSession, setRestart } = useContext(ChallengeDataContext);

  const {content, activeChallenge, currentChallenge} = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    setIsOpen(null); 

    dispatch(resetLevel({username: user.result.username, resetLastProgress: true, currentChallenge: currentChallenge, activeChallenge: activeChallenge})).then(data => {

      if(tabs.length > 0)
      {
          for(let i = 0; i < tabs.length; i++){
              document.cookie = `${tabs[i]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
          }
      }
      setRestart(true);
      resetStates();
      navigate(`/challenge/${currentChallenge}`)
      window.location.reload(false);
    });
  };

  const goBackToActiveChallenge = () => {
    navigate(`/challenge/${activeChallenge}`)
    window.location.reload(false);
  }

  return (
    <div>
        <Modal
            open={isOpen === "activeProgress"}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}>Overwrite Progress</h2>
                <Box className={classes.textContent}>
                    <Typography variant="p" className={classes.text}>
                        We detected that you have an active challenge in progress, {activeChallenge}. If you continue, your progress will be overwritten. Are you sure you want to continue?
                    </Typography>
                </Box>
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    Yes
                </Button>
                <Button variant="contained" className="mt-3" onClick={() => goBackToActiveChallenge()}>
                    No
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default UnfinishedChallenge;