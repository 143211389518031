import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  Grow,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox,
  Box,
} from "@material-ui/core"; 
import LockOutlinedIcon from "@material-ui/icons/LockOutlined.js";
import { Input } from "./Input.js";
import useStyles from "./styles.js";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import Icon from "./Icon.js";
import { useDispatch } from "react-redux";

import { AuthContext, signin, signup } from "../../actions/auth.js";
import { useTheme } from "styled-components";
import { FormattedMessage } from "react-intl";

import { styled } from '@mui/system';
import AuthInput from "Components/Input/AuthInput.js";

import countryList from 'react-select-country-list';
import Flag from 'react-world-flags'; // Import the Flag component
import Select from 'react-select'; // Import react-select
import ReCAPTCHA from "react-google-recaptcha";

// import { useHistory } from 'react-router-dom';


const initialState = {
  // firstName: "",
  // lastName: "",
  userName: "",
  email: "",
  password: "",
  confirmPassword: "",
  recaptcha: "",
};

const Auth = ({ screen }) => {

  const state = null;

  const theme = useTheme();
  const classes = useStyles(theme);

  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(screen == "login" ? false : true);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(null);
  const [surveyLink, setSurveyLink] = useState(null);

  const [captcha, setCaptcha] = useState(null);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const history = useHistory();

  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, isSignup, "howdy");

    if(!checked && isSignup){
      setError("Please read and agree to the terms and conditions.");
      return;
    }

    if (isSignup) {
      dispatch(signup(formData, navigate)).then(result => {
        console.log(result, "adflaldfafajlfhadljhs")
        if(result.message){
          setError(result.message)
          if(result.survey){
            setSurveyLink(result.survey)
          }
        }else{
          setError(null);
          // expires in one week
          navigate("/email-verification-sent")
          // history.push('/email-verification-sent');
        }
      });
    } else {
      dispatch(signin(formData, navigate)).then(result => {
        console.log(result, "adflaldfafajlfhadljhs")
        if(result.message){
          setError(result.message)
        }else{
          setError(null);
        }
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj; // if res is undefined, then result will be undefiend
    const token = res?.tokenId;
    try {
      dispatch({ type: "AUTH", data: { result, token } });

      navigate("/");
    } catch (e) {
      console.log(e);
    } 
    console.log("Google Sign in was a success");
  };
  const googleFailure = (error) => {
    console.log(error);
    console.log("Google Sign in was a failure. Try again later");
  };

  const countryOptions = countryList().getData().map((country) => ({
    value: country.value,
    label: (
      <div className={classes.flagOption}>
        <Flag code={country.value} className={classes.flagIcon} alt={country.label} />
        {country.label}
      </div>
    ),
  }));

  const handleCountryChange = (selectedCountry) => {
    handleChange({ target: { name: 'region', value: selectedCountry.value } });
  };


  return (
    <Grow in>
      <Container className={`${classes.bg} ${classes.text}`} component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography className={classes.text} variant="h5">
            {isSignup ? <FormattedMessage id="sign-up" defaultMessage="sign-up"></FormattedMessage> : <FormattedMessage id="sign-in" defaultMessage="Sign In"></FormattedMessage>}
          </Typography>
          <form onSubmit={handleSubmit} className={`${classes.form} ${classes.text}`}>
            <Grid container spacing={2}>
              {error && (
                <Typography className={classes.errorText} style={{padding: "0.5rem"}} variant="h8">
                  <FormattedMessage id={error} defaultMessage={error}></FormattedMessage>
                </Typography>
              )}
              {surveyLink && isSignup && (
                    // clickable link for my survey
                    <Typography style={{textAlign: "center", width:"100%", display: "block"}}>
                      <a href={surveyLink} target="_blank" rel="noreferrer"><FormattedMessage id="survey-link" defaultMessage="Survey Link"></FormattedMessage></a>
                    </Typography>
                  ) }
              {isSignup && (
                
                <>
                  {/* <Input
                    type="text"
                    name="firstName"
                    label={<FormattedMessage id="first-name" defaultMessage="First Name"></FormattedMessage>}
                    handleChange={handleChange}
                    autoFocus
                    half
                  />
                  <Input
                    type="text"
                    name="lastName"
                    label={<FormattedMessage id="last-name" defaultMessage="Last Name"></FormattedMessage>}
                    handleChange={handleChange}
                    half
                  /> */}
                  <div className="w-100 m-2">

                  <AuthInput
                    type="text"
                    color="inherit"
                    name="userName"
                    label={<FormattedMessage id="username" defaultMessage="Username"></FormattedMessage>}
                    handleChange={handleChange}
                  />
                  </div>
                  <div className="w-100 m-2">
                    
                  <AuthInput
                    type="email"
                    color="inherit"
                    name="email"
                    label={<FormattedMessage id="email" defaultMessage="Email"></FormattedMessage>}
                    handleChange={handleChange}
                  />
                  </div>
                </>
              )}
              {!isSignup && (
                <div className="w-100 m-2">

                  <AuthInput
                    type="text"
                    name="email"
                    label={<FormattedMessage className={classes.text} id="email-or-username" defaultMessage="Email or Username"></FormattedMessage>}
                    handleChange={handleChange}
                  />
                </div>
              )}
              <div className="w-100 m-2">

              <AuthInput
                type={showPassword ? "text" : "password"}
                name="password"
                label={<FormattedMessage id="password" defaultMessage="Password"></FormattedMessage>}
                handleChange={handleChange}
                handleShowPassword={handleShowPassword}
              />
              </div>
              {isSignup && (
                <>
                <div className="w-100 m-2">

                  <AuthInput
                    type="password"
                    name="confirmPassword"
                    label={<FormattedMessage id="confirm-password" defaultMessage="Confirm Password"></FormattedMessage>}
                    handleChange={handleChange}
                    handleShowPassword={handleShowPassword}
                  />
                </div>
                <div className="w-100 m-2">
                <label htmlFor="country-select">
                  <FormattedMessage id="select-country" defaultMessage="Select Country" />
                </label>
                <Select
                  id="country-select"
                  name="region"
                  options={countryOptions}
                  onChange={handleCountryChange}
                  placeholder={<FormattedMessage id="select-country-placeholder" defaultMessage="Choose your region" />}
                  styles={{
                    option: (provided) => ({
                      ...provided,
                      display: 'flex',
                      alignItems: 'center',
                    }),
                  }}
                />
              </div>
                </>
              )}
            </Grid>
            {!isSignup && (
                          <Grid>
                          <Typography className="mt-2" style={{textAlign: "start", width:"100%", display: "block"}}>
                            <a href="/forgot-password"><FormattedMessage id="forgot-password" defaultMessage="Forgot Password"></FormattedMessage></a>
                          </Typography>
                        </Grid>
            )}
            {/* <Typography className={classes.errorText} variant="h8">
              {error ? error : ""}
            </Typography> */}

            {/* <GoogleLogin
              clientId="57984842783-uqm620pa8nsetok09aj34jpjec622933.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  className={classes.googleButton}
                  // color=""
                  fullWidth
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<Icon />}
                  variant="contained"
                >
                  Google Sign In
                </Button>
              )}
              onSuccess={googleSuccess}
              onFailure={googleFailure}
              cookiePolicy="single_host_origin"
            ></GoogleLogin> */}
            {isSignup && (
  <Grid container direction="column">
    {/* Checkbox for agreement */}
    <Box className={classes.buttonContainer}>
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={handleCheckboxChange} color="primary" />}
        label={<FormattedMessage id="I have read and agree to the following:" defaultMessage="I have read and agree to the following:" />}
      />
    </Box>
    
    {/* List of Links */}
    <ul style={{ paddingLeft: "20px" }}>
      <li>
        <a href="/acknowledgment" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="acknowledgment" defaultMessage="Acknowledgment" />
        </a>
      </li>
      <li>
        <a href="/privacy-policy" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="privacy" defaultMessage="Privacy Policy" />
        </a>
      </li>
      <li>
        <a href="/cookies-policy" target="_blank" rel="noopener noreferrer">
          <FormattedMessage id="cookies" defaultMessage="Cookies Policy" />
        </a>
      </li>
    </ul>
  </Grid>
)}

            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(value) => {setFormData({ ...formData, "recaptcha": value }); setCaptcha(true)}}>
            </ReCAPTCHA>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!captcha}
            >
              {isSignup ? 
              <FormattedMessage id="sign-up" defaultMessage="sign-up"></FormattedMessage> : 
                <FormattedMessage id="sign-in" defaultMessage="Sign In"></FormattedMessage>
            }
            </Button> 
            <Grid container justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={() => {
                  navigate(`/${isSignup ? "login" : "register"}`);
                  setIsSignup(!isSignup);
                  setError(null);
                }}
              >
                {isSignup
                  ? <FormattedMessage id="already-account" defaultMessage="Already have an account? Sign In"></FormattedMessage>
                  : <FormattedMessage id="no-account" defaultMessage="Don't have an account? Sign Up"></FormattedMessage>
                }
              </Button>
            </Grid>
          </form>
          
        </Paper>
      </Container>
    </Grow>
  );
};

export default Auth;
