
import React, { useContext, useEffect, useState } from 'react';
import { IconButton, Input, InputAdornment, Button, Tabs, Tab, useTheme } from '@material-ui/core';
import { ArrowBack as ArrowBackIcon, ArrowForward as ArrowForwardIcon, Refresh as RefreshIcon, Lock as LockIcon, Search as SearchIcon, Add as AddIcon, ContactlessOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { changeTab } from 'actions/challenge';

const BrowserTab = (props) => {

    // const [getUser, setGetUser] = useState({})

    const { user, setUser, tabs, domain, setActiveTab, activeTab, url, setUrl, selectedBrowser, page, setParams, params, setPage, undoHistory, setUndoHistory, undoHistoryPointer, setUndoHistoryPointer } = useContext(ChallengeDataContext);
    
    const [urlEdit, setUrlEdit] = useState(null);
    
    const dispatch = useDispatch();
        
    // const classes = useStyles(theme);

    const handleUrlChange = (event) => {
        setUrlEdit(event.target.value);
    };

    useEffect(() => {

        if (!url) return; // If the URL is empty, do nothing

        console.log(url, "from link")

        setUrlEdit(url)
// https://www.bestsocial.com/profile?uid=iAmAHacker' AND  1=2  UNION SELECT name, username, avatar FROM users WHERE username LIKE '%admin%' -- 

        const getParameters = async (urlString) => {
            try {
                
                console.log(urlString)

                const extractParameters = (urlString) => {
                    const parametersObject = {};
                  
                    // Extract the query string part of the URL
                    const queryString = urlString.split('?')[1];
                  
                    if (queryString) {
                      // Split the query string into key-value pairs
                      const keyValuePairs = queryString.split('&');
                  
                      // Iterate through each key-value pair
                      keyValuePairs.forEach((pair) => {
                          const [key, value] = pair.split(/=(.*)/);
                          console.log(key, value, "asdfdaffasdsf")
                        if (key && value) {
                          // Decode and store the key-value pair
                          parametersObject[key] = value.replace(/\+/g, ' ');
                        }
                      });
                    }
                  
                    return parametersObject;
                };

                const parametersObject = extractParameters(urlString);

                console.log(parametersObject, "printing url")
    
                return parametersObject;
            } catch (error) {
                console.error('Error extracting parameters:', error);
                return {}; // Return an empty object in case of an error
            }
        };


    
        const extractParams = async () => {
            const urlParams = await getParameters(url);
            console.log(urlParams, url, "printing url params")
            setParams(urlParams);
        };
    
        extractParams();

        setPageUrl();
    
    }, [url]);

    useEffect(() => {
        if (!page) return; // If the URL is empty, do nothing
        if (!undoHistory) return; // If the URL is empty, do nothing

        console.log(page, undoHistory, undoHistoryPointer, "from page");
        let newUndoHistory = undoHistory;

        if(undoHistoryPointer < undoHistory.length - 1){
            newUndoHistory = newUndoHistory.splice(undoHistoryPointer + 1, newUndoHistory.length - undoHistoryPointer - 1);
        }

        console.log(undoHistory)

        // newUndoHistory.push(page);

        // setUndoHistory(newUndoHistory);
        setUndoHistoryPointer(undoHistory.length - 1);
    }, [page])
  
    const handleEnterPress = async (event) => {
        if (event.key === 'Enter') {
            setUrl(urlEdit);
            setPageUrl();
        }
    };

    const setPageUrl = () => {

        // Execute your search function or any other logic here
        console.log(url, urlEdit, params)
        if(url.includes("https://www.bestsocial.com/profile?uid=")){
            setPage("profile");
        }
        else if(url.includes("https://www.bestsocial.com/profile/edit?uid=")){
            setPage("edit");
        }
        else if(url == "https://www.bestsocial.com" || url == "https://www.bestsocial.com/" || url == "https://www.flopify.com" || url.includes("https://www.flopify.com?")){
            setPage("menu");
        }
        else if(url.includes("bestsocial.com/users")){
            setPage("users");
            console.log("users page", "users page")
        }
        else if(url.includes("flopify.com/product?slug=")){
            setPage("product");
            console.log("product page", "product page")
        }
        else if(url.includes("flopify.com/payment?slug=")){
            setPage("payment");
            console.log("product page", "product page")
        }
        else if(url.includes("flopify.com/thankyou")){
            setPage("thankyou");
            console.log("product page", "product page")
        }
        else if(url.includes("https://www.bank.")){
            // accessing mainly the scam pages:
            // https://www.bank.change-your-password.com
            // https://www.bank.verify-your-account.com
            setPage("menu");
            console.log("bank page", "bank page")
        }
        else{
            setPage("error");
        }
        // console.log('Search executed:', url);
    }


    const handleTabChange = (event, newValue) => {
        // console.log(newValue, activeTab)
        // if (newValue === activeTab) return; // If the new tab is the same as the current tab, do nothing
        // setActiveTab(newValue);
        // You can perform additional actions when a tab is changed
    };

    const handleAddTab = () => {
    // You can implement the logic to add a new tab
    };

    const handleGoBack = () => {
        console.log(undoHistory, undoHistoryPointer, "printing undo history")
        if(undoHistory[undoHistoryPointer - 1] === undefined) return;
        setPage(undoHistory[undoHistoryPointer - 1]);
        setUndoHistoryPointer(undoHistoryPointer - 1);
    }
    const handleGoForward = () => {
        if(undoHistory[undoHistoryPointer + 1] === undefined) return;
        setPage(undoHistory[undoHistoryPointer + 1]);
        setUndoHistoryPointer(undoHistoryPointer + 1);
    }
    const handleRefresh = () => {
        window.location.reload(false);

    }
    const handleSearch = () => {
        setUrl(urlEdit);
        setPageUrl();
    }

    const handleChangeTab = (tab) => {

        if (tab === activeTab) return; // If the new tab is the same as the current tab, do nothing

        setActiveTab(tab);

        console.log(tab, "printing new value")

        dispatch(changeTab({tab: tab, browser: selectedBrowser})).then(() => {
            console.log("Browser changed step added");
        });
    }

    return (
            <>
            <div className="d-flex justify-content-space align-items-center w-100">
        {/* Back Button */}
        {/* <IconButton color="inherit" onClick={handleGoBack}>
            <ArrowBackIcon />
        </IconButton> */}

        {/* Forward Button */}
        {/* <IconButton color="inherit" onClick={handleGoForward}>
            <ArrowForwardIcon />
        </IconButton> */}

        {/* Refresh Button */}
        <IconButton color="inherit" onClick={handleRefresh}>
            <RefreshIcon />
        </IconButton>

        {/* Simulated Search Bar */}
        <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Input
                variant="outlined"
                color="inherit"
                fullWidth
                value={urlEdit}
                onChange={handleUrlChange}
                onKeyDown={handleEnterPress}
                startAdornment={
                    <InputAdornment position="start">
                        <LockIcon color="inherit" />
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <Button color="inherit" startIcon={<SearchIcon />} onClick={() => handleSearch()}>
                            Search
                        </Button>
                    </InputAdornment>
                }
            />
        </div>
    </div>
    {/* Tabs Row */}
    <div style={{ display: 'flex', alignItems: 'center' }}>
        {activeTab && <>
            <Tabs
                value={tabs.indexOf(activeTab) || 0}
                fullWidth
                onChange={handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                className='my-2 w-100' // Set the background color of the tabs row
                // sx={{ backgroundColor: 'lightgray', width: "100px" }}
            >
                {/* Add a tab for each website */}
                {tabs.map((tab) => (  // Loop through the tabs array
                    <Tab className='w-100' label={tab} key={tab} onClick={ () => handleChangeTab(tab)} />
                ))}
                {/* Add more tabs as needed */}
            </Tabs>
        </>}

            {/* Add Tab Button */}
            {/* <IconButton color="inherit" onClick={handleAddTab}>
                <AddIcon />
            </IconButton> */}
        </div>
            </>
    );
};

export default BrowserTab;