import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles(() => ({
  color: (theme) => ({color: theme.textColor}),
  menuButton: (theme) => ({
    borderRadius: "2rem",
    padding: "0px",
    display: "block",
    '&:hover': {
      backgroundColor: theme.bgHover,
      color: theme.textColorSoft,
    }
  }),
}));
