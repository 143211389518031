import { createContext, useState } from "react";

export const SolutionsContext = createContext();

export const SolutionsContextProvider = ({children}) => {

    const [solutions, setSolutions] = useState({});

    return (
        <SolutionsContext.Provider value={{solutions, setSolutions}}>
            {children}
        </SolutionsContext.Provider>
    );
};