import React, { useEffect, useState } from 'react';
import { Container, Typography, Slider, Button, MenuItem, FormControl, InputLabel, Select, List, ListItem, ListItemText, Grid, Checkbox, ListItemIcon, FormControlLabel, Radio, RadioGroup, IconButton, CircularProgress } from '@material-ui/core';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch } from 'react-redux';
import { challengesCompleted } from 'actions/challenge.js';
import { useNavigate } from 'react-router-dom';
import { fetchSurveyQuestions, submitSurvey } from 'actions/survey.js';

const CompletedSurvey = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [unansweredIssues, setUnansweredIssues] = useState([]);
    const [submitted, setSubmitted] = useState(false);

    // fetch it from the database
    const [securityIssues, setSecurityIssues] = useState([]);

    useEffect(() => {
        // check if the user has finished all challenges. If not, throw an Error 404 not fond page
        dispatch(challengesCompleted()).then(data =>{
            setIsLoading(false)
            if(data.challengesCompleted){
                console.log('success');
            }
            else{
                setError('An error happened, pleaase try again later.');
                navigate("/not-found")
            }
        }); // check if the user has completed all challenges

        // fetch survey quesitons
        dispatch(fetchSurveyQuestions()).then(data =>{
            console.log(data)
            if(data.message){
                setError(data.message);
            }
            else if(data.completed){
                navigate("/thank-you");
            }
            else if(data.initCompleted){
                navigate("/scenario-description");
            }
            else if(data.initIncomplete){
                navigate("/intro-survey"); // open completed survey
            }
            else{
                if(data.issues.categories){
                    setSecurityIssues(data.issues.categories.map((category, idCounter = 1) => ({ id: idCounter++, issue: category.issue, tags: category.tags || [], rating: null })));
                }
            }
        })

    }, []);
  
    const handleRatingChange = (id, newRating) => {
      setSecurityIssues((prevIssues) =>
        prevIssues.map((issue) =>
          issue.id === id ? { ...issue, rating: newRating } : issue
        )
      );
    };

    const handleSubmit = () => {
        const unanswered = securityIssues.filter((issue) => issue.rating === null);
        setUnansweredIssues(unanswered);
    
        if (unanswered.length > 0) {
          // Highlight unanswered issues in red
          console.error('Please answer all questions before submitting.');
        } else {
          // Handle the submission logic
          console.log('Submitted Ratings:', securityIssues);
          // You can submit the data to the server or take other actions
  
          dispatch(submitSurvey({info: securityIssues, type: "final"})).then(data =>{
            if(data.message){
              console.log('success')
              setSubmitted(true);
              setTimeout(() => {
                navigate("/thank-you")
              }, 500);
            }
            else{
              setError('An error happened, pleaase try again later.');
            }
          })
        
        }
      };
  
    return (
    <Container>
        {isLoading ? (
            <Grid container className="d-flex justify-content-center py-3">
                <Grid className='w-100 text-center'>
                        <CircularProgress />
                </Grid>
            </Grid>
        ): (
        <Grid container className=" d-flex justify-content-center py-3">
            <Grid className='w-100 text-center'>
                <Typography variant="h4" gutterBottom>
                    Completed
                </Typography>
            </Grid>
            <Grid className='w-100 text-center'>
                <Typography variant="p" gutterBottom>
                    You have completed the challenges. First of all, thank you for your time and effort. I hope you enjoyed the challenges and learnt more about web security and how vulnerabilities can affect every web application.
                </Typography>
            </Grid>
            <Grid className='w-100 text-center'>
                <Typography variant='p'>
                    Now please let me know how much you know of the following vulnerabilities that you have covered so far.
                </Typography>
            </Grid>
            {/* survey  */}
            <Grid className='w-100 text-center'>
                <List>
                    <ListItem>
                        <Grid item xs={2}>
                            <ListItemText className="text-center" primary="Security Issue" />
                        </Grid>
                        <Grid item xs={10} className='d-flex '>
                            <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Not at all" />
                            <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Not much" />
                            <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Average" />
                            <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Good" />
                            <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Very Good" />
                        </Grid>
                    </ListItem>
                    {securityIssues.map((issue) => (
                    <ListItem key={issue.id}>
                        <Grid item xs={2} className="d-flex align-items-center">
                            {issue.rating !== null ? (
                                <IconButton
                                color="primary"
                                edge="start"
                                disabled
                                aria-label="answered"
                                >
                                <CheckCircleIcon />
                                </IconButton>
                            ) : null}
                            <ListItemText primary={issue.issue} />
                        </Grid>
                        <Grid item xs={10}>

                        <RadioGroup
                            row
                            aria-label={`rating-${issue.id}`}
                            name={`rating-${issue.id}`}
                            value={issue.rating !== null ? String(issue.rating) : ''}
                            className='w-100 d-flex justify-content-between'
                            onChange={(event) => handleRatingChange(issue.id, parseInt(event.target.value, 10))}
                        >
                            {[1, 2, 3, 4, 5].map((rating) => (
                            <FormControlLabel
                                key={rating}
                                value={String(rating)}
                                control={<Radio color="primary" />}
                                label=""
                                className='m-0 d-flex justify-content-center'
                                style={{ flex: '1 1 20%' }}
                            />
                            ))}
                        </RadioGroup>
                        {submitted && issue.rating !== null && (
                            <Checkbox
                            checked
                            color="primary"
                            inputProps={{ 'aria-label': 'checked' }}
                            />
                        )}
                        </Grid>
                    </ListItem>
                    ))}
                </List>
            </Grid>
            <Grid className='w-100 text-center'>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                Submit
                </Button>
                {error && <Typography class="w-100 text-center mt-3" color="error">{error}</Typography>}
                {submitted && <Typography class="w-100 text-center mt-3" color="success">{"Survey Submitted"}</Typography>}
            </Grid>
        </Grid>
        )}
      </Container>
  );
};

export default CompletedSurvey;
