import React from 'react';
import { Container, Button,Typography} from "@material-ui/core";

// import { useTheme } from "@material-ui/core";
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { useNavigate } from 'react-router-dom';
import videoBg from "../../assets/Digital_World.mov";
import { FormattedMessage } from 'react-intl';

const Home = () => { 

  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();
  
    return (
      <div className="text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center">
        <video src={videoBg} className={classes.video} autoPlay loop muted>
          {/* <source src={videoBg} type="video/quicktime" /> */}
          Your browser does not support the video tag.
        </video>
        <div className={classes.overlay}></div>
        <Container  className={classes.content}>
        <Typography variant="h5" className={classes.text}><FormattedMessage id="intro-1" defaultMessage="HackMeDown is created for curious individuals who want to learn about the most common Web Vulnerabilities, how they work and how to overcome them."></FormattedMessage></Typography>
        <Typography variant="h5" className={classes.text}><FormattedMessage id="intro-2" defaultMessage="HackMeDown will present you with 10 challenges of various levels of difficulty and your goal is solve them based on the security vulnerability given. HackMeDown will learn from your experience and present you challenges matching closely to your skillset per vulnerability topic."></FormattedMessage></Typography>
        <Button variant="contained" color="primary" onClick={() => navigate("/login")}>
          <FormattedMessage id="start-btn" defaultMessage="Get Started"></FormattedMessage>
        </Button>
      </Container>
    </div>
    );
  };
  

export default Home;
