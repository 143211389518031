import React, { useContext, useEffect, useState } from 'react';
import { Container, Button,Typography, CssBaseline, Paper, Grid, CircularProgress} from "@material-ui/core";

// import { useTheme } from "@material-ui/core";
import { useTheme } from "styled-components";
// import { useTheme } from '@mui/material/styles';
import useStyles from "./styles.js";
import { useNavigate, useParams } from 'react-router-dom';
import { SolutionsContext, SolutionsContextProvider } from '../../contexts/SolutionsContext.js';
import { useDispatch, useSelector } from 'react-redux';
import { fetchChallenge, getChallengeAnswers, getChallengeStatus, scenarioAuth, setChallenge } from '../../actions/challenge.js';
import ScenarioInstructionsModal from '../Modal/ScenarioInstructionsModal.js';
import ScenarioProgress from '../Modal/ScenarioProgress.js';
import ScenarioGuidance from '../Modal/ScenarioGuidance.js';
import { ModalContext } from '../../contexts/ModalContext.js';
import ChallengeCompletedModal from '../Modal/challengeCompleted.js';
import SocialProfileScenario from './Scenarios/SocialProfileScenario/SocialProfileScenario.js';
import BankScenario from './Scenarios/BankScenario/BankScenario.js';
import AttackerScenario from './Scenarios/AttackerScenario/AttackerScenario.js';
import { BankAuthContextProvider } from '../../actions/challenge.js';
import { ChallengeDataContext, ChallengeDataProvider } from '../../contexts/ChallengeData.js';
import BrowserTab from './BrowserTab/BrowserTab.js';
import ResetLevelButton from '../Button/ResetLevelButton/ResetLevelButton.js';
import OpenModal from '../Button/ResetLevelButton/OpenModal.js';
import OpenArticle from 'Components/Modal/OpenArticle.js';
import UnfinishedChallenge from 'Components/Modal/UnfinishedChallenge.js';
import ChallengeSnippets from 'Components/Modal/challengeSnippets.js';
import PacketSnifferScenario from './Scenarios/PacketSnifferScenario/PacketSnifferScenario.js';
import BrowserSwitch from './Browser/Browser.js';
import Solution from 'Components/Modal/Solution.js';
import Hint from 'Components/Modal/Hint.js';
import Skip from 'Components/Modal/Skip.js';
import EmailsScenario from './Scenarios/EmailsScenario /EmailsScenario.js';
import PhishingScenario from './Scenarios/PhishingScenario/PhishingScenario.js';
import ECommerceScenario from './Scenarios/ECommerceScenario/ECommerceScenario.js';
import ChallengeLevel from './ChallengeLevel/ChallengeLevel.js';
import { syncStates } from 'actions/helper.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs,
  getRestart
} from '../../selectors/browser.js';
import clearCookies from 'helper.js';
import NotWorking from 'Components/Modal/NotWorking.js';
import { FormattedMessage } from 'react-intl';

const Challenge = () => { 

  const {isOpen, setIsOpen, setText, text} = useContext(ModalContext);
  const {solutions, setSolutions} = useContext(SolutionsContext);

  const [challengeInfo, setChallengeInfo] = useState({});
  const [challengeStatus, setChallengeStatus] = useState({});
  const [currentStepNumber, setCurrentStepNumber] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [challengeGuidance, setChallengeGuidance] = useState([]);
  const [stepNumber, setStepNumber] = useState(0);
  const { challenge } = useParams();

  const [states, setStates] = useState({});

  const theme = useTheme();
  const classes = useStyles(theme);
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const user = useSelector(getUser);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const restart = useSelector(getRestart);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);
 
  let challengeSelector = useSelector(state => state.challengeProgress);


  // when the user moves on to the next challenge, this useEffect is triggered
  useEffect(() => {
    
    if (!challengeSelector.data) return;

    // console.log(console.log(challengeSelector.data), "adlfjhajlkdjk fhaljkfadsfahlfjkdsfahfjkads fhajksfh lf")

    if(!challengeSelector.error){
      setChallengeStatus(challengeSelector.data);
      if(Object.keys(challengeSelector.data).length > 0 && typeof challengeSelector.data !== "string")
      {

        let lastIncompleteStep = challengeSelector.data.findIndex(step => step.isComplete === 0);
        setIsLastStep(lastIncompleteStep === -1 ? true : false)
        setCurrentStepNumber(lastIncompleteStep === -1 ? challengeSelector.data.length : lastIncompleteStep);
        
    
        if(Object.keys(challengeSelector.data).length > 0 && Object.keys(challengeSelector.data).every(step => challengeSelector.data[step].isComplete === 1)){
            if(challengeGuidance.length > 0){
              setIsOpen("guidance")
            }
            else{
              setIsOpen("finish");
              
              clearCookies();
              

              // clear cookies
              // clear all cookies which are set by {browser}_{activeTab} and session_id

              
            }
        }
      }
    }
  },[challengeSelector, challengeSelector.data]);

  useEffect(() => {

    // const fetchChallengeStatus = async (other) => {
    //   const data = await dispatch(getChallengeStatus());

    //   if (data) {
    //     // returns only the stats of the current challenge
    //     setChallengeInfo(prev => ({ ...other, ...data }));

    //     if (Object.keys(data.challenge).every(step => data.challenge[step].isComplete === 1)) {
    //       navigate("/scenario-description");
    //     }
    //   } else {
    //     setChallengeInfo(prev => ({ ...other }));
    //     navigate("/scenario-description");
    //   }
    // }

    // restarting the cahllenge making the state to rerender

    if(restart){
      dispatch({ type: 'UPDATE_RESTART', payload: false });
    }

    
  }, [restart, dispatch]);

  useEffect(() => {
    console.log(theme.palette.mode, "outside iframe");

    // send the theme to the iframe

    var iframe = document.querySelector('iframe');
    if(iframe){
      iframe.contentWindow.postMessage({theme: theme.palette.mode}, '*');
    }

  }, [theme]);

  useEffect(() => {

    if(!challengeGuidance) return;
    if(!challengeStatus) return;

    if(challengeGuidance.length === 0) return;

        setStepNumber(currentStepNumber);

        if(isOpen == null){
          setIsOpen("guidance")
        }

  }, [challengeGuidance, challengeStatus, currentStepNumber]);

  const handleBrowserChange = (browser) => {
    // setSelectedBrowser(browser);

    // console.log(browser)
    
    // const expirationDate = new Date();
    // expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
    // document.cookie = `browser=${browser}; expires=${expirationDate.toUTCString()}; path=/;`;
    
    // navigate(`?browser=${browser}`);
  };


  const fetchData = async () => {
    // try {
      dispatch(getChallengeAnswers()).then(result => {
        setSolutions(result)
      });


      const data = await dispatch(fetchChallenge(challenge));


      if(data.completed){
        navigate(`/completed`);
        return;
      }

      if(!data) return;


      setChallengeInfo(data);
      setChallengeStatus(data.steps)
      // console.log("UPDATE_BROWSER_AND_TABS", data.scenarios)
      dispatch({ type: 'UPDATE_BROWSER_AND_TABS', payload: data.scenarios });
      setChallengeGuidance(data.guidance);

      
      // check if cookie already exists s(user probably refreshes the browser)
      // if not, create a new cookie
      let browserList = Object.keys(data.scenarios);
      let browser = null;
      dispatch({ type: 'UPDATE_BROWSERS', payload:browserList });
        if(document.cookie.includes('browser=') && !browserList.includes(selectedBrowser)){
          browser = document.cookie.split(';').find(cookie => cookie.includes('browser=')).split('=')[1];
        }
        else{
          // document.cookie = `browser=${Object.keys(data.scenarios)[0]}; path=/;`;
          browser = Object.keys(data.scenarios)[0];
        }
        dispatch({ type: 'UPDATE_SELECTED_BROWSER', payload:browser });
        navigate(`?browser=${browser}`);

      dispatch({ type: 'UPDATE_TABS', payload: data.scenarios[Object.keys(data.scenarios)[0]] });
      dispatch({ type: 'UPDATE_ACTIVE_TAB', payload: data.scenarios[browser][0] });

      if(data.activeChallenge !== data.seed && data.activeChallenge !== null){
        setIsOpen("activeProgress");
      }

      // const status = await dispatch(getChallengeStatus({challengeSeed: challenge}));

      // console.log(status)

      
      if(data.steps && Object.keys(data.steps) > 0)
      {
        if (Object.keys(data.steps).every(step => data.steps[step].isComplete === 1)) 
        {
          navigate(`/challenge/${data.seed}/description`);
        }
        // setChallengeStatus(data.steps);
      }


      // if (challengeSelector) {
      //   setTabs(challengeSelector.scenarios);
      //   fetchChallengeStatus(challengeSelector);
      // } else {
        // const setChallengeResult = await dispatch(setChallenge());
        // fetchChallengeStatus(setChallengeResult);
      // }
    // } catch (error) {
    //   console.error("Error fetching data:", error);
    // }
  };

  useEffect(() => {

    var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
    var eventer = window[eventMethod];
    var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    eventer(messageEvent,function(e) {
        var key = e.message ? "message" : "data";
        var data = e[key];

        console.log(data["selectedBrowser"], data["activeTab"], "data update data")

        if(data["activeTab"] && data["selectedBrowser"]){
          // setStates(data);
          // data is an object where it has key corresponding the type of the data transferred. Below, have a function which will update the corresponding states. For example
          // {session: {...}, url: {...}, ...} should update the setSession and setUrl states respectively
  
          if(data["activeTab"]){
            dispatch({ type: 'UPDATE_ACTIVE_TAB', payload: data["activeTab"] });
          }
          if(data["selectedBrowser"]){
            dispatch({ type: 'UPDATE_SELECTED_BROWSER', payload: data["selectedBrowser"] });
          }
        }
        else if(data["state"]){ // syncing redux states
          // remove UPDATE_SELECTED_BROWSER and UPDATE_ACTIVE_TAB
          if(data["state"].type !== "UPDATE_SELECTED_BROWSER"){
            console.log(data["state"], "sync state")
            dispatch(syncStates(data["state"])).then(data => {
              // console.log("updated state in browser", data)
            });
          }
        } 
        
        //run function//
    },false);

      fetchData();
  }, []);

  // useEffect(() => {

  //   console.log(states, "all states")
    
  //   if(Object.keys(states).length == 0) return;
  //   if(activeTab == null) return;
  //   if(selectedBrowser == null) return;

  //   for (const [key, value] of Object.entries(states)) {
  //     if(value != null && value != undefined && value != "undefined" && value != "null" && value.length > 0){
  //       console.log(key, value)
  //       if(key == "session"){
  //         dispatch({ type: 'UPDATE_SESSION', payload: value });
  //       }
  //       if(key == "url"){
  //         // change the url after 3 seconds
  //           console.log("CHANGE URL!!!!!!!!", `https://www.${value}`);
  //           dispatch({ type: 'UPDATE_URL', payload: value });
  //       }
  //       if(key == "selectedBrowser"){
  //         dispatch({ type: 'UPDATE_SELECTED_BROWSER', payload: value });
  //       }
  //       if(key == "scenario"){
  //         dispatch({ type: 'UDPATE_TABS', payload: value });
  //       }
  //       if(key == "page"){
  //         dispatch({ type: 'UPDATE_PAGE', payload: value });
  //       }
  //       if(key == "restart"){
  //         dispatch({ type: 'UPDATE_RESTART', payload: value });
  //       }
  //       if(key == "scriptValid"){
  //         dispatch({ type: 'UPDATE_SCRIPT_VALID', payload: value });
  //       }
  //       if(key == "domain"){
  //         dispatch({ type: 'UPDATE_DOMAIN', payload: value });
  //       }
  //     }

  //   }
  // }, [activeTab, selectedBrowser, states]);
  

    return (

      <>
        <Container className={`text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center ${classes.bg}`}>
        <UnfinishedChallenge activeChallenge={challengeInfo.activeChallenge} currentChallenge={challenge} isOpen={isOpen}></UnfinishedChallenge>
          <ScenarioInstructionsModal content={challengeInfo  && challengeInfo.description} title={challengeInfo.title} isOpen={isOpen}></ScenarioInstructionsModal>
          <ChallengeCompletedModal isOpen={isOpen} challenge={challengeInfo.activeChallenge}></ChallengeCompletedModal> 
          <ScenarioProgress  content={challengeStatus} isOpen={isOpen} setStepNumber={setStepNumber}></ScenarioProgress>
          <ScenarioGuidance  guidance={challengeGuidance} isOpen={isOpen} stepNumber={stepNumber} isLastStep={isLastStep} category={challengeInfo.article && challengeInfo.article.category} slug={challengeInfo.article && challengeInfo.article.slug}></ScenarioGuidance>
          <Solution isOpen={isOpen}></Solution>
          <Hint isOpen={isOpen}></Hint>
          <Skip isOpen={isOpen}></Skip>
          <ChallengeSnippets content={challengeInfo.snippets} isOpen={isOpen}></ChallengeSnippets>
          <NotWorking isOpen={isOpen}></NotWorking>
          <OpenArticle  category={challengeInfo.article && challengeInfo.article.category} slug={challengeInfo.article && challengeInfo.article.slug} isOpen={isOpen}></OpenArticle>
            {/* <Typography variant="h5" className={`${classes.text} m-3`}>{challengeInfo.title}</Typography> */}

          <Grid container direction="column" style={{ height: '100%' }}>

            <Grid item>
              <ResetLevelButton></ResetLevelButton>
              <OpenModal modal={"instructions"} title={<FormattedMessage id="Description" defaultMessage="Description" />}></OpenModal>
              <OpenModal modal={"progress"} title={<FormattedMessage id="Progress" defaultMessage="Progress" />}></OpenModal>
              <OpenModal modal={"guidance"} title={<FormattedMessage id="Guidance" defaultMessage="Guidance" />}></OpenModal>
              <OpenModal modal={"article"} title={<FormattedMessage id="Article" defaultMessage="Article" />}></OpenModal>
              <OpenModal modal={"hint"} title={<FormattedMessage id="Hint" defaultMessage="Hint" />}></OpenModal>
              <OpenModal modal={"solution"} title={<FormattedMessage id="Solution" defaultMessage="Solution" />}></OpenModal>
              <OpenModal modal={"skip"} title={<FormattedMessage id="Skip Chalenge" defaultMessage="Skip Chalenge" />}></OpenModal>
              {challengeInfo.snippets && (<OpenModal modal={"snippets"} title={<FormattedMessage id="Snippets" defaultMessage="Snippets" />}></OpenModal>)}
              <OpenModal modal={"report"} title={<FormattedMessage id="Not Working?" defaultMessage="Not Working?" />}></OpenModal>
            </Grid>

            <Grid item>
              <BrowserSwitch handleBrowserChange={handleBrowserChange}></BrowserSwitch>
            </Grid>
            <Grid item className={`${classes.bg}`} style={{flexGrow: 1}}>

              <Paper className={`${classes.loginContainer} ${classes.bgSoft} overflow-auto`} elevation={3} style={{ padding: '20px' }}>
                <BrowserTab></BrowserTab>
                <CssBaseline />

                <BankAuthContextProvider>
                  <iframe 
                    src={`${window.location.origin+window.location.pathname}/frame?active=${activeTab}&browser=${selectedBrowser}`} 
                    title="Challenge" 
                    className="w-100 challenge-iframe" 
                    height="400px"
                    style={{border: "none"}}>
                  </iframe>
                </BankAuthContextProvider>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  };
  

export default Challenge;
