import { GET_ECOMMERCE_AUTH_USER, GET_PRODUCT, GET_PRODUCTS, GET_PRODUCTS_ERROR, GET_PRODUCT_ERROR, UPDATE_CHALLENGE_STATUS } from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import BankAuthReducer from "../reducers/bank.js";
import { createContext, useEffect, useReducer } from "react";

export const getProducts = (query) => async (dispatch) => {
    try{
        const { data } = await api.getProducts(query);
        dispatch({ type: GET_PRODUCTS, payload: data.products });
        dispatch({ type: GET_ECOMMERCE_AUTH_USER, payload: data.authUser });

        return data;

    }
    catch(error){
        dispatch({ type: GET_PRODUCTS_ERROR, payload: error.message });
    }
}

export const getProduct = ({slug}) => async (dispatch) => {
    try{
        const { data } = await api.getProduct(slug);
        dispatch({ type: GET_PRODUCT, payload: data });

        return data;
    }
    catch(error){
        dispatch({ type: GET_PRODUCT_ERROR, payload: error.message });
    }
}

export const openPayment = (info) => async (dispatch) => {
    try{
        const { data } = await api.openPayment(info);
        if(data.step)
        {
          dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step});
        }

        return data;
    }
    catch(error){
        dispatch({ type: GET_PRODUCT_ERROR, payload: error.message });
    }
}

export const paymentSubmit = (info) => async (dispatch) => {
    try{
        const { data } = await api.paymentSubmit(info);
        if(data.step)
        {
          dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step});
        }

        return data;
    }
    catch(error){
        dispatch({ type: GET_PRODUCT_ERROR, payload: error.message });
    }
}

