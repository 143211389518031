import { GET_CHALLENGE_ANSWERS, SET_CHALLENGE_ANSWERS } from "../constants/actionTypes.js";

export default (state = {}, action) => {
    switch (action.type) {
        case GET_CHALLENGE_ANSWERS:
            return action?.payload;
        case SET_CHALLENGE_ANSWERS:
            return action?.payload;
        default:
            return state;
    }
};
