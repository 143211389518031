import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';

const ChallengeCompletedModal = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);

  const {content, challenge} = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();

  const handleCloseModal = () => {
    navigate(`/challenge/${challenge}/post-questionnaire`);
    setIsOpen(null);
  };

//   useEffect(() => {
//     console.log(isOpen)
//   },[isOpen])

  return (
    <div>
        <Modal
            open={isOpen === "finish"}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}>Challenge Complete</h2>
                <Box className={classes.textContent}>
                    <Typography variant="p" className={classes.text}>
                        You have completed the challenge. Click on the <Box fontWeight='fontWeightMedium' display='inline'>Open Questionnaire</Box> button below when you're ready to open the questionnaire.
                    </Typography>
                </Box>
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    Open Questionnaire
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default ChallengeCompletedModal;