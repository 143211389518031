import { Button } from "@material-ui/core";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import useStyles from "../Scenarios/BankScenario/Dashboard/Button/styles.js";
import { BankAuthContext, endSession } from "../../../actions/challenge.js";
import { ChallengeDataContext } from "contexts/ChallengeData.js";
import bank from "reducers/bank.js";
import { Cookies } from "react-cookie";

const cookies = new Cookies();


// export const logoutHandler = (dispatch,resetStates, activeTab) => {

//     console.log(activeTab)
//     dispatch(endSession(activeTab)).then(() => {
//         // removeCookie(activeTab);
//         cookies.remove(activeTab);
//         console.log(cookies)
//         resetStates(activeTab);
//     });
// };

export const logoutHandler = (dispatch, resetStates, activeTab) => {
    
    dispatch(endSession(activeTab)).then(() => {
        // Set cookie expiration to a past date
        document.cookie = `${activeTab}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        const cookies = document.cookie.split("; ");
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i];
            if (cookie.startsWith("session_id=")) {
                const cookieParts = cookie.split("=");
                const cookieName = cookieParts[0];
                document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
            }
        }

        

        resetStates(activeTab);
    });
};
export const BankLogOut = () => {

    const theme = useTheme();
    const classes = useStyles(theme);
    // const {user} = useContext(BankAuthContext)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { resetStates, session, activeTab } = useContext(ChallengeDataContext);

    const logout = () => {
        // if(session)
        // {
            logoutHandler(dispatch,resetStates, activeTab);
        // }
    };

    return (
        <Button
            variant="contained"
            className="position-absolute top-0 end-0"
            onClick={() => logout()}
            color="secondary"
        >
            {" "}
            Logout
        </Button>
    );
}