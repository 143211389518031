import React, { useContext, useEffect } from 'react';
import { Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';

import { useTheme } from "styled-components";
import useStyles from "../styles.js";
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs
  } from 'selectors/browser';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

const Menu = () => {

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    const theme = useTheme();
    const classes = useStyles(theme);

    const dispatch = useDispatch();

    const handleButtonClick = (val) => {
        console.log(val, session);
        dispatch({type: "UPDATE_URL", payload:"https://www.bank.com/"+val});
    }

    return (
        <Container maxWidth="lg" className='d-flex h-100 flex-column align-items-center justify-content-center'>
            <Typography className={classes.text} variant="h4" align="center" style={{ margin: '20px' }}>
                 
                <FormattedMessage id="Welcome to Your Online Banking Dashboard" defaultMessage="Welcome to Your Online Banking Dashboard" ></FormattedMessage>
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={4}>
                    <Card>
                        <CardContent className={classes.bg}>
                            <Button variant="contained" fullWidth color="primary" onClick={() => handleButtonClick("savings")}>
                                <FormattedMessage id="Savings Account" defaultMessage="Savings Account" ></FormattedMessage>
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent className={classes.bg}>
                        <Button variant="contained" fullWidth color="primary" onClick={() => handleButtonClick("transaction")}>
                            <FormattedMessage id="Transfer Money" defaultMessage="Transfer Money" ></FormattedMessage>
                        </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent className={classes.bg}>
                        <Button variant="contained" fullWidth color="primary" onClick={() => handleButtonClick("transactions")}>
                            <FormattedMessage id="Transactions History" defaultMessage="Transactions History" ></FormattedMessage>
                        </Button>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Add more buttons/cards here */}
            </Grid>
        </Container>
    );
};

export default Menu;