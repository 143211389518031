import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Card, CardContent, Grid } from '@mui/material';
import { Button } from '@material-ui/core';
import { useTheme } from 'styled-components';

import useStyles from "../styles.js";
import { useDispatch, useSelector } from 'react-redux';
import { BankAuthContext, getBalance } from '../../../../../../actions/bankScenario.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const Savings = (props) => {
  // Replace this with actual data
  const interestRate = 0.03; // Annual interest rate

  const { page, setPage,session,selectedBrowser } = useContext(ChallengeDataContext);

  const [accountInfo, setAccountInfo] = useState({})

  const dispatch = useDispatch();

  useEffect(() => {

    if (!session.user) return;

    if(session.user)
    {
      console.log(session)
      dispatch(getBalance({username: session.user.username, browser: selectedBrowser})).then(data => {
        console.log(data)
        if(data){
          setAccountInfo(data.user);
        }
      })
    }
  }, [session])

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
    <Container maxWidth="md"  className="d-flex justify-content-center h-100 flex-column align-items-center">
        <div  className={classes.text}>

        <Typography variant="h4" align="center" style={{ margin: '20px' }}>
            Savings Account
        </Typography>
            <Typography variant="h6">Username: {accountInfo.username}</Typography>
            <Typography variant="h6">Account Number: {accountInfo.accountNumber && accountInfo.accountNumber}</Typography>
            <Typography variant="h6">Sort Code: {accountInfo.accountNumber && accountInfo.sortCode}</Typography>
            <Typography variant="subtitle1">Last Transaction: {new Date(accountInfo.lastTransaction).toLocaleString()}</Typography>
            <Typography variant="h5" style={{ marginTop: '20px' }}>
                Total Savings: £{Number(accountInfo.balance) && Number(accountInfo.balance).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            </Typography>
            <Typography variant="subtitle1">
                Interest Rate: {interestRate * 100}% (Annual)
            </Typography>
        <Button variant="contained" className="m-3" color="primary" onClick={() => setPage("menu")}>
                Back
            </Button>
        </div>
      {/* Additional relevant information can be displayed here */}
    </Container>
  );
};

export default Savings;