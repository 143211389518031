// src/LoginPage.js
import React, { useContext, useDebugValue, useEffect, useState } from 'react';
import { Container, TextField, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import {BankAuthContext, getTransfers, transfer} from "../../../../../actions/bankScenario.js";
 
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import Menu from './Menu/Menu.js';
import Savings from './Savings/Savings.js';
import Transaction from './Transaction/Transaction.js';
import Transactions from './Transactions/Transactions.js';
import {BankLogOut} from '../../../Common/BankLogOut.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import { bankScenarioAuth } from 'actions/challenge.js';

const UserProfile = () => { 

    const [transferAmount, setTransferAmount] = useState(null);
    const [transferTo, setTransferTo] = useState(null);
    const [error, setError] = useState(null);
    const [loginLoading, setLoginLoading] = useState();

    const { page, setPage, activeTab, params, url, setSession } = useContext(ChallengeDataContext);

    const dispatch = useDispatch();

    useEffect(() => {

      if(!page)
      {
        setPage("menu");
      }

      console.log(activeTab, "dashboard")

    }, [activeTab, setPage]);


    const theme = useTheme();
    const classes = useStyles(theme);

    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     try {
    //     // Perform AJAX request here


    //     dispatch(transfer({transferAmount,transferTo,fromId: bankUser.id})).then(data=>{
    //         console.log(data)
    //         if (data) {
    //             console.log('Data submitted successfully');
    //         } else {
    //             console.error('Error submitting data');
    //         }
    //     });

    //     // Handle response
    //     } catch (error) {
    //         console.error('AJAX error:', error);
    //     }
    // };

    return (
    <Container maxWidth="lg" className='h-100 position-relative'>
        <BankLogOut></BankLogOut>
        {page == "menu" ? <Menu ></Menu> : <></>}
        {page == "savings" ? <Savings ></Savings> : <></>}
        {page == "transaction" ? <Transaction ></Transaction> : <></>}
        {page == "transactions" ? <Transactions ></Transactions> : <></>}
      </Container>
    );
};

export default UserProfile;