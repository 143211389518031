export const GET_CHALLENGE_STATUS = "GET_CHALLENGE_STATUS";
export const SET_CHALLENGE_STATUS = "SET_CHALLENGE_STATUS";
export const UPDATE_CHALLENGE_STATUS = "UPDATE_CHALLENGE_STATUS";

export const GET_CHALLENGE_ANSWERS = "GET_CHALLENGE_ANSWERS";
export const SET_CHALLENGE_ANSWERS = "SET_CHALLENGE_ANSWERS";

export const GET_CHALLENGE = "GET_CHALLENGE";
export const FETCH_CHALLENGE = "GET_CHALLENGE";
export const FETCH_CHALLENGE_ERROR = "GET_CHALLENGE";
export const SET_CHALLENGE = "SET_CHALLENGE";

export const GET_BALANCE = "GET_BALANCE";

export const BANK_SUBMIT = "BANK_SUBMIT";
export const BANK_SUBMIT_ERROR = "BANK_SUBMIT_ERROR";

export const BANK_TRANSACTION = "BANK_TRANSACTION";
export const BANK_TRANSACTIONS = "BANK_TRANSACTIONS";
export const CREATE_BANK_TRANSACTION = "CREATE_BANK_TRANSACTION";

export const BANK_TRANSFER = "BANK_TRANSFER";
export const BANK_TRANSFER_ERROR = "BANK_TRANSFER_ERROR";
export const RESET_LEVEL = "RESET_LEVEL";

// SOCIAL
export const FETCH_SOCIAL_USERS = "FETCH_SOCIAL_USERS";
export const FETCH_SOCIAL_USER = "FETCH_SOCIAL_USER";
export const FETCH_SOCIAL_USER_SETTINGS = "FETCH_SOCIAL_USER_SETTINGS";
export const FETCH_SOCIAL_POSTS = "FETCH_SOCIAL_POSTS";
export const FETCH_SOCIAL_POSTS_ERROR = "FETCH_SOCIAL_POSTS_ERROR";
export const FETCH_SOCIAL_USER_ERROR = "FETCH_SOCIAL_USER_ERROR";
export const CREATE_SOCIAL_POST = "CREATE_SOCIAL_POST";
export const CREATE_SOCIAL_POST_ERROR = "CREATE_SOCIAL_POST_ERROR";
export const FETCH_SOCIAL_USERS_ERROR = "FETCH_SOCIAL_USERS_ERROR";
export const FETCH_SOCIAL_USERS_SETTINGS_ERROR = "FETCH_SOCIAL_USERS_SETTINGS_ERROR";
export const UPDATE_SOCIAL_USERS = "UPDATE_SOCIAL_USERS";
export const UPDATE_SOCIAL_USERS_ERROR = "UPDATE_SOCIAL_USERS_ERROR";

export const AUTH = "AUTH";
export const AUTH_ERROR = "AUTH_ERROR";
export const NOT_AUTH = "NOT_AUTH";
export const CHALLENGE_LOGOUT = "CHALLENGE_LOGOUT";
export const LOGOUT = "LOGOUT";

// blog

export const GET_ARTICLES = "GET_ARTICLES";
export const GET_ARTICLES_ERROR = "GET_ARTICLES_ERROR";
export const GET_ARTICLE = "GET_ARTICLE";
export const GET_ARTICLE_ERROR = "GET_ARTICLE_ERROR";
export const GET_ARTICLES_BY_CATEGORY = "GET_ARTICLES_BY_CATEGORY";
export const GET_ARTICLES_BY_CATEGORY_ERROR = "GET_ARTICLES_BY_CATEGORY_ERROR";

// emails
export const GET_EMAILS = "GET_EMAILS";
export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_ERROR = "SEND_EMAIL_ERROR";
export const GET_EMAILS_ERROR  = "GET_EMAILS_ERROR";

// attacker
export const GET_STOLEN_COOKIES = "GET_STOLEN_COOKIES";
export const GET_STOLEN_COOKIES_ERROR = "GET_STOLEN_COOKIES_ERROR";
// packet Sniffer

export const GET_STOLEN_PACKETS = "GET_STOLEN_PACKETS";
export const GET_STOLEN_PACKETS_ERROR = "GET_STOLEN_PACKETS_ERROR";

// phishing
export const GET_STOLEN_CREDENTIALS = "GET_STOLEN_CREDENTIALS";
export const GET_STOLEN_CREDENTIALS_ERROR = "GET_STOLEN_CREDENTIALS_ERROR";

// ecommerce

export const GET_PRODUCTS = "GET_PRODUCTS";
export const GET_PRODUCTS_ERROR = "GET_PRODUCTS_ERROR";
export const GET_PRODUCT = "GET_PRODUCT";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR";
export const GET_ECOMMERCE_AUTH_USER = "GET_ECOMMERCE_AUTH_USER";

// questionnaire

export const GET_QUESTIONS = "GET_QUESTIONS";
export const GET_QUESTIONS_ERROR = "GET_QUESTIONS_ERROR";
export const SUBMIT_QUESTION_ANSWER = "SUBMIT_QUESTION_ANSWER";
export const SUBMIT_QUESTION_ANSWER_ERROR = "SUBMIT_QUESTION_ANSWER_ERROR";
export const GET_QUESTION_ANSWERS = "GET_QUESTION_ANSWERS";
export const GET_QUESTION_ANSWERS_ERROR = "GET_QUESTION_ANSWERS_ERROR";
export const GET_ANSWERED_QUESTIONS = "GET_ANSWERED_QUESTIONS";
export const GET_ANSWERED_QUESTIONS_ERROR = "GET_ANSWERED_QUESTIONS_ERROR";
