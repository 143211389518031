import React, { useState, useEffect, Profiler, useContext, useRef } from "react";
import { Container} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import { DarkMode } from "./contexts/DarkMode.js";
import { ThemeProvider } from 'styled-components';
import { dark, light } from "./themes.js";
import "bootstrap/dist/css/bootstrap.min.css";

import useStyles from "./styles.js";

import { 
  // BrowserRouter, 
  // Routes, 
  // Route, 
  // useRoute, 
  // Switch,
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
 } from "react-router-dom";

import './App.css';
import Home from "./Components/Home/Home.js";
import Auth from "./Components/Auth/Auth.js"; 
import { Navbar } from "./Components/Navbar/Navbar.js";
import { SidebarMenu } from "./Components/SidebarMenu/SidebarMenu.js";
import Instructions from "./Components/Instructions/Instructions.js";
import Challenge from "./Components/Challenge/Challenge.js";
import Description from "./Components/Challenge/Description/Description.js";
import Footer from "./Components/Footer/Footer.js";
import { ModalContextProvider } from "./contexts/ModalContext.js";
import { SideMenuContextProvider } from "./contexts/SideMenuContext.js";
import About from "./Components/About/About.js";
import { SolutionsContextProvider } from "./contexts/SolutionsContext.js";
import { ChallengeDataProvider } from "contexts/ChallengeData.js";
import Blog from "Components/Blog/Blog.js";
import BlogPosts from "Components/Blog/Posts/BlogPosts.js";
import BlogPost from "Components/Blog/Posts/Post/BlogPost.js";
import ExperienceQuiz from "Components/ExperienceQuiz/ExperienceQuiz.js";
import CompletedSurvey from "Components/CompletedSurvey/CompletedSurvey.js";
import NotFoundPage from "Components/Error/NotFoundPage.js";
import ThankYouPage from "Components/CompletedSurvey/ThankYou.js";
import { IntlProvider } from 'react-intl';
import translations from './translations.json'; // import your  translation files
import LanguageSelector from "Components/LanguageSelector/LanguageSelector.js";
import QuestionnairePage from "Components/Questionnaire/Questionnaire.js";

function App() {
  const [isDarkMode, setIsDarkMode] = useState(false);
  
  const theme = isDarkMode ? dark : light;

  const user = useSelector((state) => state.auth);

  const classes = useStyles(theme);

  const [locale, setLocale] = useState(''); // state for current locale
  const [messages, setMessages] = useState(translations[locale]); // state for current locale messages

  useEffect(() => {
    // check if locale cookie exists
    const cookieValue = document.cookie.split('; ').find(row => row.startsWith('locale='));

    if (cookieValue) {
      const localeCookie = cookieValue.split('=')[1];
      if(locale !== localeCookie && locale !== ''){
        document.cookie = `locale=${locale}; max-age=31536000; path=/; ${process.env.REACT_APP_DOMAIN.includes("localhost") ? '' : process.env.REACT_APP_DOMAIN}`;
        setLocale(locale);
        setMessages(translations[locale]);
      }
      else{
        setLocale(localeCookie);
        setMessages(translations[locale]);
      }
    }
    else{
      // if no cookie exists, set the default locale to English
      document.cookie = `locale=en; max-age=31536000; path=/;  ${process.env.REACT_APP_DOMAIN.includes("localhost") ? '' : process.env.REACT_APP_DOMAIN}`;
      setLocale('en');
      setMessages(translations[locale]);
    }

  }, [locale]);

  return (

      <Router>
        <div className={classes.bg}>
          <IntlProvider locale={locale} messages={messages}>
          <ModalContextProvider>
            <SideMenuContextProvider>
                      <DarkMode.Provider value={{isDarkMode, setIsDarkMode}}>
                        <ThemeProvider theme={theme}>
                        <SolutionsContextProvider>

                          <Container maxidth="lg"  className="d-flex flex-column p-0" style={{ minHeight: '100vh', width: "100%" }}>
                        <Navbar setLocale={setLocale} />
                        
                            <SidebarMenu />
                            {window.location.host.split('.')[0] === 'blog' ? (
                              <Routes>
                                <Route path="/" element={<Blog />} />
                                <Route path="/:category" element={<BlogPosts />} />
                                <Route path="/:category/:slug" element={<BlogPost />} />
                              </Routes>
                            ) : ( 
                              <Routes>
                                <Route path="/" exact  element=
                                  {<Home /> }
                                />
                                <Route path="/about" exact  element=
                                  {<About /> }
                                />
                                <Route path="/login" exact  element=
                                  {user 
                                  ? <Navigate to="/instructions" replace={true} />
                                  : <Auth screen="login" />
                                  }
                                />
                                <Route path="/intro-survey" exact  element=
                                  {user 
                                  ? <ExperienceQuiz start="true"></ExperienceQuiz>
                                  : <Auth screen="login" />
                                  }
                                />
                                <Route path="/completed" exact  element=
                                  {user 
                                  ? <CompletedSurvey start="true"></CompletedSurvey>
                                  : <Auth screen="login" />
                                  }
                                />
                                <Route path="/thank-you" exact  element=
                                  {user 
                                  ? <ThankYouPage start="true"></ThankYouPage>
                                  : <Auth screen="login" />
                                  }
                                />
                                <Route path="/register" exact  element=
                                  {user 
                                  ? <Navigate to="/" replace={true} />
                                  : <Auth screen="register" />
                                  }
                                />
                                <Route path="/instructions" exact  element=
                                  {!user 
                                  ? <Auth screen="register" />
                                  : <Instructions />
                                  }
                                />
                                <Route path="/not-found" exact  element=
                                  {<NotFoundPage></NotFoundPage>}
                                />
                                <Route path="/challenge/:challenge/pre-questionnaire" exact  element=
                                  {!user 
                                  ? <Auth screen="register" />
                                  : <QuestionnairePage pre={true} />
                                  }
                                />
                                <Route path="/challenge/:challenge/post-questionnaire" exact  element=
                                  {!user 
                                  ? <Auth screen="register" />
                                  : <QuestionnairePage pre={false} />
                                  }
                                />
                                <Route path="/scenario-description/:challenge?" exact  element=
                                  {!user 
                                  ? <Auth screen="register" />
                                  : <Description />
                                  }
                                />
                                <Route path="/challenge/:challenge" exact  element=
                                  {!user 
                                  ? <Auth screen="register" />
                                  : <ChallengeDataProvider><Challenge /></ChallengeDataProvider>
                                  }
                                />
                              </Routes>
                            )}
                            <Footer />

                            {/* <Person />
                          <div className="row">
                            <button
                              type="button"
                              onClick={() => {
                                setShowPostForm(true);
                              }}
                            >
                              New Post
                            </button>
                          </div>
                          <div className="row">
                            <Posts />
                            {showPostForm && <NewPostForm showForm={showPostForm} />}
                          </div> */}
                          </Container>
                        </SolutionsContextProvider>
                        </ThemeProvider>
                      </DarkMode.Provider>
                      </SideMenuContextProvider>
                      </ModalContextProvider>
          </IntlProvider>
        </div>
      </Router>
  );
}

export default App;
