import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Table, TableContainer, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import { Button } from '@material-ui/core';

import { useTheme } from 'styled-components';

import useStyles from "../styles.js";
import { useDispatch, useSelector } from 'react-redux';
import { BankAuthContext, getTransfers } from '../../../../../../actions/bankScenario.js';
import SearchBar from '../Button/SearchBar.js';
import { SolutionsContext } from '../../../../../../contexts/SolutionsContext.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const Transactions = (props) => {
  // Replace this with actual transaction data
  const [transactions, setTransactions] = useState([]);
  const [query, setQuery] = useState('');
  const [transactionsError, setTransactionsError] = useState(null);

  const dispatch = useDispatch();
  const bankUser = useSelector((state) => state.bank);
  
  const { page, setPage, session } = useContext(ChallengeDataContext);
  
  const theme = useTheme();
  const classes = useStyles(theme);

  const {solutions, setSolutions} = useContext(SolutionsContext);
  
  useEffect(() => {

    if (!session.user) return;

    const unionAnswer = solutions.union;
    if(unionAnswer){
      setQuery(unionAnswer ? (unionAnswer.query || "") : "");
    }
    
    dispatch(getTransfers({username: session.user.username})).then(data => {
      if(data){
        // console.log(data)
        if(!data.error){
          setTransactions(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        }
      }
    })
  }, [solutions]);

  const handleSearch = (searchTerm) => {
    console.log('Searching for:', searchTerm);
    dispatch(getTransfers({username: session.user.username, query: searchTerm})).then(data => {
      setTransactionsError(null)
      if(data){
        if(!data.error){
          setTransactions(data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
        }
        else{
          setTransactionsError(data.error);
          setTransactions([]);
          console.log(data.error)
        }
      }
    })
    // Perform your search logic here
  };

  return (
    <Container maxWidth="md">
      <div  className={classes.text}>

      <Typography variant="h4" align="center" style={{ margin: '20px' }}>
        Transactions History
      </Typography>
      <SearchBar onSearch={handleSearch} query={query} />
      <TableContainer component={Paper}>
        <Table className={classes.bg}>
          <TableHead>
            <TableRow >
              <TableCell><Typography className={classes.text}>Date</Typography></TableCell>
              <TableCell><Typography className={classes.text}>Description</Typography></TableCell>
              <TableCell><Typography className={classes.text}>Amount (GBP)</Typography></TableCell>
              <TableCell><Typography className={classes.text}>Action</Typography></TableCell>
              <TableCell><Typography className={classes.text}>Recipient</Typography></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell><Typography className={classes.text}>{new Date(transaction.createdAt).toString() !== "Invalid Date" ? new Date(transaction.createdAt).toLocaleString() : transaction.createdAt}</Typography></TableCell>
                <TableCell><Typography className={classes.text}>{transaction.description}</Typography></TableCell>
                <TableCell><Typography className={classes.text}>{!isNaN(parseFloat(transaction.amount)) && Number(transaction.amount).toFixed(2)}£</Typography></TableCell>
                <TableCell><Typography className={classes.text}>{transaction.from == session.user.accountNumber ? "Sent" : "Received"}</Typography></TableCell>
                <TableCell><Typography className={classes.text}>{transaction.from == session.user.accountNumber ? "to "+transaction.sentTo : "from "+transaction.sentFrom }</Typography></TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {transactionsError && (
        <div className="w-100 pt-3">
          <Typography>{transactionsError}</Typography>
        </div>
      )}

      <div className="w-100">
        <Button variant="contained" className="m-3" color="primary" onClick={() => setPage("menu")}>
          Back
        </Button>
      </div>

{/* ' AND 1=2 UNION SELECT COLUMN_NAME, 1, 2, 3, 4 FROM information_schema.columns WHERE TABLE_NAME = 'users' --  */}
      </div>
    </Container>
  );
};

export default Transactions;