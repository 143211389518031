import { CHALLENGE_LOGOUT, BANK_SUBMIT, BANK_SUBMIT_ERROR,RESET_LEVEL } from "../constants/actionTypes.js";

// authReducer.js
const initialState = {
  isAuthenticated: false,
  error: false,
  loading: false,
  // other auth-related properties...
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BANK_SUBMIT:
        return {
          ...state,
          isAuthenticated: action.payload,
        };
    case BANK_SUBMIT_ERROR:
      return {
        ...state,
        error: true,
      };
    case RESET_LEVEL:
      return {
        ...state,
        isAuthenticated: false,
      };
    case CHALLENGE_LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};

// export default (bank = initialState, action) => {
//   switch (action.type) {
//     case BANK_SUBMIT:
//         if (Object.keys(acticon?.payload).length) {
//           localStorage.setItem(action?.scenario, JSON.stringify({ ...action?.payload }));
//         }
//         return {
//           data: {...bank.data, [action?.scenario]: action?.payload},
//           loading: false,
//           error: null,
//         };
//     case BANK_SUBMIT_ERROR:
//       return {
//         data: {...bank.data, [action?.scenario]: undefined},
//         loading: false,
//         error: action?.payload,
//       };
//     case RESET_LEVEL:
//       if(localStorage.getItem(action?.scenario)){
//           localStorage.removeItem(action?.scenario);
//       }
//       return {
//         data: {...bank.data, [action?.scenario]: undefined},
//         loading: false,
//         error: null,
//       };
//     case CHALLENGE_LOGOUT:
//       if(localStorage.getItem(action?.scenario)){
//           localStorage.removeItem(action?.scenario);
//       }
//       return {
//         data: {...bank.data, [action?.scenario]: undefined},
//         loading: false,
//         error: null,
//       };
//     default:
//       console.log(action)
//       if(localStorage.getItem(action?.scenario)){
//         // console.log({
//         //   data: {...bank.data, [action?.scenario]: JSON.parse(localStorage.getItem(action?.scenario))},
//         //   loading: false,
//         //   error: null,
//         // })
//         return {
//           data: {...bank.data, [action?.scenario]: JSON.parse(localStorage.getItem(action?.scenario))},
//           loading: false,
//           error: null,
//         }
//       }
//       console.log("sadf")
//       return bank;
//   }
// };
