export const light = {
    textColor: "#000",
    darkModeSwitch: "#000",
    bg: "#f6f3f3",
    logo: "rgba(0,183,255, 1)",
    bgSoft: "white",
    bgHover: "#dedede",
    textColorSoft: "#555",
    border: "lightgray",
    overlay: "rgba(255,255,255,0.5)"
}

export const dark = {
    textColor: "whitesmoke",
    darkModeSwitch: "#ebab34",
    bg: "#222",
    logo: "white",
    bgSoft: "#333",
    bgHover: "#404040",
    textColorSoft: "lightgray",
    border: "#444",
    overlay: "rgba(0,0,0,0.5)"
}