import React, { useContext, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Paper,
  Grid,
  Typography,
  Container,
  Grow,
} from "@material-ui/core"; 
import LockOutlinedIcon from "@material-ui/icons/LockOutlined.js";
import { Input } from "./Input.js";
import useStyles from "./styles.js";
import { useNavigate } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import Icon from "./Icon.js";
import { useDispatch } from "react-redux";

import { AuthContext, signin, signup } from "../../actions/auth.js";
import { useTheme } from "styled-components";
import { FormattedMessage } from "react-intl";

const initialState = {
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  password: "",
  confirmPassword: "",
};

const Auth = ({ screen }) => {
  const state = null;

  const theme = useTheme();
  const classes = useStyles(theme);

  const [showPassword, setShowPassword] = useState(false);
  const [isSignup, setIsSignup] = useState(screen == "login" ? false : true);
  const [formData, setFormData] = useState(initialState);
  const [error, setError] = useState(null);
  const [surveyLink, setSurveyLink] = useState(null);

  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleShowPassword = () =>
    setShowPassword((prevShowPassword) => !prevShowPassword);

  const switchMode = () => {
    setIsSignup((prevIsSignup) => !prevIsSignup);
    setShowPassword(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData, isSignup);

    if (isSignup) {
      dispatch(signup(formData, navigate)).then(result => {
        console.log(result, "adflaldfafajlfhadljhs")
        if(result.message){
          setError(result.message)
          if(result.survey){
            setSurveyLink(result.survey)
          }
        }else{
          setError(null);
          document.cookie = `auth=${formData.userName}`;
          navigate("/")
        }
      });
    } else {
      dispatch(signin(formData, navigate)).then(result => {
        console.log(result, "adflaldfafajlfhadljhs")
        if(result.message){
          setError(result.message)
        }else{
          setError(null);
          document.cookie = `auth=${result.result.username}`;
        }
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const googleSuccess = async (res) => {
    const result = res?.profileObj; // if res is undefined, then result will be undefiend
    const token = res?.tokenId;
    try {
      dispatch({ type: "AUTH", data: { result, token } });

      navigate("/");
    } catch (e) {
      console.log(e);
    } 
    console.log("Google Sign in was a success");
  };
  const googleFailure = (error) => {
    console.log(error);
    console.log("Google Sign in was a failure. Try again later");
  };

  return (
    <Grow in>
      <Container className={classes.bg} component="main" maxWidth="xs">
        <Paper className={classes.paper} elevation={3}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography className={classes.text} variant="h5">
            {isSignup ? <FormattedMessage id="sign-up" defaultMessage="sign-up"></FormattedMessage> : <FormattedMessage id="sign-in" defaultMessage="Sign In"></FormattedMessage>}
          </Typography>
          <form onSubmit={handleSubmit} className={classes.form}>
            <Grid container spacing={2}>
              {error && (
                <Typography className={classes.errorText} style={{padding: "0.5rem"}} variant="h8">
                  {error}
                </Typography>
              )}
              {surveyLink && isSignup && (
                    // clickable link for my survey
                    <Typography style={{textAlign: "center", width:"100%", display: "block"}}>
                      <a href={surveyLink} target="_blank" rel="noreferrer"><FormattedMessage id="survey-link" defaultMessage="Survey Link"></FormattedMessage></a>
                    </Typography>
                  ) }
              {isSignup && (
                
                <>
                  <Input
                    type="text"
                    name="firstName"
                    label={<FormattedMessage id="first-name" defaultMessage="First Name"></FormattedMessage>}
                    handleChange={handleChange}
                    autoFocus
                    half
                  />
                  <Input
                    type="text"
                    name="lastName"
                    label={<FormattedMessage id="last-name" defaultMessage="Last Name"></FormattedMessage>}
                    handleChange={handleChange}
                    half
                  />
                  <Input
                    type="text"
                    name="userName"
                    label={<FormattedMessage id="username" defaultMessage="Username"></FormattedMessage>}
                    handleChange={handleChange}
                  />
                  <Input
                    type="email"
                    name="email"
                    label={<FormattedMessage id="email" defaultMessage="Email"></FormattedMessage>}
                    handleChange={handleChange}
                  />
                </>
              )}
              {!isSignup && (
                <Input
                  type="text"
                  name="email"
                  label={<FormattedMessage id="email-or-username" defaultMessage="Email or Username"></FormattedMessage>}
                  handleChange={handleChange}
                />
              )}
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                label={<FormattedMessage id="password" defaultMessage="Password"></FormattedMessage>}
                handleChange={handleChange}
                handleShowPassword={handleShowPassword}
              />
              {isSignup && (
                <Input
                  type="password"
                  name="confirmPassword"
                  label={<FormattedMessage id="confirm-password" defaultMessage="Confirm Password"></FormattedMessage>}
                  handleChange={handleChange}
                  handleShowPassword={handleShowPassword}
                />
              )}
            </Grid>
            {/* <Typography className={classes.errorText} variant="h8">
              {error ? error : ""}
            </Typography> */}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {isSignup ? <FormattedMessage id="sign-up" defaultMessage="sign-up"></FormattedMessage> : <FormattedMessage id="sign-in" defaultMessage="Sign In"></FormattedMessage>}
            </Button>
            {/* <GoogleLogin
              clientId="57984842783-uqm620pa8nsetok09aj34jpjec622933.apps.googleusercontent.com"
              render={(renderProps) => (
                <Button
                  className={classes.googleButton}
                  // color=""
                  fullWidth
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}
                  startIcon={<Icon />}
                  variant="contained"
                >
                  Google Sign In
                </Button>
              )}
              onSuccess={googleSuccess}
              onFailure={googleFailure}
              cookiePolicy="single_host_origin"
            ></GoogleLogin> */}
            <Grid container justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                fullWidth
                onClick={() => {
                  navigate(`/${isSignup ? "login" : "register"}`);
                  setIsSignup(!isSignup);
                }}
              >
                {isSignup
                  ? <FormattedMessage id="already-account" defaultMessage="Already have an account? Sign In"></FormattedMessage>
                  : <FormattedMessage id="no-account" defaultMessage="Don't have an account? Sign Up"></FormattedMessage>
                }
              </Button>
            </Grid>
          </form>
          
        </Paper>
      </Container>
    </Grow>
  );
};

export default Auth;
