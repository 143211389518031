import React from "react";
import App from "./App.js";
import ReactDom from "react-dom/client";
// import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk"; 
import { AuthContextProvider } from "./actions/auth.js";
import "./style.scss";

import reducers from "./reducers/index.js";

const store = createStore(reducers, compose(applyMiddleware(thunk)));
const root = ReactDom.createRoot( document.getElementById("root"));
root.render(
  <Provider store={store}>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </Provider>,
);

// test

// npm start - starts the server
