import React, { useEffect, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { fetchArticles } from '../../actions/blog.js';
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { FormattedMessage } from 'react-intl';


const Blog = () => {

  const theme = useTheme();
  const classes = useStyles(theme);


  // Fetch blog posts from the API
  const [posts, setPosts] = useState([]);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchArticles()).then((result) => {
      console.log(result);
      if(!result){
        setError("An uknown error occurred while fetching articles.");
      }
      else{
        if (result.error) {
          setError(result.error);
        } else {
          setPosts(result);
        }
      }
    });
  }, [])

  return (
    <Container className={classes.root}>
      <Typography variant="h4" gutterBottom>
      <FormattedMessage id="blog-title" defaultMessage="Welcome to our Web Security Blog" ></FormattedMessage>
      </Typography>
      <Typography variant="body1" paragraph>
        <FormattedMessage id="blog-description" defaultMessage="Explore our blog to learn about real-world web security scenarios and
        how to defend against common attacks. Each challenge provides detailed
        teaching materials to enhance your understanding." ></FormattedMessage>
      </Typography>

      <Grid container spacing={3}>
        {/* Example blog post cards */}
        {posts.map((post) => (
          <Grid item xs={12} sm={6} md={4} key={post}>
            <Card className={classes.card}>
              {/* <CardMedia
                component="img"
                alt={`${post.title}`}
                height="160"
                image={`https://source.unsplash.com/160x160/?security,cyber,technology`}
                className={classes.media}
              /> */}
              <CardContent className={classes.content}>
                <Typography variant="h6" gutterBottom>
                  {post.title}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {/* Description of the blog post content. Lorem ipsum dolor sit
                  amet, consectetur adipiscing elit. */}
                </Typography>
                <Link to={`/${post.category}/${post.slug}`} style={{ textDecoration: 'none' }}>
                  Read more
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
        {error && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error" align="center">
              {error}
            </Typography>
          </Grid>
        )}
      </Grid>

      {/* Outlet for nested routes */}
      <Outlet />
    </Container>
  );
};

export default Blog;
