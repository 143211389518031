// src/App.js
import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, Container, CssBaseline } from '@mui/material';
import LoginPage from '../../Common/LoginPage/LoginPage.js';
import Dashboard from './Dashboard/Dashboard.js';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

import {
    getPage,
    getBrowsersAndTabs,
    getUser,
    getParams,
    getUid,
    getUrl,
    getDomain,
    getUndoHistory,
    getUndoHistoryPointer,
    getSession,
    getActiveTab,
    getSelectedBrowser,
    getTabs
  } from 'selectors/browser';
import { useDispatch, useSelector } from 'react-redux';
import { pageIsAuth, scenarioAuth } from 'actions/challenge.js';
import { getParameters } from 'helper/helper.js';
import { useParams } from 'react-router-dom';

const BankScenario = () => {
      
      
    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const activeTab = useSelector(getActiveTab);
    const selectedBrowser = useSelector(getSelectedBrowser);

    const theme = useTheme();
    const classes = useStyles(theme);
    const { challenge } = useParams()

    // const [fetchSessionDone, setFetchSessionDone] = useState(false);

    const dispatch = useDispatch();

    console.log(session, page, "dashboard")

    // async function fetchParams() {
    //     const urlParams = await getParameters(url);
    //     dispatch({ type: 'UPDATE_PARAMS', payload: urlParams });
    // }

    // const fetchSession = async () => {
    //     try {
    //       // const response = await axios.get('http://localhost:5000/api/session', {
    //       //   withCredentials: true, // Send cookies along with the request
    //       // });
  
    //       // console.log(selectedBrowser, activeTab, session, selectedBrowser)
  
    //       if(session)
    //       {
    //         if(session.sessionId)
    //         {
    //           const expirationDate = new Date();
    //           expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
    //           if(!document.cookie.includes('session_id='))
    //           {
    //             // document.cookie = `session_id=${session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
    //           }
    //         }
    //       }
  
    //       let cookieId = null;
    //       if(challenge == "challenge7"){
    //         if(document.cookie.includes('session_id='))
    //         {
    //           cookieId = document.cookie
    //             .split("; ")
    //             .find((row) => row.startsWith("session_id="))
    //             ?.split("=")[1];
    //         }
    //       }
  
    //       // getSession server
    //       dispatch(scenarioAuth({scenario: activeTab, browser: selectedBrowser, session_id: cookieId})).then(data => {
  
    //         // get authneticated user forom local storage
  
    //         if(data.requiresLogin)
    //         {
    //           let dataSessionExists = false;
    //           if(data)
    //           {
  
    //             // data session exists
    //             if(data.session){
  
    //               dispatch({ type: 'UPDATE_SESSION', payload: data.session });
    //               // if(data.isAuthenticated === false){
    //               //   dataSessionExists = false;
    //               // }
  
    //               // if(data.session.status){
    //               //     dataSessionExists = true;
    //               // }
    //               // else{
    //               //     dataSessionExists = false;
    //               // }
    //             }
    //             // else{
    //             //   dataSessionExists = false;
    //             // }
  
    //           }
  
    //           // const expirationDate = new Date();
    //           // expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
  
    //           // if(dataSessionExists){
    //           //   document.cookie = `${selectedBrowser}_${activeTab}=${data.session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
    //           //   document.cookie = `session_id=${data.session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
    //           //   if(!url){
    //           //     dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com`});
    //           //   }
    //           // }
    //           // else{
    //           //   document.cookie = `${selectedBrowser}_${activeTab}=; path=/;`;
    //           //   document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    //           //   dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com/login`});
    //           // }
    //         }
    //         else{
    //           // dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com`});
    //         }

    //         setFetchSessionDone(true);
    //       });
  
    //       // Assuming the response has a property isAuthenticated
    //       // dispatch(setAuthenticated(response.data.isAuthenticated));
    //     } catch (error) {
    //       console.error('Error fetching session:', error);
    //     }
    // };

    // useEffect(() => {

    //     console.log(session, activeTab, "session, bank scenario");

    //     if(!fetchSessionDone) return;

    //     let action = {};
    //     let newPage = page;

    //     let pageUrl = url;

    //     if(!pageUrl){
    //         pageUrl = `https://www.${activeTab}.com`;
    //     }

    //     if(pageUrl === "https://www.bank.com" || pageUrl === "https://www.bank.com/"){
    //         // accessing mainly the scam pages:
    //         // https://www.bank.change-your-password.com
    //         // https://www.bank.verify-your-account.com
    //         action = { type: 'UPDATE_PAGE', payload: "menu" };
    //         newPage = "menu";
    //     }
    //     // check if the path is /login
    //     else if(pageUrl.includes("/login")){
    //         action = { type: 'UPDATE_PAGE', payload: "login" };
    //         newPage = "login";
    //     }
    //     else if(pageUrl.includes("/savings")){
    //         action = { type: 'UPDATE_PAGE', payload: "savings" };
    //         newPage = "savings";
    //     }
    //     else if(pageUrl.includes("/transactions")){
    //         action = { type: 'UPDATE_PAGE', payload: "transactions" };
    //         newPage = "transactions";
    //     }
    //     else if(pageUrl.includes("/transaction")){
    //         action = { type: 'UPDATE_PAGE', payload: "transaction" };
    //         newPage = "transaction";
    //     }
    //     if(action.type && action.payload){
    //         // check if the page is accessible 

    //         console.log(pageUrl, newPage, "page url new page")

    //         const expirationDate = new Date();
    //         expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now

    //         dispatch(pageIsAuth({url: pageUrl, page: newPage, browser: selectedBrowser, scenario: activeTab})).then(data => {
    //             console.log(pageUrl, newPage)
    //             console.log(data, "page is auth")
    //             // some pages are accessible only to authenticated users and some are not
    //             // if the page is not accessible redirect to the login page
    //             if(data.accessible === false)
    //             {
    //                 dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com/login`});
    //                 dispatch({type: "UPDATE_PAGE", payload: "login"});

    //                 document.cookie = `${selectedBrowser}_${activeTab}=; path=/;`;
    //                 document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';

    //             }
    //             else if(data.accessible === true)
    //             {
    //                 document.cookie = `${selectedBrowser}_${activeTab}=${session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
    //                 document.cookie = `session_id=${session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;

    //                 dispatch(action);
    //                 if(!pageUrl){
    //                     dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com`});
    //                 }
    //                 else{
    //                     dispatch({type: "UPDATE_URL", payload: pageUrl});
    //                 }
    //             }
    //             fetchParams();
    //         });

    //     }
    // }, [url, session, fetchSessionDone]);

    // useEffect(() => {

    //     if(!activeTab) return;
    //     if(!selectedBrowser) return;

    //     console.log(activeTab, selectedBrowser, "active tab selected browser")
    //     fetchSession();
    
    // }, [dispatch, activeTab, selectedBrowser]);

    //' OR balance > 1000000

    return (
        <Container component="main" className="m-0 p-0" maxWidth="md">
            {page == null ? 
            <Container maxWidth="lg" className="position-relative p-0 d-flex justify-content-center align-items-center">
                <CircularProgress />
            </Container> : 
            <>
                {page === "login" ? <LoginPage title="Login to your {platform} account." platform="Bank" /> : <Dashboard />}
            </>}
        </Container>
    );
};

export default BankScenario;