import React, { useEffect, useState } from 'react';
import { Container, Typography, Slider, Button, MenuItem, FormControl, InputLabel, Select, List, ListItem, ListItemText, Grid, Checkbox, ListItemIcon, FormControlLabel, Radio, RadioGroup, IconButton } from '@material-ui/core';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useDispatch } from 'react-redux';
import { submitSurvey } from 'actions/survey.js';
import { useNavigate } from 'react-router-dom';
import { fetchSurveyQuestions } from 'actions/survey.js';
import { FormattedMessage } from 'react-intl';

const ExperienceQuiz = () => {
    const theme = useTheme();
    const classes = useStyles(theme);

    const [error, setError] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // fetch it from the database
    const [securityIssues, setSecurityIssues] = useState([]);
  
    const [unansweredIssues, setUnansweredIssues] = useState([]);
    const [submitted, setSubmitted] = useState(false);
  
    const handleRatingChange = (id, newRating) => {
      setSecurityIssues((prevIssues) =>
        prevIssues.map((issue) =>
          issue.id === id ? { ...issue, rating: newRating } : issue
        )
      );
    };
  
    const handleSubmit = () => {
      const unanswered = securityIssues.filter((issue) => issue.rating === null);
      setUnansweredIssues(unanswered);
  
      if (unanswered.length > 0) {
        // Highlight unanswered issues in red
        console.error('Please answer all questions before submitting.');
      } else {
        // Handle the submission logic
        console.log('Submitted Ratings:', securityIssues);
        // You can submit the data to the server or take other actions

        dispatch(submitSurvey({info: securityIssues, type: "prior"})).then(data =>{
          if(data.message){
            console.log('success')
            setSubmitted(true);
            setTimeout(() => {
              navigate("/scenario-description")
            }, 500);
          }
          else{
            setError('An error happened, pleaase try again later.');
          }
        })
      
      }
    };

    useEffect(() => {
      // fetch survey quesitons
      dispatch(fetchSurveyQuestions()).then(data =>{
        if(data.message){
          setError(data.message);
        }
        else if(data.completed){
            navigate("/thank-you");
        }
        else if(data.initCompleted){
            navigate("/scenario-description");
        }
        else if(data.challengesCompleted){
          navigate("/completed"); // open completed survey
        }
        else{
            if(data.issues.categories){
              setSecurityIssues(data.issues.categories.map((category, idCounter = 1) => ({ id: idCounter++, issue: category.issue, tags: category.tags || [], rating: null })));
            }
        }
      })
    }, []);
  
    return (
      <Container>
        <Grid container className=" d-flex justify-content-center py-3">
          <Grid className='w-100 text-center'>
          <Typography variant="h4" gutterBottom>
          <FormattedMessage id="init-survey-title" defaultMessage="Web Security Experience Survey"></FormattedMessage>
          </Typography>
        </Grid>
        <Grid className='w-100 text-center'>
          <Typography variant="p" gutterBottom>
          <FormattedMessage id="init-survey-description" defaultMessage="Before getting started, we need to learn more about your background to ensure you get the best experience and learn the most out of web security. We understand that the resources regarding web security are very limited, and most likely not challenging enough. HackMeDown will take this inforamtion to provide you with challenges to to help you learnand be challenged."></FormattedMessage>
          </Typography>
        </Grid>
          <Grid className='w-100'>
        <List>
        <ListItem>
        <Grid item xs={2}>
          <ListItemText className="text-center" primary="Security Issue" />
        </Grid>
        <Grid item xs={10} className='d-flex '>
          <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Not at all" />
          <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Not much" />
          <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Average" />
          <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Good" />
          <ListItemText style={{ flex: '1 1 20%' }} className="text-center" primary="Very Good" />
        </Grid>
        </ListItem>
        {securityIssues.map((issue) => (
          <ListItem key={issue.id}>
            <Grid item xs={2} className="d-flex align-items-center">
              {issue.rating !== null ? (
                <IconButton
                  color="primary"
                  edge="start"
                  disabled
                  aria-label="answered"
                >
                  <CheckCircleIcon />
                </IconButton>
              ) : null}
              <ListItemText primary={issue.issue} />
            </Grid>
            <Grid item xs={10}>

              <RadioGroup
                row
                aria-label={`rating-${issue.id}`}
                name={`rating-${issue.id}`}
                value={issue.rating !== null ? String(issue.rating) : ''}
                className='w-100 d-flex justify-content-between'
                onChange={(event) => handleRatingChange(issue.id, parseInt(event.target.value, 10))}
              >
                {[1, 2, 3, 4, 5].map((rating) => (
                  <FormControlLabel
                    key={rating}
                    value={String(rating)}
                    control={<Radio color="primary" />}
                    label=""
                    className='m-0 d-flex justify-content-center'
                    style={{ flex: '1 1 20%' }}
                  />
                ))}
              </RadioGroup>
              {submitted && issue.rating !== null && (
                <Checkbox
                  checked
                  color="primary"
                  inputProps={{ 'aria-label': 'checked' }}
                />
              )}
            </Grid>
          </ListItem>
        ))}
      </List>
          </Grid>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
            <FormattedMessage id="submit" defaultMessage="Submit"></FormattedMessage>
            </Button>
            {error && <Typography class="w-100 text-center mt-3" color="error">{error}</Typography>}
            {submitted && <Typography class="w-100 text-center mt-3" color="success">{<FormattedMessage id="survey-submitted" defaultMessage="Survey Submitted"></FormattedMessage>}</Typography>}
        </Grid>
      </Container>
  );
};

export default ExperienceQuiz;
