import { Avatar, IconButton, ListItemAvatar, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { Visibility as VisibilityIcon, Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';


const RenderRow = ({row, setBtnClicked, setSelectedRow}) => {
      
    const handleViewMore = (row) => {
          // Implement the logic to open a modal and display all details of the post
        console.log('View more:', row);
        setSelectedRow(row)
        setBtnClicked("view");
    };
        
    const handleEdit = (row) => {
          // Implement the logic to open a modal and allow editing of the post
        console.log('Edit:', row);
        setSelectedRow(row)
        setBtnClicked("edit");
    };
        
    const handleDelete = (row) => {
          // Implement the logic to open a modal and allow deleting the post
        console.log('Delete:', row);
        setSelectedRow(row)
        setBtnClicked("delete");
    };

    return (
        <TableRow key={row.id}>
        {Object.keys(row).map((key) => 
            (
                <>
                    {key == "avatar" ? (
                        <TableCell key={key}>
                            <Avatar alt={"avatar"} src={row[key]} />
                        </TableCell>
                    ) : (
                        <TableCell key={key}>{row[key]}</TableCell>
                    )}
                </>
            )
        )}
        <TableCell>
            {/* View more button */}
            <IconButton onClick={() => handleViewMore(row)}>
            <VisibilityIcon />
            </IconButton>
    
            {/* Edit button */}
            <IconButton onClick={() => handleEdit(row)}>
            <EditIcon />
            </IconButton>
    
            {/* Delete button */}
            <IconButton onClick={() => handleDelete(row)}>
            <DeleteIcon />
            </IconButton>
        </TableCell>
        </TableRow>
    );
    };

  export default RenderRow;