import React, { useContext, useEffect, useState } from 'react';
import { getProfileSettings, updateUser } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData';

import { Drawer, List, ListItem, ListItemText, AppBar, Toolbar, Button, Typography, Container } from '@mui/material';
import UsersTab from './UsersTab';
import PostsTab from './PostsTab.js';
import SettingsTab from './SettingsTab';

const Admin = () => {
  
  const [error, setError] = useState(null);
  // const [user, setUser] = useState({});

  const {page, setPage, session, params, setUrl, uid, url, domain, activeTab} = useContext(ChallengeDataContext);

  const dispatch = useDispatch();

  const {data} = useSelector((state) => state.socialMedia);

  const [selectedTab, setSelectedTab] = useState('posts');

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case 'posts':
        return <PostsTab content={"posts"} />;
      case 'users':
        return <PostsTab content={"users"} />;
      case 'settings':
        return <SettingsTab />;
      default:
        return null;
    }
  };

  return (
    <Container className='px-0 py-3 d-flex'>
      <Grid container className='w-100 d-flex'>
  {/* First Row */}
  <Grid container>
    <Grid sm="2">
      {/* Your Drawer or Sidebar content goes here */}
      <List>
        <ListItem button onClick={() => handleTabChange('posts')}>
          <ListItemText primary="Posts" />
        </ListItem>
        <ListItem button onClick={() => handleTabChange('users')}>
          <ListItemText primary="Users" />
        </ListItem>
        <ListItem button onClick={() => handleTabChange('settings')}>
          <ListItemText primary="Settings" />
        </ListItem>
      </List>
    </Grid>
    <Grid sm="10">
      <main>{renderTabContent()}</main>
    </Grid>
  </Grid>

  {/* Second Row */}
  <Grid container>
    {error && (
      <Typography>{error}</Typography>
    )}
  </Grid>
</Grid>

    </Container>
  );
};

export default Admin;
