// src/App.js
import React, { useContext, useEffect } from 'react';
import { Container, CssBaseline } from '@mui/material';
import LoginPage from '../../Common/LoginPage/LoginPage.js';
import { Paper } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import Dashboard from './Dashboard/Dashboard.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const ECommerceScenario = () => {

    
    const theme = useTheme();
    const classes = useStyles(theme);
    
    const {activeTab, session, sessionTest, url} = useContext(ChallengeDataContext);

    // useEffect(() => {
    //     console.log('prior',url, session)
    //     if (session.status) {
    //         console.log('ηευυυυ',session)
    //     }
    // }, [url])

    // console.log(session, sessionTest, sessionTest.data[activeTab], activeTab)

    //' OR balance > 1000000

    console.log(session, "my sessin changes")

    return (
        <Container component="main" maxWidth="md">
            {!session.status ? <LoginPage title="Login to Your Flopify Account." /> : <Dashboard />}
            {/* <OpenModal modal={"finish"} title="Finish"></OpenModal> */}
        </Container>
    );
};

export default ECommerceScenario;