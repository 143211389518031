import React, { useContext } from 'react';
import { Container, Typography, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ChallengeDataContext } from 'contexts/ChallengeData';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

export const ThankYouPage = () => {

    const { page, setPage, user, setUser, url, setUrl, domain, session, setDomain, tabs, setTabs, params } = useContext(ChallengeDataContext);
  const classes = useStyles();

  const backHome = () => {
    setUrl(`${domain}`);
    setPage('menu');
  }

  return (
    <Container className={classes.container} maxWidth="md">
      <Paper className={classes.paper} elevation={3}>
        <Typography variant="h4" gutterBottom>
          Thank You for Your Purchase!
        </Typography>
        <Typography variant="body1" paragraph>
          Your order has been successfully processed. We appreciate your business.
        </Typography>
        <Typography variant="body2" paragraph>
          Order Confirmation Number: 123456
        </Typography>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => backHome()}
        >
          Back to Home
        </Button>
      </Paper>
    </Container>
  );
};

export default ThankYouPage;
