import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import { ModalContext } from '../../../contexts/ModalContext.js';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";

const ScenarioInstructions = (props) => {

  const {isOpen, setIsOpen, setText, text} = useContext(ModalContext);

  const {modal, title} = props;

  const handleOpenModal = () => {
    setIsOpen(modal);
  };

  const theme = useTheme();
  const classes = useStyles(theme);

  return (
      <Button className="m-2" variant="contained" onClick={handleOpenModal}>
        {title}
      </Button>
  );
};

export default ScenarioInstructions;