import { Box, Button, Drawer, Grid, Typography } from "@material-ui/core";
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { AuthContext } from "../../actions/auth.js";
import AvatarIcon from "../Avatar/AvatarIcon/AvatarIcon.js";
import LogoutButton from "../Button/LogoutButton/LogoutButton.js";
import Menu from "../Menu/Menu.js";
import { useTheme } from "styled-components";

import useStyles from "./styles.js";
import { SideMenuContext } from "../../contexts/SideMenuContext.js";

export const SidebarMenu = () => {

    const user = useSelector((state) => state.auth);

    const theme = useTheme();
    const classes = useStyles(theme);

    const { menuOpen, setMenuOpen } = useContext(SideMenuContext);

    const list = () => (
        <Grid container alignItems="stretch" className={classes.bg}>
            <Box
                sx={200}
                role="presentation"
                // onClick={() => setIsOpen(false)}
                onKeyDown={() => setMenuOpen(false)}
                className={"p-3 py-4"}
            >
                <Grid container 
                        direction="row"
                        justifyContent="center"
                        alignItems="center" 
                        wrap="nowrap" spacing={2}
                    >
                    <Grid item>
                        <AvatarIcon username={user?.result.username} avatar={user?.result.avatar}></AvatarIcon>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography className={classes.text} >{user?.result.name}</Typography>
                    </Grid>
                </Grid>
                <Grid container className="py-3">
                    <Grid container item >
                        <Menu />
                    </Grid>
                    <Grid container item>
                        <LogoutButton></LogoutButton>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );

    return (
        <div>
            <Drawer
                anchor="right"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
            >
                {list()}
            </Drawer>
        </div>
        );
};