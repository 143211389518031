import React, { useEffect, useState } from 'react';
import { Container, Grid, Paper, TextField, Button, Avatar } from '@mui/material';
import { getProfile } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';

const ErrorPage = ({}) => {
  // Simulated user data (replace with actual user data from your database)

  // State for the search input
  return (
    <Container maxWidth="md">
        <Typography variant="h6" style={{ flexGrow: 1 }}>
            404 Error, not found.
        </Typography>
    </Container>
  );
};

export default ErrorPage;
