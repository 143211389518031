import { Avatar, IconButton, ListItem, ListItemAvatar, Typography } from "@material-ui/core"
import { DangrousElement } from "Components/Challenge/Scenarios/ECommerceScenario/Dashboard/DangerousElement/DangerousElement";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { useSelector } from "react-redux";
import { formatDate } from "helper/helper";
import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import useStyles from "./../../styles.js";
import { FormattedMessage } from "react-intl";

export const Post = (props) => {

    const {openProfilePage, post} = props;

    const [createdAt, setCreatedAt] = useState('');

    const theme = useTheme();
    const classes = useStyles(theme);

    // const challengeData = useSelector((state) => state.challenge);

    let challengeSeed = "";
    const regex = /challenge(\d+)/;
    let challengeRegex = window.location.href.match(regex);
    if(challengeRegex){
      challengeSeed = challengeRegex[0];
    }

    useEffect(() => {
        const updateDate = async () => {
            setCreatedAt(await formatDate(post.createdAt));
        }

        updateDate();
    }, [post]);

    return (
        <>
        <ListItem key={post.id} style={{ display: 'flex', flexDirection: 'column', flex: "1", width: "100%" }}>
            <div style={{ display: 'flex', alignItems: 'center', width: "100%", marginBottom: '10px' }}>
              <div style={{  display: 'flex', justifyContent: 'start', alignItems: 'start' }}>
              <span onClick={() => openProfilePage(post.username)} style={{ cursor: 'pointer' }}>
                <ListItemAvatar>
                  <Avatar alt={post.name} src={post.avatar} />
                </ListItemAvatar>
              </span>
              </div>
              <div style={{ marginLeft: '10px', flex: 1 }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span onClick={() => openProfilePage(post.username)} style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                  <Typography variant="subtitle1" className={classes.textSoft} style={{ fontWeight: 'bold' }}>{post.name}</Typography>
                  </span>
                  <Typography variant="caption" className={classes.textSoft} >
                    {createdAt && <><FormattedMessage id={createdAt.split(" ")[0]} defaultMessage={createdAt.split(" ")[0]} ></FormattedMessage>{" "+createdAt.split(" ").slice(1).join(" ")}</>}
                  </Typography>
                </div>
                <Typography variant="body1" className={classes.text}>
                  {challengeSeed === "callenge13" || challengeSeed === "challenge12" ? 
                    <DangrousElement markup={decodeURIComponent(post.content)} /> 
                    : <div dangerouslySetInnerHTML={{ __html: decodeURIComponent(post.content) }}></div>
                  }
                  </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="caption" className={classes.textSoft} >
                    {post.likes || 0} <FormattedMessage id="likes" defaultMessage="Likes" ></FormattedMessage>
                  </Typography>
                  {/* <FavoriteIcon color="error" style={{ marginLeft: '5px' }} /> */}
                  <IconButton color="default" aria-label="Like">
                    <FavoriteBorderIcon style={{ color: 'red' }} /> {/* Heart icon */}
                  </IconButton>
                </div>
              </div>
            </div>
          </ListItem>
        </>
    )
}