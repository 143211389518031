import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper, TextField, Button, Avatar } from '@mui/material';
import { getProfile } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData';

const UserProfile = () => {
  // Simulated user data (replace with actual user data from your database)

  const { page, uid, url, params, setPage, setUrl, domain, session } = useContext(ChallengeDataContext);
  
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState({});

  const {data} = useSelector((state) => state.socialMedia);

  const isMounted = useRef(false); // skipping first useEffect render

  // State for the search input

  // console.log(user)

  useEffect(() => {

    // console.log(data.profile)

    if (!session.status) return;
    if (!domain) return;
    // if (Object.keys(user).length > 0 ) return;
    // if (user) return;

    // console.log(session, params, user, domain, data, url)

    if (isMounted.current) {
      // console.log(user, bankUser.data.result))
      if (Object.keys(params).length === 0 ) return;

      if(data.profile && data.profile.username === params.uid)
      {
        setUser(data.profile);
      }
      else{
        // console.log(encodeURI(params.uid), params.uid.replace(/%/g, '%25'))
        dispatch(getProfile({username:  params.uid})).then((result) => {
          // console.log(result);
          setError(null)
          if (result.error) {
            setError(result.error);
          } else {
            if(result){
              setUser(result);
            }
          }
        });
      }
    }
    else{
      isMounted.current = true;
    }

  }, [params, session, domain]);
  
  return (
    <Container maxWidth="md">
      {/* App Bar */}
      {/* <AppBar position="sticky" color="default">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Facebook
          </Typography>
        </Toolbar>
      </AppBar> */}

      {/* Cover Photo */}
      {!error ? (
        <>
          <div
            style={{
              backgroundImage: `url(${user.coverPhoto})`,
              backgroundSize: 'cover',
              height: 250,
            }}
          >
            {/* Profile Picture */}
            <Avatar
              src={user.avatar}
              alt={user.name}
              style={{
                width: 150,
                height: 150,
                border: '5px solid white',
                margin: '80px auto 0',
              }}
            />

            {/* User Name */}
            <Typography
              variant="h4"
              component="div"
              style={{ color: 'white', textAlign: 'center', marginTop: 16 }}
            >
              {user.name}
            </Typography>

            {/* Edit Profile Button */}
            {session.user && session.user.username === user.username && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setPage("edit")}
                style={{ margin: '16px auto 0', display: 'block' }}
              >
                Edit Profile
              </Button>
            )}
          </div>

          {/* User Information */}
          <Card style={{ marginTop: 16 }}>
            <CardContent>
              <Typography variant="h6">About</Typography>
              <Divider style={{ margin: '8px 0' }} />
              <Typography variant="body1">Name: {user.name}</Typography>
              <Typography variant="body1">Username: {user.username}</Typography>
              <Typography variant="body1">Role: {user.role}</Typography>
              {/* Add more user information here */}
            </CardContent>
          </Card>

          {/* User Posts (You can replace this with your actual posts component) */}
          <Card style={{ marginTop: 16 }}>
            <CardContent>
              <Typography variant="h6">Posts</Typography>
              <Divider style={{ margin: '8px 0' }} />
              {/* Add user posts here */}
            </CardContent>
          </Card>
        </>
      ) : (
        <Typography variant="h6" className="m-3">{error}</Typography>
      )}
    </Container>
  );
};

export default UserProfile;
