import React, { useState, useEffect, useContext } from "react";
import { Button } from "@material-ui/core";
import useStyles from "./styles.js";

import HomeIcon from "@material-ui/icons/Home.js";
import SearchIcon from "@material-ui/icons/Search.js";
import PersonIcon from "@material-ui/icons/Person.js";
import MailOutlineIcon from "@material-ui/icons/MailOutline.js";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder.js";
import ListAltIcon from "@material-ui/icons/ListAlt.js";
import PermIdentityIcon from "@material-ui/icons/PermIdentity.js";
import SettingsIcon from '@mui/icons-material/Settings.js';
// import SettingsIcon from '@mui/icons-material/Settings.js';
import SidebarLink from "./SidebarLink.js";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTheme } from "styled-components";
import { SideMenuContext } from "../../contexts/SideMenuContext.js";
import { FormattedMessage } from "react-intl";
// import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

export const Menu = () => 
{
  const theme = useTheme();
  const classes = useStyles(theme);

  const navigate = useNavigate();

  const { menuOpen, setMenuOpen } = useContext(SideMenuContext);

  const btns = [
    {
      text: <FormattedMessage id="home" defaultMessage="Home"></FormattedMessage>,
      icon: HomeIcon,
      navigate: "/",
      hasBadge: false,
    },
    {
      text: <FormattedMessage id="about" defaultMessage="About"></FormattedMessage>,
      icon: PersonIcon,
      navigate: "/about",
      hasBadge: false,
    },
    // {
    //   text: "Settings",
    //   icon: SettingsIcon,
    //   navigate: `/settings`,
    //   hasBadge: false,
    // },
  ]

  return (
    <div className="sidebar">
      {btns.map(item => (
        <Button className={classes.menuButton} style={{textTransform: 'none', fontWeight: '400'}} onClick={() => {navigate(item.navigate); setMenuOpen(false); }}>
          <SidebarLink text={item.text} Icon={item.icon} hasBadge={item.hasBadge} type={item.text} />
        </Button>
      ))}
      {/* <Button className="post-btn" variant="contained" color="secondary">
        <CreateIcon />
        Say Something
      </Button> */}
    </div>
  );
};

export default Menu;
