import { IconButton, Tab, Tabs } from '@material-ui/core';
import { changeBrowser } from 'actions/challenge';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const BrowserSwitch = () => {

    const { browsers, selectedBrowser, setSelectedBrowser, setTabs, browsersAndTabs, session } = useContext(ChallengeDataContext);

    // console.log(browsers, selectedBrowser)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeBrowser = (browser) => {

        if (browser === selectedBrowser) return;

        setSelectedBrowser(browser);
        setTabs(browsersAndTabs[browser]);

        // console.log(browser)
        
        // const expirationDate = new Date();
        // expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
        // document.cookie = `browser=${browser}; expires=${expirationDate.toUTCString()}; path=/;`;

        console.log(session, selectedBrowser, document.cookie)

        dispatch(changeBrowser({browser: browser})).then(() => {
            console.log("Browser changed step added");
        });
        
        navigate(`?browser=${browser}`);

        // refresh the page
        // window.location.reload(false);
    }

    return (
        <div style={{ display: 'flex', width: "100%", alignItems: 'center' }}>
            <Tabs
                value={browsers.indexOf(selectedBrowser) || 0}
                fullWidth
                // onChange={handleChangeBrowser}
                variant="scrollable"
                scrollButtons="auto"
                className='my-2 w-100' // Set the background color of the tabs row
                // sx={{ backgroundColor: 'lightgray', width: "100px" }}
            >
                {/* Add a tab for each website */}
                {browsers.map((tab) => (  // Loop through the tabs array
                    <Tab className='w-100' label={tab} key={tab} onClick={ () => handleChangeBrowser(tab)} />
                ))}
                {/* Add more tabs as needed */}
            </Tabs>
        </div>
    );
};

export default BrowserSwitch;
