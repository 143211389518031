import { GET_CHALLENGE, SET_CHALLENGE } from "../constants/actionTypes.js";

const initialState = {
    data: {},
    loading: false,
    error: null,
}

export default (challenge = initialState, action) => {
    switch (action.type) {
        case GET_CHALLENGE:
            return { data: { ...challenge.data, ...action.payload }, loading: false, error: null };
        case SET_CHALLENGE:
            return { data: { ...challenge.data, ...action.payload }, loading: false, error: null };
        default:
            return challenge;
    }
};
