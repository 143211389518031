import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles(() => ({
    modalContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    box: (theme) => ({
        backgroundColor: theme.bgSoft,
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        maxWidth: '500px',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        textAlign: "center",
        maxHeight: "80%",
        overflow: 'hidden', // Hide any overflowing content outside the box
    }),
    text: (theme) => ({
        color: theme.textColor,
        margin: '0',
    }),
    textContent: (theme) => ({
        overflowY: "auto",
        marginTop: "0.5rem",
        padding: "16px 0px"
    }),
    boxSnippets: (theme) => ({
      backgroundColor: theme.bgSoft,
        border: '1px solid #ccc',
        padding: '20px',
        borderRadius: "0.25rem",
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        fontFamily: 'monospace', // Set the font to monospace
        whiteSpace: 'pre-wrap', // Preserve whitespace and wrap lines
        maxWidth: '800px',
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'start',
        textAlign: "start",
        maxHeight: "90%",
      }),
      textContentSnippets: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
        padding: "0.5rem",
        marginTop: "0.5rem",
        overflowWrap: 'break-word',
        overflowY: 'auto', // Make the content box scrollable
        maxHeight: '100%', // Set a maximum height if needed
        paddingRight: '17px', // Add space for the scrollbar
        position: 'relative',
      },
      textSnippets: {
        margin: 0,
      },
      scrollButton: {
        // position: 'absolute',
        // bottom: '10px', // Adjust the position as needed
        // left: '50%',
        // transform: 'translateX(-50%)',
      },
}));
