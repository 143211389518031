import { Button } from "@material-ui/core";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { AuthContext } from "../../../actions/auth.js";
import useStyles from "../LogoutButton/styles.js";
import { resetLevel } from "../../../actions/challenge.js";
import { ChallengeDataContext } from "contexts/ChallengeData.js";


const ResetLevelButton = () => {

    const theme = useTheme();
    const classes = useStyles(theme);
    const {user} = useContext(AuthContext);

    const {challenge} = useParams();

    const dispatch = useDispatch(); 
    const navigate = useNavigate();

    const { resetStates, tabs, setSession, setRestart } = useContext(ChallengeDataContext);

    const reset = () => {
        dispatch(resetLevel({username: user.result.username, currentChallenge: challenge})).then(data => {

            
            if(tabs.length > 0)
            {
                for(let i = 0; i < tabs.length; i++){
                    document.cookie = `${tabs[i]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

                }
                document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
            }
            setRestart(true);
            resetStates();
            window.location.reload(false);
        });
    };

    return (
        <Button
            variant="contained"
            className="m-2"
            onClick={() => reset()}
            color="primary"
        >
            {" "}
            Reset
        </Button>
    );
}

export default ResetLevelButton;