import { GET_EMAILS, GET_EMAILS_ERROR, SEND_EMAIL, SEND_EMAIL_ERROR, UPDATE_CHALLENGE_STATUS } from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { createContext, useEffect, useReducer } from "react";

export const fetchEmails = (info) => async (dispatch) => {
  try{
    const {data} = await api.fetchEmails(info);

    // ' OR balance > 1000000 --
    dispatch({ type: GET_EMAILS, payload: data.emails });

    if(data.step)
    {
      console.log(data.step)
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_EMAILS_ERROR, payload: e.message });
  }
};

export const createEmail = (email) => async (dispatch) => {
  try{
    const {data} = await api.createEmail(email);
    console.log(data)
    dispatch({ type: SEND_EMAIL, payload: data.email });

    if(data.step)
    {
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step});
    }

    return data;
  }
  catch (e) {
    console.log(e)
    dispatch({ type: SEND_EMAIL_ERROR, payload: e.message });
  }
}