import { BANK_TRANSACTIONS, BANK_TRANSFER_ERROR, CREATE_BANK_TRANSACTION} from "../constants/actionTypes.js";

const initialState = {
  data: [],
  loading: false,
  error: null,
}

export default (transactions = initialState, action) => {
  switch (action.type) {
    case BANK_TRANSACTIONS:
      return {data: action?.payload, loading: false, error: null};
    case CREATE_BANK_TRANSACTION:
      return {data: [...transactions.data, action?.payload], loading: false, error: null};
    case BANK_TRANSFER_ERROR:
      return {data: [], loading: false, error: action?.payload};
    default:
      return transactions;
  }
};
