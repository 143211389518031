import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
      },
      card: {
        display: 'flex',
        marginBottom: theme.spacing(2),
      },
      media: {
        width: 160,
        height: 160,
        objectFit: 'cover',
      },
      content: {
        flex: '1 0 auto',
      },
      title:{
        fontWeight: 700,
        textAlign: 'center',
      },
      body: {
        fontFamily: 'Arial, sans-serif',
        lineHeight: 1.6,
        margin: 20,
      },
      headings: {
        color: '#333',
      },
      paragraph: {
        marginBottom: 15,
      },
      pre: {
        backgroundColor: '#f4f4f4',
        padding: 10,
        borderRadius: 5,
        overflow: 'auto',
      },
      ul: {
        listStyleType: 'none',
        padding: 0,
      },
      listItem: {
        '&::before': {
          content: '"\\2022"', // Unicode character for bullet point
          color: '#333',
          marginRight: 8,
        },
      },
      solution: {
        display: 'none',
      },
      spoilAnswer: {
        fontStyle: 'italic',
        cursor: 'pointer',
      },
}));
