import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
  paper: (theme) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "1rem",
    backgroundColor: theme.bg,
    padding: "1rem",
  }),
  bg: (theme) => ({
    paddingTop: "4rem",
  }),
  text: (theme) => ({
    color: theme.textColor
  }),
  errorText: (theme) => ({
    color: "red",
    textAlign: "center",
  }),
  // root: {
  //   "& .MuiTextField-root": {
  //     margin: theme.spacing(1),
  //   },
  // },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  googleButton: {
    marginBottom: theme.spacing(2),
  },
}));
