import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import { CircularProgress, IconButton, TextField } from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useDispatch } from 'react-redux';
import { submitReport } from 'actions/challenge.js';
import { FormattedMessage } from 'react-intl';

const NotWorking = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);

  const [description, setDescription] = useState("");
  const [result, setResult] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

  const {content, title} = props;

  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();

  const contentRef = useRef(null);
  const [showScrollIndicator, setShowScrollIndicator] = useState(false);

  useEffect(() => {
      
      
      const timeoutId = setTimeout(() => {
      
        if(!contentRef.current) return;
        if(isOpen !== "report") return;

        if (contentRef.current) {
          const handleScroll = () => {
            const isScrollable = contentRef.current.scrollHeight - contentRef.current.scrollTop > contentRef.current.clientHeight;
            setShowScrollIndicator(isScrollable);
          };

          handleScroll();
    
          contentRef.current.addEventListener('scroll', handleScroll);
    
          return () => {
            contentRef.current.removeEventListener('scroll', handleScroll);
          };
        }
      }, 0); // Delay of 0 milliseconds
    
      return () => clearTimeout(timeoutId);
  }, [contentRef, isOpen]);

  const scrollToBottom = () => {
    contentRef.current.scrollTop = contentRef.current.scrollHeight;
  };

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  const handleSubmitReport = () => {

    setError("");

    if(description === ""){
        setError("Please describe the issue.");
        return;
    }

    console.log("Report submitted");
    setLoading(true);
    dispatch(submitReport({report: description})).then((data) => {
        if(data){
            if(data.success){
                setResult(data.message);
            }
        }
        else{
            setResult("An error occured. Please try again later.");
        }

        setLoading(false);
        // call handleCloseModal after 3 seconds:
        setTimeout(() => {
            handleCloseModal();
            setResult("");
        }, 3000);
    });
  }

  return (
    <div>
        <Modal 
            open={isOpen === "report"}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box  className={classes.box}>
                <h2 id="modal-title" className={classes.text}><FormattedMessage id="Something's not working?" defaultMessage="Something's not working?" /></h2>
                <Box className={`${classes.textContent} text-start`} ref={contentRef}>
                    <Typography variant="p" className={classes.text}>
                        - <FormattedMessage id="Current step not progressing?" defaultMessage="Current step not progressing?" />
                    </Typography>
                    <br />
                    <Typography variant="p" className={classes.text}>
                        - <FormattedMessage id="You fonud a problem?" defaultMessage="You fonud a problem?" />
                    </Typography>
                    <br />
                    <Typography variant="p" className={classes.text}>
                        - <FormattedMessage id="You have a complaint about something?" defaultMessage="You have a complaint about something?" />
                    </Typography>
                    <br />
                    <br />
                    <Typography variant="p" className={classes.text}>
                        <FormattedMessage id="Please, let me know what happened below and I will fix is as soon as possible." defaultMessage="Please, let me know what happened below and I will fix is as soon as possible." />
                    </Typography>
                </Box>
                <Box className="w-100 d-flex justify-content-center align-items-center">
                    {/* Material UI TextField replacing the textarea */}
                    <TextField
                    className="w-100"
                    variant="outlined"
                    multiline
                    rows={4}
                    placeholder={<FormattedMessage id="Describe the issue here"  defaultMessage="Describe the issue here..." />}
                    onChange={(e) => setDescription(e.target.value)}
                    />
                </Box>
                <Box className="w-100 d-flex justify-content-between align-items-center">
                    <Button
                        variant="contained"
                        disabled={loading}
                        className="mt-3"
                        onClick={handleSubmitReport}
                        sx={{ flex: 1, marginRight: '10px' }} // Use flex: 1 to make it take equal width, and marginRight for spacing
                    >
                        {loading ? <CircularProgress size={24} /> : <FormattedMessage id="Submit report" defaultMessage="Submit report" />}
                    </Button>
                    <Button
                        variant="contained"
                        disabled={loading}
                        className="mt-3"
                        onClick={handleCloseModal}
                        sx={{ flex: 1, marginLeft: '10px' }} // Use flex: 1 to make it take equal width, and marginLeft for spacing
                    >
                        {loading ? <CircularProgress size={24} /> : <FormattedMessage id="Close" defaultMessage="Close" />}
                    </Button>
                    </Box>

                {/* if result exist? */}
                {result && <Typography variant="p" className={`${classes.text} mt-3`}><FormattedMessage id={result} defaultMessage={result} /></Typography>}
                {error && <Typography variant="p" className={`${classes.errorText} mt-3`}><FormattedMessage id={error} defaultMessage={error} /></Typography>}
            </Box>
        </Modal>
    </div>
  );
};

export default NotWorking;