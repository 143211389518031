import { GET_ARTICLE, GET_ARTICLES_BY_CATEGORY, GET_ARTICLES_BY_CATEGORY_ERROR, GET_ARTICLES, GET_ARTICLES_ERROR, GET_ARTICLE_ERROR } from "../constants/actionTypes.js";

const initialState = {
    data: [],
    loading: false,
    error: null,
}

export default (articles = initialState, action) => {
    switch (action.type) {
        case GET_ARTICLES:
            return {data: action?.payload, loading: false, error: null};
        case GET_ARTICLES_BY_CATEGORY:
            return {data: action?.payload, loading: false, error: null};
        case GET_ARTICLE:
            return {data: action?.payload, loading: false, error: null};
        case GET_ARTICLES_ERROR:
            return {data: [], loading: false, error: action?.payload};
        case GET_ARTICLES_BY_CATEGORY_ERROR:
            return {data: [], loading: false, error: action?.payload};
        case GET_ARTICLE_ERROR:
            return {data: {}, loading: false, error: action?.payload};
        default:
            return articles;
    }
}