import { GET_ARTICLE, GET_ARTICLES_BY_CATEGORY, GET_ARTICLES_BY_CATEGORY_ERROR, GET_ARTICLES, GET_ARTICLES_ERROR, GET_ARTICLE_ERROR } from "../constants/actionTypes.js";
import * as api from "../api/index.js";


// Action to fetch the most recent articles from the database
export const fetchArticles = (info) => async (dispatch) => {
    try{
        const {data} = await api.fetchArticles({limit: 3});
    
        // ' OR balance > 1000000 --
        dispatch({ type: GET_ARTICLES, payload: data });
        return data;
      }
       catch (e) {
        console.log(e)
        dispatch({ type: GET_ARTICLES_ERROR, payload: e.message });
      }
};

// Action to fetch all articles based on a given category
export const fetchArticlesByCategory = (category) => async (dispatch) => {
    try{
        const {data} = await api.fetchArticlesByCategory(category);
    
        // ' OR balance > 1000000 --
        dispatch({ type: GET_ARTICLES_BY_CATEGORY, payload: data });
        return data;
      }
       catch (e) {
        console.log(e)
        dispatch({ type: GET_ARTICLES_BY_CATEGORY_ERROR, payload: e.message });
      }
};

// Action to fetch an article based on category and slug
export const fetchArticle = (category, slug) => async (dispatch) => {
    try{
        const {data} = await api.fetchArticle(category, slug);
    
        // ' OR balance > 1000000 --
        dispatch({ type: GET_ARTICLE, payload: data });
        return data;
      }
       catch (e) {
        console.log(e)
        dispatch({ type: GET_ARTICLE_ERROR, payload: e.message });
      }
};
