import { AUTH, SEND_VERIFICATION_CODE, LOGOUT, NOT_AUTH, AUTH_ERROR } from "../constants/actionTypes.js";

export default (state = null, action) => {
  switch (action.type) {
    case AUTH:
      if (Object.keys(action?.data).length) {
        localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
      }
      return action?.data;
    case NOT_AUTH:
      if (Object.keys(action?.data).length) {
        localStorage.clear();
      }
      return null;
    case AUTH_ERROR:
      if (Object.keys(action?.data).length) {
        localStorage.clear();
      }
      return null;
    case LOGOUT:
      localStorage.clear();
      return null;
    default:
      if(localStorage.getItem("profile")){
        return JSON.parse(localStorage.getItem("profile"));
      }
      return state;
  }
};
