// src/LoginPage.js
import React, { useContext, useDebugValue, useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {bankScenarioAuth} from "../../../../actions/challenge.js";
 
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { SolutionsContext } from '../../../../contexts/SolutionsContext.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const LoginPage = (props) => { 

  const {title}  = props;

  const {solutions, setSolutions} = useContext(SolutionsContext);

  const {activeTab, setSession, session, setUrl, url, params, selectedBrowser} = useContext(ChallengeDataContext);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  useEffect(() => {
    console.log('react sucks', activeTab)
  },[activeTab])

  useEffect(() => {
    console.log('react sucks', isAuthenticated)
    if (isAuthenticated) {
      // Redirect or perform actions for an authenticated user
      console.log('User is already authenticated');
      // For example, you can redirect to another page:
      // history.push('/dashboard');
    }
  },[isAuthenticated]);

  const setUpCookies = (data) => {
    // set cookies to expire in the future
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now

    // check if the cookie has session_id and scenario before setting them

    if(!document.cookie.includes('scenario='))
    {
      // console.log(document.cookie,document.cookie.includes('scenario=') )
      // document.cookie = `scenario=${data.scenario};  expires=${expirationDate.toUTCString()}; path=/`;
    }
    if (!document.cookie.includes('session_id='))
    {
      // console.log(document.cookie, document.cookie.includes('session_id='))
      // document.cookie = `session_id=${data.auth.sessionId};  expires=${expirationDate.toUTCString()}; path=/`;
    }

  }

  useEffect(() => {
    console.log(params)

    if (!params) return;

    if(params.sid && url.includes("www.bank.com")){

      dispatch(bankScenarioAuth({scenario: activeTab, browser: selectedBrowser, url: url})).then(data=>{
        setLoginLoading(false);
        console.log(data)
        if(data){
          if(data.error){
            setError(data.error)
          }
          if (data.auth) {
            // setSession
            console.log('hi',{...data.auth, status: true})
            // set cookies for scenario and session cookie
            setUpCookies(data);
            setSession({...data.auth, status: true})
            // setUrl(data.auth);
            console.log('Data submitted successfully');
          } else {
            console.error('Error submitting data');
          }
        }
      });

    }

  }, [params, url]);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [level, setLevel] = useState(1);
  const [loginLoading, setLoginLoading] = useState();

  const dispatch = useDispatch();

  const theme = useTheme();
  const classes = useStyles(theme);

  useEffect(()=>{
 
    if (!solutions) return;
    
    const loginAnswers = solutions.login ?? solutions.register;
    // console.log(solutions)
    if(!loginAnswers) return;

      setUsername(loginAnswers ? (loginAnswers.username || "") : "");
      setPassword(loginAnswers ? (loginAnswers.password || "") : "");
  }, [solutions])


  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoginLoading(true);
    setError(null)

    try {
      // Perform AJAX request here

      console.log(session)

      dispatch(bankScenarioAuth({username,password,level, scenario: activeTab, browser: selectedBrowser, url: url})).then(data=>{
        // console.log(data)
        setLoginLoading(false);
        console.log(data)
        if(data){
          if(data.error){
            setError(data.error)
          }
          if (data.auth) {
            // setSession
            console.log('hi',{...data.auth, status: true})
            setUpCookies(data);
            setSession({...data.auth, status: true})
            // setUrl(data.auth);
            console.log('Data submitted successfully');
          } else {
            console.error('Error submitting data');
          }
        }
      });

      // Handle response
    } catch (error) {
      console.error('AJAX error: ', error);
    }
  };

    return (
      <Container maxWidth="sm" className="d-flex justify-content-center h-100 flex-column align-items-center">
      <h3 className={classes.text}>{title}</h3>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Username"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button type="submit" variant="contained" color="primary">
          Login
        </Button>
        {error && 
          <Typography className='mt-2'>{error}</Typography>
        }
        {loginLoading && 
          <Typography className='mt-2'>
            <CircularProgress />
          </Typography>
          }
      </form>
    </Container>
    );
};

export default LoginPage;