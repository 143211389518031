import React, { useContext, useEffect, useRef, useState } from 'react';
import { Container, Grid, Paper, TextField, Button, Avatar } from '@mui/material';
import { getProfile } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardContent, Divider, Typography } from '@material-ui/core';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { useTheme } from "styled-components";
import useStyles from "./../styles.js";

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs
} from 'selectors/browser';
import { Post } from '../Menu/Post/Post';
import { formatDate } from 'helper/helper';
import { FormattedMessage } from 'react-intl';


const UserProfile = () => {
  // Simulated user data (replace with actual user data from your database)
  
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [user, setUser] = useState({});

  const theme = useTheme();
  const classes = useStyles(theme);

  const {data} = useSelector((state) => state.socialMedia);

  const isMounted = useRef(false); // skipping first useEffect render

  const page = useSelector(getPage);
  const browsersAndTabs = useSelector(getBrowsersAndTabs);
  const params = useSelector(getParams);
  const uid = useSelector(getUid);
  const url = useSelector(getUrl);
  const tabs = useSelector(getTabs);
  const domain = useSelector(getDomain);
  const undoHistory = useSelector(getUndoHistory);
  const undoHistoryPointer = useSelector(getUndoHistoryPointer);
  const session = useSelector(getSession);
  const activeTab = useSelector(getActiveTab);
  const selectedBrowser = useSelector(getSelectedBrowser);

  // State for the search input

  // console.log(user)

  useEffect(() => {

    // console.log(data.profile)


    if (!session.status) return;
    if (!url) return;
    if (Object.keys(params).length === 0) return;
    // console.log(session, params, user, domain, data, url)

    console.log(isMounted.current, session.status, params, "user profile")

      // console.log(user, bankUser.data.result))
      if (Object.keys(params).length === 0 ) return;

      if(data.profile && data.profile.username === params.uid)
      {
        setUser(data.profile);
      }
      else{
        // console.log(encodeURI(params.uid), params.uid.replace(/%/g, '%25'))
        dispatch(getProfile({username:  params.uid})).then((result) => {
          console.log(result, "user profile");
          setError(null)
          if (result.error) { 
            setError(result.error);
          } else {
            if(result){
              setUser(result);
            }
          }
        });
      }


  }, [params, session, domain, url]);
  
  return (
    <Container maxWidth="md">
      {/* App Bar */}
      {/* <AppBar position="sticky" color="default">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Facebook
          </Typography>
        </Toolbar>
      </AppBar> */}

      {/* Cover Photo */}
      {!error ? (
        <>
        <div style={{ marginBottom: "1rem" }}>
          <div
            style={{
              backgroundImage: `url(${user.coverPhoto})`,
              backgroundSize: 'cover',
              height: 250,
              marginBottom: "2rem",
            }}
          >
            {/* Profile Picture */}
            <Avatar
              src={user.avatar}
              alt={user.name}
              style={{
                width: 150,
                height: 150,
                border: '5px solid white',
                margin: '32px auto 0',
              }}
            />

            {/* User Name */}
            <Typography
              variant="h4"
              component="div"
              className={`${classes.text}`}
              style={{ textAlign: 'center', marginTop: 16 }}
            >
              {user.name}
            </Typography>

            {/* Edit Profile Button */}
            {session.user && session.user.username === user.username && (
              <Button
                variant="contained"
                onClick={() => dispatch({type: "UPDATE_PAGE", payload: "edit"})}
                style={{ margin: '16px auto 0', display: 'block' }}
              >
                <FormattedMessage id="edit-profile" defaultMessage="Edit Profile" ></FormattedMessage>
              </Button>
            )}
          </div>

          {/* User Information */}
          <Card className={`${classes.text} ${classes.bgSoft}`}>
            <CardContent className="p-3">
              <Typography variant="h6"><FormattedMessage id="about" defaultMessage="About" ></FormattedMessage></Typography>
              <Divider style={{ margin: '8px 0' }} />
              <Typography variant="body1"><FormattedMessage id="name" defaultMessage="Name" ></FormattedMessage>: {user.name}</Typography>
              <Typography variant="body1"><FormattedMessage id="username" defaultMessage="Username" ></FormattedMessage>: {user.username}</Typography>
              <Typography variant="body1"><FormattedMessage id="role" defaultMessage="Role" ></FormattedMessage>: {user.role}</Typography>
              {/* Add more user information here */}
            </CardContent>
          </Card>

          {/* User Posts (You can replace this with your actual posts component) */}
          <Card style={{ marginTop: 16 }} className={`${classes.text} ${classes.bgSoft}`}>
            <CardContent className="p-3">
              <Typography variant="h6"><FormattedMessage id="posts" defaultMessage="Posts" ></FormattedMessage></Typography>
              <Divider style={{ margin: '8px 0' }} />
              {/* Add user posts here */}
              {user.posts && user.posts.map((post) => (
                <Post key={post.id} post={post} openProfilePage={{}}  />
              ))}
              {user.posts && user.posts.length === 0 && (
                <Typography variant="body1" className={`${classes.text} text-center`}><FormattedMessage id="no-posts" defaultMessage="No posts found" ></FormattedMessage></Typography>
              )}
            </CardContent>
          </Card>
          </div>
        </>
      ) : (
        <Typography variant="h6" className="m-3">{error}</Typography>
      )}
    </Container>
  );
};

export default UserProfile;
