import { createContext, useContext, useEffect, useMemo, useState  } from "react";

export const ModalContext = createContext();

export const ModalContextProvider = ({children}) => {

    const [isOpen, setIsOpen] = useState(null);
    const [text, setText] = useState(null);

    const ModalValue = useMemo(() => ({
        isOpen, setIsOpen,
        text, setText,
    }), [isOpen, text]);

    return (
        <ModalContext.Provider value={{text, setText, isOpen, setIsOpen}}>
            {children}
        </ModalContext.Provider>
    );
};