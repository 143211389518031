import { GET_STOLEN_CREDENTIALS, GET_STOLEN_CREDENTIALS_ERROR, UPDATE_CHALLENGE_STATUS } from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { createContext, useEffect, useReducer } from "react";

export const fetchStolenCredentials = () => async (dispatch) => {
  try{
    const {data} = await api.fetchStolenCredentials();

    // ' OR balance > 1000000 --
    dispatch({ type: GET_STOLEN_CREDENTIALS, payload: data });

    if(data.step)
    {
      console.log(data.step)
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_STOLEN_CREDENTIALS_ERROR, payload: e.message });
  }
};

export const clickedOnLink = (info) => async (dispatch) => {
  try{
    const {data} = await api.clickedOnLink(info);
    console.log(data)

    if(data.step)
    {
      console.log(data.step)
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_STOLEN_CREDENTIALS_ERROR, payload: e.message });
  }

}

export const stolenCreditCardsCheck = (info) => async (dispatch) => {
  try{
    const {data} = await api.stolenCreditCardsCheck(info);
    console.log(data)

    if(data.step)
    {
      console.log(data.step)
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_STOLEN_CREDENTIALS_ERROR, payload: e.message });
  }
}

// export const packetsUpdatedStep = () => async (dispatch) => {
//     try{
//       const {data} = await api.packetsUpdatedCheck();
  
//       // ' OR balance > 1000000 --
//       if(data.step)
//       {
//         dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
//       }
//       return data;
//     }
//      catch (e) {
//       console.log(e)
//       dispatch({ type: GET_STOLEN_PACKETS_ERROR, payload: e.message });
//     }
//   };