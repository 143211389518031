import { CircularProgress, Container } from "@material-ui/core";
import AttackerScenario from "../Scenarios/AttackerScenario/AttackerScenario";
import PacketSnifferScenario from "../Scenarios/PacketSnifferScenario/PacketSnifferScenario";
import PhishingScenario from "../Scenarios/PhishingScenario/PhishingScenario";
import BankScenario from "../Scenarios/BankScenario/BankScenario";
import ECommerceScenario from "../Scenarios/ECommerceScenario/ECommerceScenario";
import EmailsScenario from "../Scenarios/EmailsScenario /EmailsScenario";
import SocialProfileScenario from "../Scenarios/SocialProfileScenario/SocialProfileScenario";
import { useContext, useEffect, useMemo, useState } from "react";
import { ChallengeDataContext } from "contexts/ChallengeData";
import { useDispatch, useSelector } from "react-redux";
import { pageIsAuth, scenarioAuth } from "actions/challenge";
import { receiveFromParent, syncStates } from 'actions/helper.js';

import {
  getPage,
  getBrowsersAndTabs,
  getUser,
  getParams,
  getUid,
  getUrl,
  getDomain,
  getUndoHistory,
  getUndoHistoryPointer,
  getSession,
  getActiveTab,
  getSelectedBrowser,
  getTabs,
  getRestart,
  getAllUrls, getBrowsers, getChallengeData, getScriptValid
} from "selectors/browser";
import { getParameters } from "helper/helper";
import { useParams } from "react-router-dom";

import { DarkMode } from "../../../contexts/DarkMode.js";

const ChallengeLevel = (props) => {

    // get challenge from url (challenge1, challenge2 etc)

    const dispatch = useDispatch();

    const { isDarkMode, setIsDarkMode } = useContext(DarkMode);

    const queryParams = new URLSearchParams(window.location.search);
    const activeTab = queryParams.get('active') == "null" || queryParams.get('active') == "undefined" ? null : queryParams.get('active');
    const selectedBrowser = queryParams.get('browser') == "null" || queryParams.get('browser') == "undefined" ? null : queryParams.get('browser');

    const page = useSelector(getPage);
    const browsersAndTabs = useSelector(getBrowsersAndTabs);
    const user = useSelector(getUser);
    const params = useSelector(getParams);
    const uid = useSelector(getUid);
    const url = useSelector(getUrl);
    const tabs = useSelector(getTabs);
    const domain = useSelector(getDomain);
    const undoHistory = useSelector(getUndoHistory);
    const undoHistoryPointer = useSelector(getUndoHistoryPointer);
    const session = useSelector(getSession);
    const restart = useSelector(getRestart);
    const browsers = useSelector(getBrowsers);
    const allUrls = useSelector(getAllUrls);
    const scriptValid = useSelector(getScriptValid);

    const challengeData = useSelector((state) => state.challenge);

    const state = useSelector((state) => state);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {

        // get the state which changed from the list of states
        // send the state to the parent window

          var eventMethod = window.addEventListener ? "addEventListener" : "attachEvent";
          var eventer = window[eventMethod];
          var messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";
      
          // Listen to message from parent window
          eventer(messageEvent,function(e) {
              var key = e.message ? "message" : "data";
              var data = e[key];
      
              // console.log("syncing states before", data["state"], url)
              if(data["state"]){ // syncing redux states
                console.log("syncing states url", data["state"], url)
                dispatch(syncStates(data["state"])).then(data => {
                  // console.log("updated state in iframe", data)
                });
              }
              if(data["theme"]){
                console.log("syncing theme", data["theme"]);
                localStorage.setItem('darkMode', data["theme"]);
                setIsDarkMode(data["theme"] === "dark" ? true : false);
              }
              if(data["locale"]){
                console.log("syncing language", data["locale"]);
                localStorage.setItem('locale', data["locale"]);
              }
              
              //run function//
          },false);
        
        window.parent.postMessage({
            session: session,
            page: page,
            challengeData: challengeData,
            browsersAndTabs: browsersAndTabs,
            user: user,
            params: params,
            tabs: tabs,
            activeTab: activeTab,
            uid: uid,
            selectedBrowser: selectedBrowser,
            browsers: browsers,
            url: url,
            allUrls: allUrls,
            domain: domain,
            restart: restart,
            undoHistory: undoHistory,
            undoHistoryPointer: undoHistoryPointer,
            scriptValid: scriptValid,
        }, "*");  //  `*` on any domain   

    }, [activeTab, selectedBrowser]);


    const [fetchSessionDone, setFetchSessionDone] = useState(false);
    const { challenge } = useParams()

    async function fetchParams(url) {
        const urlParams = await getParameters(url);
        dispatch({ type: 'UPDATE_PARAMS', payload: urlParams });
    }

    // const fetchSession = async (page, syncNewPage = true) => {
    //     try {
    //       // const response = await axios.get('http://localhost:5000/api/session', {
    //       //   withCredentials: true, // Send cookies along with the request
    //       // });
  
    //       // console.log(selectedBrowser, activeTab, session, selectedBrowser)
  
    //       if(session)
    //       {
    //         if(session.sessionId)
    //         {
    //           const expirationDate = new Date();
    //           expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
    //           if(!document.cookie.includes('session_id='))
    //           {
    //             // document.cookie = `session_id=${session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
    //           }
    //         }
    //       }
  
    //       let cookieId = null;
    //       if(challenge == "challenge7"){
    //         if(document.cookie.includes('session_id='))
    //         {
    //           cookieId = document.cookie
    //             .split("; ")
    //             .find((row) => row.startsWith("session_id="))
    //             ?.split("=")[1];
    //         }
    //       }

    //       let pageUrl = await pageUrlVal(url, activeTab);  // Async call

    //       // get the sid= parameter from the url
    //       const urlParams = await getParameters(pageUrl);

          
    //       // getSession server
    //       dispatch(scenarioAuth({scenario: activeTab, browser: selectedBrowser, page: page, session_id: cookieId || urlParams.sid})).then(data => {
            
    //         // get authneticated user forom local storage
            
    //         // console.log(data, "scenario auth data fetch session")
    //         console.log(cookieId, document.cookie, urlParams, pageUrl, data, "cookieId fetch session")
  
    //         if(data.requiresLogin)
    //         {
    //           let dataSessionExists = false;
    //           if(data)
    //           {
  
    //             // data session exists
    //             if(data.session){
  
    //               dispatch({ type: 'UPDATE_SESSION', payload: data.session });
    //               // if(data.isAuthenticated === false){
    //               //   dataSessionExists = false;
    //               // }
  
    //               // if(data.session.status){
    //               //     dataSessionExists = true;
    //               // }
    //               // else{
    //               //     dataSessionExists = false;
    //               // }
    //             }
    //             // else{
    //             //   dataSessionExists = false;
    //             // }
  
    //           }
  
    //           // const expirationDate = new Date();
    //           // expirationDate.setDate(expirationDate.getDate() + 7); // Set the expiration date to 7 days from now
  
    //           // if(dataSessionExists){
    //           //   document.cookie = `${selectedBrowser}_${activeTab}=${data.session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
    //           //   document.cookie = `session_id=${data.session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
    //           //   if(!url){
    //           //     dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com`});
    //           //   }
    //           // }
    //           // else{
    //           //   document.cookie = `${selectedBrowser}_${activeTab}=; path=/;`;
    //           //   document.cookie = 'session_id=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    //           //   dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com/login`});
    //           // }
    //         }
    //         else{
    //           // dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com`});
    //         }

    //         if(syncNewPage){
    //           setFetchSessionDone(true);
    //         }
              
    //       });
  
    //       // Assuming the response has a property isAuthenticated
    //       // dispatch(setAuthenticated(response.data.isAuthenticated));
    //     } catch (error) {
    //       console.error('Error fetching session:', error);
    //     }
    // };

    const pageUrlVal = async (url, activeTab) => {
      let pageUrl;
      if(url){
        pageUrl = url;
      }
      else{
        let urlFromInput = window.parent?.document?.getElementsByClassName("browser-url-input")[0]?.getElementsByTagName("input")[0]?.value;
        if(urlFromInput.split(".")[1] === activeTab){
          pageUrl = urlFromInput;
        }
      }

      return pageUrl;
    }

    async function handlePageUpdate() {
      // if (!fetchSessionDone) return;
  
      let action = {};
      let newPage = page;
  
      let pageUrl = await pageUrlVal(url, activeTab);  // Async call
  
      if (!pageUrl) {
        pageUrl = `https://www.${activeTab}.com`;
      }

      if(url.includes("https://www.bestsocial.com/profile?uid=")){
        action = { type: 'UPDATE_PAGE', payload: "profile" };
        newPage = "profile";
      }
      else if(url.includes("https://www.bestsocial.com/profile/edit?uid=")){
          action = { type: 'UPDATE_PAGE', payload: "edit" };
          newPage = "edit";
      }
      else if(url == "https://www.bestsocial.com" || url == "https://www.bestsocial.com/" || url == "https://www.flopify.com" || url.includes("https://www.flopify.com?")){
          action = { type: 'UPDATE_PAGE', payload: "menu" };
          newPage = "menu";
      }
      else if(url.includes("bestsocial.com/users")){
          action = { type: 'UPDATE_PAGE', payload: "users" };
          newPage = "users";
      }
      else if(url.includes("bestsocial.com/admin")){
          action = { type: 'UPDATE_PAGE', payload: "admin" };
          newPage = "admin";
      }
      else if(url.includes("flopify.com/product?slug=")){
          action = { type: 'UPDATE_PAGE', payload: "product" };
          newPage = "product";
      }
      else if(url.includes("flopify.com/payment?slug=")){
          action = { type: 'UPDATE_PAGE', payload: "payment" };
          newPage = "payment";
      }
      else if(url.includes("flopify.com/thankyou")){
          action = { type: 'UPDATE_PAGE', payload: "thankyou" };
          newPage = "thankyou";
      } 
      else if(url.includes("flopify.com")){
          action = { type: 'UPDATE_PAGE', payload: "main" };
          newPage = "menu";
      } 
      else if(url.includes("/login")){
          action = {type: "UPDATE_PAGE", payload: "login"};
          newPage = "login";
      }
      else if(url.includes("https://www.bank.")){

        if (pageUrl === "https://www.bank.com" || pageUrl === "https://www.bank.com/" || pageUrl.includes("https://www.bank.com?")) {
          action = { type: 'UPDATE_PAGE', payload: "menu" };
          newPage = "menu";
        } else if (pageUrl.includes("/login")) {
          action = { type: 'UPDATE_PAGE', payload: "login" };
          newPage = "login";
        } else if (pageUrl.includes("/savings")) {
          action = { type: 'UPDATE_PAGE', payload: "savings" };
          newPage = "savings";
        } else if (pageUrl.includes("/transactions")) {
          action = { type: 'UPDATE_PAGE', payload: "transactions" };
          newPage = "transactions";
        } else if (pageUrl.includes("/transaction")) {
          action = { type: 'UPDATE_PAGE', payload: "transaction" };
          newPage = "transaction";
        } else {
          action = { type: 'UPDATE_PAGE', payload: activeTab };
          newPage = activeTab;
        }
          // accessing mainly the scam pages:
          // https://www.bank.change-your-password.com
          // https://www.bank.verify-your-account.com
      }
      else {
        action = { type: 'UPDATE_PAGE', payload: activeTab };
        newPage = activeTab;
      }
  
      if (action.type && action.payload) {
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 7);
  
        // Extract the domain between www. and .com
        let domain = pageUrl.split("www.")[1].split(".com")[0];
  
  
        try {

          let cookieId = null;
          if(challenge == "challenge7"){
            if(document.cookie.includes('session_id='))
            {
              cookieId = document.cookie
                .split("; ")
                .find((row) => row.startsWith("session_id="))
                ?.split("=")[1];
            }
          }

          let pageUrl = await pageUrlVal(url, activeTab);  // Async call

          

          // get sid parameter from the url

          const urlParams = await getParameters(pageUrl);

          // get the sid= parameter from the url
          const data = await dispatch(pageIsAuth({ url: pageUrl, page: newPage, browser: selectedBrowser, scenario: activeTab, session_id: cookieId || urlParams.sid }));

          console.log(data, pageUrl, activeTab, selectedBrowser, "page is auth")

          setIsLoading(false);

          dispatch({ type: 'UPDATE_PARAMS', payload: params });
          if(data.session.session){
            dispatch({ type: 'UPDATE_SESSION', payload: data.session.session });
          }
  
          // take the parameter and value and convert them into url parameters starting with ? and separated by &
          
          let urlParamsString = Object.keys(params).map((key) => `${key}=${params[key]}`).join('&');
          if(!urlParamsString){
            urlParamsString = "";
          }
          else{
            urlParamsString = "?" + urlParamsString;
          }
  
          if (data) {
            if (data.accessible === false) {
  
              // only add the urlarameters if the url is login
              pageUrl = `https://www.${domain}.com/login`;
              if(pageUrl.includes("/login"))
              {
                pageUrl = `https://www.${domain}.com/login${urlParamsString}`;
              }

              dispatch({ type: "UPDATE_URL", payload: pageUrl });
              dispatch({ type: "UPDATE_PAGE", payload: "login" });
  
              document.cookie = `${selectedBrowser}_${activeTab}=; path=/;`;
  
            } else if (data.accessible === true) {
              if(data.session.sessionId){
                document.cookie = `${selectedBrowser}_${activeTab}=${data.session.sessionId}; expires=${expirationDate.toUTCString()}; path=/;`;
              }
  
              dispatch(action);
              if (!pageUrl) {
                dispatch({ type: "UPDATE_URL", payload: `https://www.${activeTab}.com` });
                pageUrl = `https://www.${activeTab}.com`;
              } else {
                dispatch({ type: "UPDATE_URL", payload: pageUrl });
              }
            }
          }
  
          fetchParams(pageUrl);
        } catch (error) {
          console.error('Error during page authorization check:', error);
        }
      }
    }

    useEffect(() => {
      // Define an asynchronous function inside the effect
      setIsLoading(true);

      if(!activeTab) return;
      if(!selectedBrowser) return;
    
      // Call the async function
      
      handlePageUpdate();
      
    }, [url]);  // Depend on url and fetchSessionDone
    

    // useEffect(() => {
    //   if(!activeTab) return;
    //   if(!selectedBrowser) return;

    //   handlePageUpdate(page, false);
    // }, [url])


    useEffect(() => {

        if(!activeTab) return;
        if(!selectedBrowser) return;

        dispatch({ type: 'UPDATE_ACTIVE_TAB', payload: activeTab });
        dispatch({ type: 'UPDATE_SELECTED_BROWSER', payload: selectedBrowser });
        dispatch({ type: 'UPDATE_DOMAIN', payload: `https://www.${activeTab}.com` });

        // console.log(activeTab, selectedBrowser, "activeTab, selectedBrowser")

    }, [dispatch, activeTab, selectedBrowser]);

    // useEffect(() => {

    //   if (!url) return;

    //   // console.log()

    //   // link can be visited

    //   async function fetchParams() {
    //     const urlParams = await getParameters(url);
    //     dispatch({ type: 'UPDATE_PARAMS', payload: urlParams });
    //   }

    //   dispatch(pageIsAuth({url: url, page: page})).then(data => {
    //     console.log(data, "page is auth")
    //     if(data === false)
    //     {
    //       dispatch({type: "UPDATE_URL", payload: `https://www.${activeTab}.com/login`});
    //       dispatch({type: "UPDATE_PAGE", payload: "login"});
    //     }
    //     else if(data === true)
    //     {
    //       fetchParams();
    //     }
    //   });
      
    // },[url])

    

    // store state in the local storage. State will include information such as: browser user, active tab, page, etc.
    // this will allow the user to go through the challenge and a way whre teh iframe communicates with the parent window
    // to update the state of the challenge

    return (
    <Container maxWidth="100" className="position-relative p-0 w-100 d-flex justify-content-center align-items-center" style={{ minHeight: "100vh" }}>
            {!isLoading ? <>
              {activeTab === "attacker" ? <AttackerScenario></AttackerScenario> : <></>}
              {activeTab === "packetsniffer" ? <PacketSnifferScenario></PacketSnifferScenario> : <></>}
              {activeTab === "phishing" ? <PhishingScenario></PhishingScenario> : <></>}
              {activeTab === "stolencreditcards" ? <PhishingScenario></PhishingScenario> : <></>}
              {activeTab === "bank" ? <BankScenario></BankScenario> : <></>}
              {activeTab === "flopify" ? <ECommerceScenario></ECommerceScenario> : <></>}
              {activeTab === "bazoommail" ? <EmailsScenario></EmailsScenario> : <></>}
              {activeTab === "bestsocial" ? <SocialProfileScenario></SocialProfileScenario> : <></>}
              {(!activeTab) && 
                <Container maxWidth="lg" className="p-0 d-flex justify-content-center align-items-center">
                  <CircularProgress />
                </Container>
                    }
            </> : null }
        </Container>
    );
}

export default ChallengeLevel;