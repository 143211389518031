export const light = {
    textColor: "#000",
    darkModeSwitch: "#000",
    bgSoft: "#f6f3f3",
    logo: "rgba(0,183,255, 1)",
    bg: "#fff",
    bgHover: "#dedede",
    textColorSoft: "#555",
    border: "#D3D3D3",
    overlay: "rgba(255,255,255,0.25)",
    
}

export const dark = {
    textColor: "#f5f5f5",
    darkModeSwitch: "#ebab34",
    bgSoft: "#222",
    logo: "#fff",
    bg: "#333",
    bgHover: "#404040",
    textColorSoft: "#D3D3D3",
    border: "#444",
    overlay: "rgba(0,0,0,0.5)"
}