import React, { useContext } from 'react';
import { Container, Typography, Grid, Button, Card, CardContent } from '@mui/material';

import { useTheme } from "styled-components";
import useStyles from "../styles.js";
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const Menu = () => {

    const { page, setPage } = useContext(ChallengeDataContext);

    const theme = useTheme();
    const classes = useStyles(theme);
  

    return (
        <Container maxWidth="lg" className='d-flex h-100 flex-column align-items-center justify-content-center'>
            <Typography className={classes.text} variant="h4" align="center" style={{ margin: '20px' }}>
                Welcome to Your Online Banking Dashboard
            </Typography>
            <Grid container spacing={2} >
                <Grid item xs={4}>
                    <Card>
                        <CardContent className={classes.bg}>
                            <Button variant="contained" fullWidth color="primary" onClick={() => setPage("savings")}>
                                Savings Account
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent className={classes.bg}>
                        <Button variant="contained" fullWidth color="primary" onClick={() => setPage("transaction")}>
                            Transfer Money
                        </Button>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardContent className={classes.bg}>
                        <Button variant="contained" fullWidth color="primary" onClick={() => setPage("transactions")}>
                            Transactions
                        </Button>
                        </CardContent>
                    </Card>
                </Grid>
                {/* Add more buttons/cards here */}
            </Grid>
        </Container>
    );
};

export default Menu;