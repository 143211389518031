import React, { useContext, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { ModalContext } from '../../contexts/ModalContext';
import { Box, Typography, IconButton, Paper } from '@mui/material';
import useStyles from './styles';
import { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import copy from 'clipboard-copy';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { FormattedMessage } from 'react-intl';

const ChallengeSnippets = (props) => {
  const { isOpen, setIsOpen } = useContext(ModalContext);
  const { content } = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  // Create an array of refs for each code snippet
  const codeSnippetRefs = useRef([]);
  codeSnippetRefs.current = content ? content.map((_, i) => codeSnippetRefs.current[i] || React.createRef()) : [];

  const [copiedIndex, setCopiedIndex] = useState(null);

  const copyToClipboard = (index) => {
    const snippetText = codeSnippetRefs.current[index].current.innerText;
    copy(snippetText);
    setCopiedIndex(index);
    setTimeout(() => setCopiedIndex(null), 2000); // Reset the copied state after 2 seconds
  };

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  return (
    <div>
      <Modal
        open={isOpen === 'snippets'}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className={classes.modalContainer}
      >
        <Box className={classes.box}>
          <Typography variant="h4" id="modal-title" className={classes.textSnippets}>
            <FormattedMessage id="Snippets" defaultMessage="Snippets" />
          </Typography>
          {/* Container for content with overflow scroll */}
          <Box className={classes.contentContainer}>
            {content && content.length > 0 ? (
              content.map((item, index) => (
                <div key={index}>
                  <Typography variant="h6" id="modal-title" className={classes.textSnippets}>
                    {item.title}
                  </Typography>
                  <Typography variant="body1" id="modal-description" className={classes.textSnippets}>
                    {item.description}
                  </Typography>
                  <div className={`${classes.textContentSnippets} rounded text-start`}>
                    <Typography variant="body2" component="pre" className={classes.textSnippets}>
                      <pre>
                        <code ref={codeSnippetRefs.current[index]}>{item.snippet}</code>
                      </pre>
                    </Typography>
                    <IconButton
                      color="primary"
                      onClick={() => copyToClipboard(index)}
                      disabled={copiedIndex === index}
                      className={`${classes.text}`}
                    >
                      {copiedIndex === index ? <CheckCircleIcon className={classes.text} /> : <FileCopyIcon className={classes.text} />}
                    </IconButton>
                    {copiedIndex === index && ' Copied!'}
                  </div>
                </div>
              ))
            ) : (
              <Typography variant="body1" id="modal-title" className={`${classes.textSnippets} text-center`}>
                <FormattedMessage id="No snippets available." defaultMessage="No snippets available." />
              </Typography>
            )}
          </Box>
          <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
            <FormattedMessage id="thank-you" defaultMessage="Thank You" />
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default ChallengeSnippets;
