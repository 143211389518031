// src/LoginPage.js
import React, { useContext, useDebugValue, useEffect, useState } from 'react';
import { Container, TextField, Button, InputAdornment } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {BankAuthContext, bankScenarioAuth, getTransfers, transfer} from "../../../../../actions/bankScenario.js";
import AccountCircleIcon from '@mui/icons-material/AccountCircle.js';
import SettingsIcon from '@mui/icons-material/Settings.js';
import ExitToAppIcon from '@mui/icons-material/ExitToApp.js';
 
import {logoutHandler} from "../../../Common/BankLogOut.js";

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { AppBar, Avatar, ButtonGroup, Card, CardContent, FormControl, Grid, IconButton, Input, List, ListItem, ListItemText, MenuItem, Paper, Select, Tab, Tabs, Toolbar, Typography } from '@material-ui/core';
import Menu from './Menu/Menu.js';
import Savings from './UserProfile/UserProfile.js';
import UserProfile from './UserProfile/UserProfile.js';
import Settings from './Settings/Settings.js';
import SearchIcon from '@mui/icons-material/Search';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RefreshIcon from '@mui/icons-material/Refresh';
import LockIcon from '@mui/icons-material/Lock';

import debounce from 'lodash/debounce'; // Import the debounce function
import { getProfile, getUsers } from 'actions/socialProfileScenario.js';
import ErrorPage from './Error/Error.js';
import AddIcon from '@mui/icons-material/Add';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';
import { useCookies } from "react-cookie";
import Admin from './Admin/Admin.js';
import Users from './Users/Users.js';

const Dashboard = (props) => { 

  const { page, setPage, session, user, setUser, url, setUrl, domain, setDomain, tabs, setTabs, params, activeTab, scriptValid } = useContext(ChallengeDataContext);

    const [transferAmount, setTransferAmount] = useState(null);
    const [transferTo, setTransferTo] = useState(null);
    const [error, setError] = useState(null)

    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searching, setSearching] = useState(false);
    const [searchTimeout, setSearchTimeout] = useState(null);

    const bankUser = useSelector((state) => state.bank);
    const { resetStates } = useContext(ChallengeDataContext);


    const theme = useTheme();
    const classes = useStyles(theme);
    const dispatch = useDispatch();

    const handleUserSearch = (query) => {
      // Simulate an API call to fetch search results based on the query
      // Replace this with your actual API call
      setSearching(true);
      dispatch(getUsers({query: query})).then(results =>{
        if(results.error)
        {
          setSearchResults([results.error]); // Update the search results
          setError(results.error)
        }
        else{
          setSearchResults(results);
          setError(null)
        }
        setSearching(false);
        // console.log(results)

      })
      .catch((error) => {
        console.error('Error fetching search results:', error);
        setSearchResults([error]);
        setError(error)
        setSearching(false);
      });


    }; // Delay search by 1 second (1000 milliseconds)

    const handleSearchChange = (event) => {
      const { value } = event.target;
      setSearchQuery(value);
      debounceUserSearch(value); // Trigger the debounced search after user input
    };

    // Function to debounce the user search
    const debounceUserSearch = (query) => {
      if(query === ""){
        setSearching(false);
        setSearchResults([]);
      }
      else
      {
        clearTimeout(searchTimeout);
        setSearchTimeout(setTimeout(() => {
          handleUserSearch(query);
        }, 500)); // Wait for 1 second of inactivity before firing the search
      }
    };

    useEffect(() => {
      if(!session) return;

      if(session.user.username === "admin"){
        setPage("admin");
      }

      // console.log(session.user.username)

    },[session])


    // const handleSubmit = async (e) => {
    //     e.preventDefault();

    //     try {
    //     // Perform AJAX request here


    //     dispatch(transfer({transferAmount,transferTo,fromId: bankUser.id})).then(data=>{
    //         console.log(data)
    //         if (data) {
    //             console.log('Data submitted successfully');
    //         } else {
    //             console.error('Error submitting data');
    //         }
    //     });

    //     // Handle response
    //     } catch (error) {
    //         console.error('AJAX error:', error);
    //     }
    // };

      
    // Function to handle user logout (not implemented here)
    const handleLogout = () => {
      // Implement logout logic here
      logoutHandler(dispatch, resetStates, activeTab);
    };

    const handleUserClick = (result) => {
      // Replace this with the function you want to call when a user is clicked
      setUser(result);
      setPage("profile");
      setSearchResults([]);
      // set url to user profile
      setUrl(domain+`/profile?uid=${result.username}`);
      // console.log(`User clicked: ${result}`);
    };

    const openProfilePage = () => {
      setUrl(domain+"/profile?uid="+session.user.username); 
      setPage("profile"); 
      setUser({}); 
      setSearchResults([]);
    }

    const openSettingsPage = () => {
      setUrl(domain+"/profile/edit?uid="+session.user.username);
      setPage("edit");
      setSearchResults([])
    }

    const {data} = useSelector((state) => state.socialMedia);
    const challengeInfo = useSelector((state) => state.challenge);

    useEffect(() => {

      if(!page)
      {
        // console.log(page)
        if(session.user.role === "admin"){
          setPage("admin");
        }
        else{
          setPage("menu");
        }
      }
      

    }, [activeTab, setPage]);

    const showUsersResults = (query) => {
      setPage("users");
      setUrl(domain+"/users?query="+encodeURIComponent(query));
      setSearchResults([]);
    }

    // useEffect(() => {
    //   console.log(activeTab, page, "dashboard")
    // }, [page]);

    // console.log(page, "hi")

    return (
    <Container maxWidth="lg" className='h-100 position-relative p-0'>
      <AppBar position="static">
        <Toolbar sx className="d-flex justify-content-between">
          <Typography variant="h6" component="div" onClick={() => setPage("menu")}>
            BestSocial
          </Typography>
          { session.user.role != "admin" ?
          (
            <>
              <div style={{ display: 'flex', alignItems: 'center', flex: 1, marginLeft: '20px', marginRight: '20px' }}>
                <TextField
                  label="Search User"
                  variant="outlined"
                  fullWidth
                  onChange={handleSearchChange}
                  className='m-2'
                  sx={{ '& input': { backgroundColor: 'white' } }} // Set the background color to white
                />
                {/* Add the IconButton for search */}
                <ButtonGroup variant="contained" aria-label="Navigation">
                  <Button color="primary" onClick={() => showUsersResults(searchQuery)}>
                    <SearchIcon />
                  </Button>
                </ButtonGroup>
              </div>
              <ButtonGroup variant="contained" aria-label="Navigation">
                <Button color="primary" onClick={() => openProfilePage()}>
                  <AccountCircleIcon />
                </Button>
                <Button color="primary" onClick={() => openSettingsPage()}>
                  <SettingsIcon />
                </Button>
                <Button onClick={handleLogout} variant="contained" color="secondary" style={{backgroundColor: "#f50057!important"}}>
                  Logout
                </Button>
              </ButtonGroup>
            </>
          )
        :
          (<>
              <ButtonGroup variant="contained" aria-label="Navigation">
                <Button onClick={handleLogout} variant="contained" color="secondary" style={{backgroundColor: "#f50057!important"}}>
                  Logout
                </Button>
              </ButtonGroup>
          </>)}
        </Toolbar>
      </AppBar>
      {!searching && searchResults.length > 0 && (
          <div
            style={{
              position: 'absolute',
              width: '100%',
              backgroundColor: 'white',
              boxShadow: '0px 3px 5px rgba(0,0,0,0.2)',
              zIndex: "100",
            }}
          >
            <List>
              {!error 
              ? (
                searchResults.map((result) => (
                  <div key={result.id} style={{ display: 'flex', alignItems: 'start', padding: "0.25rem" }}>
                    <div
                      onClick={() => handleUserClick(result)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '8px',
                        // backgroundColor: '#f0f0f0', // Background color for the "button"
                        cursor: 'pointer', // Cursor style to indicate it's clickable
                        width: "100%"
                      }}
                    >
                    <Avatar src={result.avatar} alt={result.name} />
                      <div style={{ marginLeft: 16, display: 'flex', alignItems: 'start', flexDirection: "column" }}>
                        <Typography variant="body1">{result.name}</Typography>
                        <Typography variant="body2">@{result.username}</Typography>
                      </div>
                    </div>
                  </div>
                )))
              : (
                searchResults.map((result) => (
                  <div key={result.id}>
                    <Typography variant="body1">{result}</Typography>
                  </div>
                ))
              )}
            </List>
          </div>
        )}

      {/* Search Bar with Suggestions Dropdown */}

        {page === "menu" ? <Menu ></Menu> : <></>}
        {page === "users" ? <Users ></Users> : <></>}
        {page === "profile" ? <UserProfile ></UserProfile> : <></>}
        {page === "edit" ? <Settings ></Settings> : <></>}
        {page === "error" ? <ErrorPage></ErrorPage> : <></>}
        {page === "admin" ? <Admin></Admin> : <></>}
      </Container>
    );
};

export default Dashboard;