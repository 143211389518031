// PostsTab.jsx
import React, { useEffect, useState } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import RenderRow from './RenderRow';
import PostModal from './PostModal';
import { useDispatch, useSelector } from 'react-redux';
import { getPosts, getUsers } from 'actions/socialProfileScenario';

const posts = [
  { id: 1, username: 'user1', content: 'Post content 1', likes: 10 },
  { id: 2, username: 'user2', content: 'Post content 2', likes: 20 },
  // Add more posts as needed
];

const PostsTab = ({content}) => {

    const [selectedRow, setSelectedRow] = useState(null);
    const [btnClicked, setBtnClicked] = useState(null);
    const [error, setError] = useState(null);

    const [dataList, setDataList] = useState([]);
    const {data} = useSelector((state) => state.socialMedia);

    const dispatch = useDispatch();

    useEffect(() => { 

        console.log(data, content)

        if (data[content].length === 0 )
        {
            if(content === "posts"){
                dispatch(getPosts()).then((result) => {
                    // console.log(result);
                    if (result.error) {
                        setError(result.error);
                    } else {
                        console.log(result)
                        setDataList(result);
                        setError(null)
                    }
                });
            }else if(content === "users"){
                dispatch(getUsers()).then((result) => {
                    // console.log(result);
                    if (result.error) {
                        setError(result.error);
                    } else {
                        console.log(result)
                        setDataList(result);
                        setError(null)
                    }
                });
            }
        }
        else{
            setDataList(data[content]);
        }
    }, [data, content]);

    return (
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        {dataList.length > 0 ? (
                            <>{Object.keys(dataList[0]).map((key) => (
                                <TableCell>{key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()}</TableCell>
                            ))}</>
                        ) : (
                            <></>
                        )
                    }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataList.map((post) => (
                        <RenderRow row={post}  setBtnClicked={setBtnClicked} setSelectedRow={setSelectedRow} />
                    ))}
                </TableBody>
            </Table>
            {btnClicked && selectedRow ? (
                <PostModal
                    open={btnClicked != null ? true : false}
                    btnClicked={btnClicked}
                    handleClose={() => setBtnClicked(null)}
                    row={selectedRow}
                    handleEdit={(editedPost) => {
                    // Implement the logic to save the edited post
                    console.log('Saving edited post:', editedPost);
                    }}
                    handleDelete={() => {
                    // Implement the logic to delete the post
                    console.log('Deleting post:', selectedRow);
                    setBtnClicked(null);
                }} />
            ) : <></>
            }
        </>
    );
};

export default PostsTab;
