import { combineReducers } from "redux";

import auth from "./auth.js";
import bank from "./bank.js";
import blog from "./blog.js";
import emails from "./emails.js";
import bankTransactions from "./bankTransactions.js";
import challengeAnswers from "./challengeAnswers.js";
import bankBalance from "./bankBalance.js";
import challengeProgress from "./challengeProgress.js";
import socialMedia from "./socialProfile.js";
import packetsniffer from "./packetsniffer.js";
import attacker from "./attacker.js";
import ecommerce from "./ecommerce.js";
import challenge from "./challenge.js";

export default combineReducers({
  auth: auth,
  bank: bank,
  attacker: attacker,
  ecommerce: ecommerce,
  emails: emails,
  packetsniffer: packetsniffer,
  blog: blog,
  bankTransactions: bankTransactions,
  bankBalance: bankBalance,
  socialMedia: socialMedia,
  challengeProgress: challengeProgress,
  challengeAnswers: challengeAnswers,
  challenge: challenge
});
