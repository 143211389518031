import React, { useEffect, useId, useState } from 'react';
import { Container, Button,Typography, CircularProgress, Grid} from "@material-ui/core";
import { useDispatch } from 'react-redux';

// import { useTheme } from "@material-ui/core";
import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { useNavigate, useParams } from 'react-router-dom';
import { setChallenge } from '../../../actions/challenge.js';
import { endSession } from '../../../actions/challenge.js';
import { FormattedMessage } from 'react-intl';

const Description = () => { 

  const theme = useTheme();
  const classes = useStyles(theme);

  const [challengeInfo, setChallengeInfo] = useState({});
  const {challenge} = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log(challenge,"adsfasfdsa!!!!!!!!!")
    dispatch(setChallenge({challenge: challenge })).then(data => {
      setChallengeInfo(data);

      if(data.completed){
        navigate(`/completed`);
        return;
      }
      if(data.initQuestionnaireCompleted === false){
        navigate(`/challenge/${data.activeChallenge}/pre-questionnaire`);
        return;
      }
      // go to the previous challenge to complete the post questionnaire
      else if(data.postQuestionnaireCompleted === false){
        navigate(`/challenge/${data.currentChallenge}/post-questionnaire`);
        return;
      }

      // console.log(data)

      for(let browser in data.scenarios)
      {
        for(let i = 0; i < data.scenarios[browser].length; i++){
          document.cookie = `${data.scenarios[browser][i]}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        }
      }

      // clearing any authentication instances
      // dispatch(endSession());
    })
  },[])
  
    return (
      <Container  className="text-center flex-grow-1 d-flex flex-column align-items-center justify-content-center">
              {challengeInfo.description ? (
                <>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Typography variant="h3" className={classes.text}>

                        {challengeInfo.description.split('\n').map((line, index) => (
                          <React.Fragment key={index}>
                              {line}
                              <br />
                          </React.Fragment>
                          ))
                        }
                    </Typography>
                  </Grid>
                </Grid>
                <Button variant="contained" color="primary" onClick={() => navigate(`/challenge/${challengeInfo.seed}`)}>
                  <FormattedMessage id="start-level" defaultMessage="Start Level"></FormattedMessage>
                </Button>
                </>

            )
        : (
          // Display loading spinner while setting up the database
          <Container className="p-3">
            <CircularProgress /> 
            <Typography>
              <FormattedMessage id="setting-up-database" defaultMessage="Setting up Database..."></FormattedMessage>
            </Typography>
          </Container>
        )
      }
    </Container>
    );
  };
  

export default Description;
