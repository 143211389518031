import { UPDATE_CHALLENGE_STATUS } from "../constants/actionTypes.js";
import * as api from "../api/index.js";

export const xssScriptValidity = (info) => async (dispatch) => {
  try{
    const {data} = await api.xssScriptValidity(info);

    // ' OR balance > 1000000 --

    if(data.step)
    {
      dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: "SCRIPT_ERROR", payload: e.message });
  }
};
