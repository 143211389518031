import { FETCH_SOCIAL_POSTS, FETCH_SOCIAL_POSTS_ERROR, FETCH_SOCIAL_USERS, FETCH_SOCIAL_USER, UPDATE_CHALLENGE_STATUS, UPDATE_SOCIAL_USERS, UPDATE_SOCIAL_USERS_ERROR, FETCH_SOCIAL_USER_ERROR, FETCH_SOCIAL_USERS_ERROR, FETCH_SOCIAL_USERS_SETTINGS_ERROR, FETCH_SOCIAL_USER_SETTINGS, CREATE_SOCIAL_POST, CREATE_SOCIAL_POST_ERROR } from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import BankAuthReducer from "../reducers/bank.js";
import { createContext, useEffect, useReducer } from "react";

export const getUsers = (query = {}) => async (dispatch) => {
  try{

    const {data} = await api.fetchUsers(query);

    // ' OR balance > 1000000 --
    if(data.error){
      console.log(data.error)
      dispatch({ type: FETCH_SOCIAL_USERS_ERROR, payload: data });
      return {error: data.error};
    }
    else{
      dispatch({ type: FETCH_SOCIAL_USERS, payload: data.users });
      return data.users;
    }
  }
   catch (e) {
    console.log(e)
    dispatch({ type: "FETCH_SOCIAL_USERS_ERROR", payload: e.message });
  }
};

export const getProfile = (username) => async (dispatch) => {
  try{
    const {data} = await api.fetchProfile(username);
    // console.log(username,"asdf", data)

    if(data.error){
      dispatch({ type: FETCH_SOCIAL_USER_ERROR, payload: data });
      return {error: data.error};
    }
    else{
      dispatch({ type: FETCH_SOCIAL_USER, payload: data.user });
      
      if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
      }
      return data.user;
    }

  }
   catch (e) {
    console.log(e)
    dispatch({ type: "FETCH_SOCIAL_USER_ERROR", payload: e.message });
  }
};

export const getProfileSettings = (info) => async (dispatch) => {
  try{
    // console.log(info)
    const {data} = await api.fetchProfileSettings(info);

    // console.log(data)

    if(data.error){
      dispatch({ type: FETCH_SOCIAL_USERS_SETTINGS_ERROR, payload: data });
      return {error: data.error};
    }
    else{
      dispatch({ type: FETCH_SOCIAL_USER_SETTINGS, payload: data.user });

      if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
      }
      return data.user;
    }

  }
   catch (e) {
    console.log(e)
    dispatch({ type: "FETCH_SOCIAL_USER_SETTINGS_ERROR", payload: e.message });
  }
};

export const getPosts = (info) => async (dispatch) => {
  try{
    const {data} = await api.fetchPosts(info);

    // ' OR balance > 1000000 --
    if(data.error){
      dispatch({ type: FETCH_SOCIAL_POSTS_ERROR, payload: data });
      return {error: data.error};
    }
    else{
      dispatch({ type: FETCH_SOCIAL_POSTS, payload: data.posts });
    //   if(data.step)
    //   {
    //     dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
    //   }
    }
    return data.posts;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: "FETCH_SOCIAL_POSTS_ERROR", payload: e.message });
  }
};

export const createPost = (info) => async (dispatch) => {
  try{
    const {data} = await api.createPost(info);
    // console.log(data,"adsfads")

    // ' OR balance > 1000000 --
    if(data.error){
      dispatch({ type: CREATE_SOCIAL_POST_ERROR, payload: data });
      return {error: data.error};
    }
    else{
      dispatch({ type: CREATE_SOCIAL_POST, payload: data.data });
      if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
      }
    }
    return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: "FETCH_SOCIAL_POSTS_ERROR", payload: e.message });
  }
};

export const updateUser = (info) => async (dispatch) => {
  try{
    // console.log(info)
    const {data} = await api.updateUser(info);

    // ' OR balance > 1000000 --

    if(data.error){
      dispatch({ type: UPDATE_SOCIAL_USERS_ERROR, payload: data.error });
      return {error: data.error};
    }
    else{
      dispatch({ type: UPDATE_SOCIAL_USERS, payload: data.message });
      if(data.step){
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step });
      }
      return {message: data.message};
    }

  }
   catch (e) {
    console.log(e)
    dispatch({ type: UPDATE_SOCIAL_USERS_ERROR, payload: e.message });
  }
};