import { makeStyles } from "@material-ui/core/styles/index.js";
import { deepPurple } from "@material-ui/core/colors/index.js";

export default makeStyles((them) => ({
  bg: (theme) => ({
    backgroundColor: theme.bg,
  }),
  bgSoft: (theme) => ({
    backgroundColor: theme.bgSoft,
  }),
  heading: (theme) => ({
    color: theme.logo,
    textDecoration: "none",
  }),
  text: (theme) => ({
    color: theme.textColorSoft
  }),
  container: (theme) => ({
    padding: theme.spacing(4),
  }),
  card: (theme) => ({
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: theme.bgSoft,
  }),
  cardContent: (theme) => ({
    minHeight: 150, // Adjust based on content size
    padding: "0px",
  }),
  challengeTitle: (theme) => ({
    marginBottom: theme.spacing(1),
    color: theme.textColor,
  }),
  button: (theme) => ({
    margin: theme.spacing(1),
  }),
  title: (theme) => ({
    color: theme.textColor,
    textAlign: "center",
  }),
}));
