import { createContext, useContext, useEffect, useMemo, useState  } from "react";

export const SideMenuContext = createContext();

export const SideMenuContextProvider = ({children}) => {

    const [menuOpen, setMenuOpen] = useState(false);

    const ModalValue = useMemo(() => ({
        menuOpen, setMenuOpen,
    }), [menuOpen]);

    return (
        <SideMenuContext.Provider value={{menuOpen, setMenuOpen}}>
            {children}
        </SideMenuContext.Provider>
    );
};