import React, { useContext, useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, ListItemAvatar, Avatar, Container } from '@material-ui/core';
import { getUsers } from 'actions/socialProfileScenario';
import { useDispatch, useSelector } from 'react-redux';
import { ChallengeDataContext } from 'contexts/ChallengeData';
import { DangrousElement } from 'Components/Challenge/Scenarios/ECommerceScenario/Dashboard/DangerousElement/DangerousElement';
import { xssScriptValidity } from 'actions/validScript';

const Users = () => {

    const [query, setQuery] = useState('');
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    const { page, setPage, session, user, setUser, url, setUrl, domain, setDomain, tabs, setTabs, params, activeTab, scriptValid, setScriptValid } = useContext(ChallengeDataContext);

    const dispatch = useDispatch();

    useEffect(() => {

        if(!params.query) return;

        if(params.query){
            setQuery(params.query)
        }

        dispatch(getUsers({query: params.query})).then(results => {
        console.log(params.query, "query", results.error)
        console.log(scriptValid)
            
            if(results.error)
            {
              setUsers([results.error]); // Update the search results
              setError(results.error);
            }
            else{
              setUsers(results);
              setError(null)
            }
            // console.log(results)
    
          })
          .catch((error) => {
            setError(error);
          });

    },[params.query])

    useEffect(() => {
      console.log("scriptValid", scriptValid, query)
      // if(!scriptValid) return;

      // move to the next step
      if(scriptValid && query){
        dispatch(xssScriptValidity({isValid: scriptValid, script: query})).then(results => {
          console.log(results)
          setScriptValid(false);
        })
      }

    },[scriptValid]);

    const handleUserClick = (result) => {
        // Replace this with the function you want to call when a user is clicked
        setUser(result);
        setPage("profile");
        // set url to user profile
        setUrl(domain+`/profile?uid=${result.username}`);
        // console.log(`User clicked: ${result}`);
      };

    const challengeData = useSelector(state => state.challenge);

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
      {challengeData.data.seed === "challenge11" || challengeData.data.seed === "challenge13" ? 
        <DangrousElement markup={`Users matching with '${decodeURIComponent(query)}'`} />
        : `Users matching with '${query}'`
      }
      </Typography>
      <List>
        {error ? 
            <>
                {(challengeData.data.seed !== "challenge11" && challengeData.data.seed !== "challenge13" ? 
                    error
                    : <DangrousElement markup={decodeURIComponent(error)} />
                )}
            </>
            :
            <></>
        }
        {users.map(result => (
          <div key={result.id} style={{ display: 'flex', alignItems: 'start', padding: "0.25rem" }}>
          <div
            onClick={() => handleUserClick(result)}
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '8px',
              // backgroundColor: '#f0f0f0', // Background color for the "button"
              cursor: 'pointer', // Cursor style to indicate it's clickable
              width: "100%"
            }}
          >
          <Avatar src={result.avatar} alt={result.name} />
            <div style={{ marginLeft: 16, display: 'flex', alignItems: 'start', flexDirection: "column" }}>
              <Typography variant="body1">{result.name}</Typography>
              <Typography variant="body2">@{result.username}</Typography>
            </div>
          </div>
        </div>
        ))}
      </List>
    </Container>
  );
}

export default Users;
