import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles(() => ({
  bg: (theme) => ({
    backgroundColor: theme.bg,
    height: "100%",
  }),
  text: (theme) => ({
    color: theme.textColor
  }),
  subTtext: (theme) => ({
    color: theme.textColorSoft
  }),
}));
