import { makeStyles } from "@material-ui/core/styles/index.js";

export default makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(4),
      },
      card: {
        display: 'flex',
        marginBottom: theme.spacing(2),
      },
      media: {
        width: 160,
        height: 160,
        objectFit: 'cover',
      },
      content: {
        flex: '1 0 auto',
      },
}));
