import { BANK_SUBMIT, BANK_SUBMIT_ERROR, BANK_TRANSFER_ERROR, GET_CHALLENGE_ANSWERS, GET_CHALLENGE_STATUS, LOGOUT, RESET_LEVEL, SET_CHALLENGE, SET_CHALLENGE_ANSWERS, SET_CHALLENGE_STATUS, UPDATE_CHALLENGE_STATUS, CHALLENGE_LOGOUT, FETCH_CHALLENGE, FETCH_CHALLENGE_ERROR } from "../constants/actionTypes.js";
import * as api from "../api/index.js";
import { useReducer , createContext} from "react";
import BankAuthReducer from "../reducers/bank.js";

export const setChallenge = (challenge) => async (dispatch) => {
  try{
    const {data} = await api.setChallenge(challenge);


    // ' OR balance > 1000000 --
    console.log(data)
      dispatch({ type: SET_CHALLENGE_STATUS, payload: data.steps });
      dispatch({ type: SET_CHALLENGE, payload: data.seed });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: SET_CHALLENGE_STATUS, payload: e.message });
  }
};

export const getChallengeStatus = (info) => async (dispatch) => {
  try{
    const {data} = await api.getChallengeStatus(info);

    // ' OR balance > 1000000 --
      dispatch({ type: GET_CHALLENGE_STATUS, payload: data });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_CHALLENGE_STATUS, payload: e.message });
  }
};

export const setChallengeAnswers = () => async (dispatch) => {
  try{
    const {data} = await api.setChallengeAnswers();

    // ' OR balance > 1000000 --
      dispatch({ type: SET_CHALLENGE_ANSWERS, payload: data });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: SET_CHALLENGE_ANSWERS, payload: e.message });
  }
};

export const getChallengeAnswers = () => async (dispatch) => {
  try{
    const {data} = await api.getChallengeAnswers();

    // ' OR balance > 1000000 --
    // console.log(data)
      dispatch({ type: GET_CHALLENGE_ANSWERS, payload: data });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: GET_CHALLENGE_ANSWERS, payload: e.message });
  }
};

export const resetLevel = (info) => async (dispatch) => {
  try{
    const {data} = await api.resetLevel(info);

    // ' OR balance > 1000000 --


      dispatch({ type: RESET_LEVEL });

  }
   catch (e) {
    console.log(e)
    dispatch({ type: BANK_TRANSFER_ERROR, payload: e.message });
  }
};

export const scenarioAuth = (info) => async (dispatch) => {
  try{
    const sessionIDCookie = document.cookie.split(';').find(c => c.trim().startsWith('session_id='));
    const sessionID = sessionIDCookie ? sessionIDCookie.split('=')[1] : null;
    console.log(sessionIDCookie, sessionID)

    const {data} = await api.scenarioAuth({...info, session_id: sessionID});

    // ' OR balance > 1000000 --
      dispatch({ type: BANK_SUBMIT, payload: data });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: FETCH_CHALLENGE_ERROR, payload: e.message });
  }
}

export const endSession = (scenario) => async (dispatch) => {
  try{
    // const {data} = await api.endSession(scenario);
    const {data} = await api.endScenarioAuth(scenario);

    // ' OR balance > 1000000 --
      // dispatch({ type: LOGOUT, payload: data });
      dispatch({ type: CHALLENGE_LOGOUT, data: scenario, scenario:scenario });

      return scenario;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: SET_CHALLENGE_STATUS, payload: e.message });
  }
}

export const fetchChallenge = (challenge) => async (dispatch) => {
  try{
    const {data} = await api.fetchChallenge(challenge);

    // ' OR balance > 1000000 --
      dispatch({ type: FETCH_CHALLENGE, payload: data });
      dispatch({ type: GET_CHALLENGE_STATUS, payload: data.steps });

      return data;
  }
   catch (e) {
    console.log(e)
    dispatch({ type: FETCH_CHALLENGE_ERROR, payload: e.message });
  }
}


export const bankScenarioAuth = (info ) => async (dispatch) => {
  try{
    const {data} = await api.challengeLogin(info);
    console.log(info.scenario,"Asdfdfdfsafsafafaw", data)

    // ' OR balance > 1000000 --
    if(data.message){
      dispatch({ type: BANK_SUBMIT_ERROR, payload: data.message, scenario: info.scenario });
      if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step, scenario: info.scenario});
      }
      if(data.report){
        console.error(JSON.parse(data.report))
      }
      return {error: data.message};
    }
    else{
      dispatch({ type: BANK_SUBMIT, payload: data.auth, scenario: info.scenario });

      if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step, scenario: info.scenario});
      }
      return data;
    }
  }
   catch (e) {
    console.log(e)
    dispatch({ type: BANK_SUBMIT_ERROR, payload: e.message, scenario: info.scenario });
  }
};

// export const getCookies = () => async (dispatch) => {
//   try{
//     const data = await api.fetchCookies();

//     // ' OR balance > 1000000 --
//       // dispatch({ type: GET_CHALLENGE_STATUS, payload: data });

//       return data;
//   }
//   catch (e) {
//     console.log(e)
//     // dispatch({ type: GET_CHALLENGE_STATUS, payload: e.message });
//   }
// }

export const getCookies = (scenario) => async () => {
  try {
    const data = await api.fetchCookies(scenario);
    console.log(data)
    return data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const hasProgress = () => async () => {
  try {
    const {data} = await api.hasProgress();
    console.log(data)
    return data;
  }
  catch (error) {
    console.log(error);
    throw error;
  }
}

export const openArticle = ({category,slug}) => async (dispatch) => {
  try{
    const {data} = await api.openArticle(category,slug);

      return data;
  }
   catch (e) {
    console.log(e)
  }
}

const LOGIN_SESSIONS = {
  bankUser: localStorage.getItem("login") !== undefined ? JSON.parse(localStorage.getItem("login")) : null,
};

export const challengesCompleted = () => async (dispatch) => {
  try{
    const {data} = await api.challengesCompleted();

    return data;
  }
  catch (e) {
    console.log(e)
  }
}

export const getCompletedChallenges = (info) => async (dispatch) => {
  try{
    const {data} = await api.getCompletedChallenges(info);

    return data;
  }
  catch (e) {
    console.log(e)
  }
}

export const getHint = (info) => async (dispatch) => {
  try{
    const {data} = await api.getHint(info);

    return data;
  }
  catch (e) {
    console.log(e)
  }

}

export const getSolution = (info) => async (dispatch) => {
  try{

    const {data} = await api.getSolution(info);

    return data;
  }
  catch (e) {
    console.log(e)
  }

}

export const skipchallenge = (info) => async (dispatch) => {
  try{

    const {data} = await api.skipChallenge(info);

    return data;
  }
  catch (e) {
    console.log(e)
  }

}

export const changeBrowser = (info) => async (dispatch) => {
  try{

    const {data} = await api.changeBrowser(info);

    if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step});
      }

    return data;
  }
  catch (e) {
    console.log(e)
  }

}

export const changeTab = (info) => async (dispatch) => {
  try{

    const {data} = await api.changeTab(info);

    if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step});
      }

    return data;
  }
  catch (e) {
    console.log(e)
  }

}

export const packetSnifferCookieStolen = (info) => async (dispatch) => {
  try{

    const {data} = await api.packetSnifferCookieStolen(info);

    if(data.step)
      {
        dispatch({ type: UPDATE_CHALLENGE_STATUS, payload: data.step});
      }

    return data;
  }
  catch (e) {
    console.log(e)
  }

}

export const BankAuthContext = createContext(LOGIN_SESSIONS);

export const BankAuthContextProvider = ({ children }) => {

  const [state, dispatch] = useReducer(BankAuthReducer, LOGIN_SESSIONS);

  return (
    <BankAuthContext.Provider
      value={{
        bankUser: state.bankUser,
        dispatch,
      }}
    >
      {children}
    </BankAuthContext.Provider>
  );
};

// export const setChallenge = () => async (dispatch) => {
//   try{
//     const {data} = await api.setChallenge();

//     // ' OR balance > 1000000 --
//       dispatch({ type: SET_CHALLENGE, payload: data });

//       return data;
//   }
//    catch (e) {
//     console.log(e)
//     dispatch({ type: SET_CHALLENGE, payload: e.message });
//   }
// };

// export const getChallenge = () => async (dispatch) => {
//   try{
//     const {data} = await api.getChallenge();

//     // ' OR balance > 1000000 --
//       dispatch({ type: GET_CHALLENGE, payload: data });

//       return data;
//   }
//    catch (e) {
//     console.log(e)
//     dispatch({ type: GET_CHALLENGE, payload: e.message });
//   }
// };
