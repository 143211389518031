import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import CheckIcon from '@mui/icons-material/Check.js';
import CloseIcon from '@mui/icons-material/Close.js';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { skipchallenge } from 'actions/challenge.js';

const Skip = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);

  const {content} = props;
 
  const theme = useTheme();
  const classes = useStyles(theme);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  const skipChallenge = () => {

    // skip challenge
    if (isOpen !== "skip") return; 

    dispatch(skipchallenge()).then((response) => {
      console.log('response', response);
      navigate(`/scenario-description/${response.challengeSeed}`)
    })

    // handleCloseModal();

    setIsOpen(null);
  }

  return (
    <div>
        <Modal
            open={isOpen === "skip"}
            onClose={handleCloseModal} 
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
            <Box className={classes.box}>
                <h2 id="modal-title" className={classes.text}>Skip Challenge</h2>
                <Box className={`${classes.textContent} text-start flex-column text-center`} style={{ display: 'flex' }}>
                    Are you sure you want to skip this challenge? You won't be able to come back to it again in the future.
                </Box>
                <Button variant="contained" className="mt-3" onClick={() => skipChallenge()}>
                    Yes
                </Button>
                <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    No!
                </Button>
            </Box>
        </Modal>
    </div>
  );
};

export default Skip;