// SettingsTab.jsx
import React from 'react';
import { Typography } from '@mui/material';

const SettingsTab = () => {
  return (
    <div>
      <Typography variant="h5">Admin Settings</Typography>
      {/* Add your admin settings components here */}
    </div>
  );
};

export default SettingsTab;
