// src/App.js
import React, { useContext, useEffect } from 'react';
import { Container, CssBaseline } from '@mui/material';
import LoginPage from '../../Common/LoginPage/LoginPage.js';
import { Paper } from '@material-ui/core';

import { useTheme } from "styled-components";
import useStyles from "./styles.js";
import { BankAuthContext, endSession } from '../../../../actions/challenge.js';
import ResetLevelButton from '../../../Button/ResetLevelButton/ResetLevelButton.js';
import { useDispatch, useSelector } from 'react-redux';
import OpenModal from '../../../Button/ResetLevelButton/OpenModal.js';
import ScenarioInstructionsModal from '../../../Modal/ScenarioInstructionsModal.js';
import { ModalContext } from '../../../../contexts/ModalContext.js';
import { getChallengeAnswers } from '../../../../actions/challenge.js';
import Dashboard from './Dashboard/Dashboard.js';
import { ChallengeDataContext } from 'contexts/ChallengeData.js';

const SocialProfileScenario = () => {

    
    const theme = useTheme();
    const classes = useStyles(theme);
    
    const {activeTab, session, sessionTest, url} = useContext(ChallengeDataContext);

    // useEffect(() => {
    //     console.log('prior',url, session)
    //     if (session.status) {
    //         console.log('ηευυυυ',session)
    //     }
    // }, [url])

    // console.log(session, sessionTest, sessionTest.data[activeTab], activeTab)

    //' OR balance > 1000000

    return (
        <Container component="main" maxWidth="md">
            {!session.status ? <LoginPage title="Login to Your BestSocial Account." /> : <Dashboard />}
            {/* <OpenModal modal={"finish"} title="Finish"></OpenModal> */}
        </Container>
    );
};

export default SocialProfileScenario;