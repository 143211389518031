import * as api from "../api/index.js";

export const submitSurvey = (info) => async (dispatch) => {
  try{
    const {data} = await api.submitSurvey(info);

      return data;
  }
   catch (e) {
    console.log(e)
  }
}

export const surveysCompleted = (info) => async (dispatch) => {
    try{
      const {data} = await api.surveysCompleted(info);

      console.log(data)
  
        return data;
    }
     catch (e) {
      console.log(e)
    }
  }

export const fetchSurveyQuestions = (info) => async (dispatch) => {
    try{
      const {data} = await api.fetchSurveyQuestions(info);

      console.log(data)

        return data;
    }
     catch (e) {
      console.log(e)
    }
  }