import React, { useContext, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button/index.js';
import Modal from '@mui/material/Modal/index.js';
import {ModalContext} from "../../contexts/ModalContext.js"
import { Box, Typography } from '@mui/material';
import useStyles from "./styles.js";
import { useTheme } from "styled-components";
import { useNavigate } from 'react-router-dom';
import copy from 'clipboard-copy';


import { IconButton, Paper } from '@material-ui/core';

import FileCopyIcon from '@mui/icons-material/FileCopy';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const ChallengeSnippets = (props) => {

  const {isOpen, setIsOpen} = useContext(ModalContext);

  const {content} = props;

  const theme = useTheme();
  const classes = useStyles(theme);

  const codeSnippetRef = useRef(null);
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    copy(codeSnippetRef.current.innerText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset the copied state after 2 seconds
  };


  const navigate = useNavigate();

  const handleCloseModal = () => {
    setIsOpen(null);
  };

  // console.log(content)

//   useEffect(() => {
//     console.log(isOpen)
//   },[isOpen])

  return (
    <div>
        <Modal
            open={isOpen === "snippets"}
            onClose={handleCloseModal}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className={classes.modalContainer}
        >
                <Box className={classes.boxSnippets}>
                  <Typography variant="h4" id="modal-title" className={classes.textSnippets}>
                    Snippets
                  </Typography>
                    {content && content.length > 0 ?
                      content.map((item, index) => (
                        <>
                        <Typography variant="h6" id="modal-title" className={classes.textSnippets}>
                            {content[index].title}
                          </Typography>
                        <Typography variant="p" id="modal-title" className={classes.textSnippets}>
                            {content[index].description}
                          </Typography>
                          <Paper className={classes.textContentSnippets}>
                            <Typography key={index} variant="body2" component="pre" className={classes.textSnippets}>
                              <pre>
                                <code ref={codeSnippetRef}>{content[index].snippet}</code>
                              </pre>
                            </Typography>
                            <IconButton
                                color="primary"
                                onClick={copyToClipboard}
                                disabled={copied}
                              >
                                {copied ? <CheckCircleIcon /> : <FileCopyIcon />}
                              </IconButton>
                                {copied && ' Copied!'}
                          </Paper>
                        </>
                      ))
                        :
                        <Typography variant="p" id="modal-title" className={`${classes.textSnippets} text-center`}>
                            No snippets avaliable.
                          </Typography>
                    }
                  <Button variant="contained" className="mt-3" onClick={handleCloseModal}>
                    Thank You
                  </Button>
                </Box>
        </Modal>
    </div>
  );
};

export default ChallengeSnippets;